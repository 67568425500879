import TabMaster from '@components/tab-master/tab-master.component'
import Table from '@components/table/table.component'
import ManageUsersFilterOverlay from './components/manage-users-filter-overlay.component'
import useManageUsers from './manage-users.service'
import { IManageUser } from './manage-users.interface'
import { useTranslation } from 'react-i18next'

const ManageUsers = () => {
    const { t } = useTranslation()
    const {
        filterOverlayService,
        tabFilter,
        manageUserDataMeta,
        manageUserData,
        setPageData,
        navigate,
        setTabFilter,
        allModal,
        headers,
        tabItems,
        loading,
        ConfigureColumnsModalService,
    } = useManageUsers()

    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)] pt-0 xl:pt-0 sm:pt-0">
                <TabMaster
                    items={tabItems || []}
                    tabFilter={tabFilter}
                    onChange={(item) => {
                        setTabFilter(item)
                    }}
                    //  search ----------------------------------------------------------------
                    useSearch={true}
                    placeHolderSearch={t('action.search', {
                        value: t('users.user_id'),
                    })}
                    onSearchSubmit={() => {}}
                    containerSearchClassName={'flex-1'}
                    //  button for overlay or something ----------------------------------------
                    iconButton1={{
                        onClick: () =>
                            filterOverlayService.toggleOverlayHandling(),
                        icon: 'ri-filter-2-line',
                        filterOverlayComponent: (
                            <ManageUsersFilterOverlay
                                filterOverlayService={filterOverlayService}
                            />
                        ),
                    }}
                    iconButton2={{
                        onClick: () =>
                            ConfigureColumnsModalService.openModalHandling(),
                        icon: 'ri-settings-2-line',
                    }}
                    actionButton1={{
                        onClick: () => {
                            navigate('create/')
                        },
                        label: t('menu.manage_user_new').toUpperCase(),
                        className: '!text-size-S',
                        variant: 'brand-v2-inverse',
                    }}
                />

                <Table<IManageUser>
                    headers={headers}
                    data={manageUserData}
                    loading={loading}
                    nextHandling={(page) => {
                        setPageData(page)
                    }}
                    previousHandling={(page) => {
                        setPageData(page)
                    }}
                    meta={manageUserDataMeta}
                    moduleTitle={'User'}
                    onRowClick={function (data): void {
                        const id = data.id
                        navigate('details/' + id)
                    }}
                    enableExport={true}
                    variantButtonExport="brand-v2-inverse"
                    // modalService={generateReportModalService}
                    // components={<ManageUsersFilter isGenerateReport={true} />}
                    // resetFilter={() => { }}
                    // getDataGenerate={() => { }}
                    // generateReportData={manageUserData}
                    // GenerateReportHeaders={ManageUserHeader}
                    // removeFilter={() => { }}
                />
            </div>
            {allModal}
        </div>
    )
}

export default ManageUsers
