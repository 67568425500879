import {
    IBarChartAirData,
    IBarChartCourierData,
    IBarChartRoadData,
    IBarChartSeaAndRailData,
    IResponseDropdownVolumeDashboard,
    IVolumeFilterValidation,
} from './volumes.interface'
import { useFormik } from 'formik'
import { useVolumeFilterValidation } from 'client/ifb/form-validation/volume.validation'
import {
    barChartSelector,
    donatChartSelector,
    itemTotalChartSelector,
    setBarChartAir,
    setBarChartCour,
    setBarChartRail,
    setBarChartRoad,
    setBarChartSea,
    setDonatChartShipment,
    setVolumeDropdown,
    volumeDropdownSelector,
    weightDropdownSelector,
} from './volumes.slice'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import {
    fetchBarData,
    fetchDonatData,
} from 'client/ifb/repository/volume.repository'
import { fetchtDropdownDashboard } from 'client/ifb/repository/dashboard.repository'
import moment from 'moment'
import { Toast } from '@components/toast/toast.component'

const useVolumes = () => {
    const dispatch = useDispatch()
    const barData = useSelector(barChartSelector)
    const itemTotalChart = useSelector(itemTotalChartSelector)
    const donatDatashipment = useSelector(donatChartSelector)
    const volemeDropdown = useSelector(volumeDropdownSelector)
    const weightDropdown = useSelector(weightDropdownSelector)
    const [isLoading, setIsLoading] = useState({
        sea: true,
        air: true,
        road: true,
        rail: true,
        courier: true,
        donat: true,
    })

    // states
    const [itemActive, setItemActive] = useState<boolean>(true)
    const [lastValueUnitMeasurement, setLastValueUnitMeasurement] = useState<{
        volume: string
        weight: string
    }>({
        volume: 'M3',
        weight: 'KG',
    })

    const { volumeFilterInitialValue, volumeFilterValidation } =
        useVolumeFilterValidation()

    const formikVolume = useFormik<IVolumeFilterValidation>({
        validationSchema: volumeFilterValidation,
        initialValues: volumeFilterInitialValue,
        onSubmit: async () => {},
    })

    const numberToFixed = (value?: number) =>
        value ? Number(value.toFixed(2)) : 0

    const fetchData = async () => {
        try {
            const seaPromise = loadSeaData()

            const airPromise = loadAirData()

            const roadPromise = loadRoadData()

            const railPromise = loadRailData()

            const courierPromise = loadCourierData()

            const donatPromise = fetchDonatData(formikVolume.values)
                .then((donat) => {
                    dispatch(
                        setDonatChartShipment({
                            data: donat?.data,
                            unitMeasurement: formikVolume.values.UnitMeasure,
                        }),
                    )
                    setIsLoading((prevLoading) => ({
                        ...prevLoading,
                        donat: false,
                    }))
                })
                .catch((error) => {
                    console.error('Error fetching donat data:', error)
                    setIsLoading((prevLoading) => ({
                        ...prevLoading,
                        donat: false,
                    }))
                })

            await Promise.allSettled([
                seaPromise,
                airPromise,
                roadPromise,
                railPromise,
                courierPromise,
                donatPromise,
            ])
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }

    const fetchDataOnlyOnce = async () => {
        try {
            const [dataDropdown] = await Promise.all([
                fetchtDropdownDashboard(),
            ])
            if (dataDropdown) {
                dispatch(
                    setVolumeDropdown(
                        dataDropdown as IResponseDropdownVolumeDashboard,
                    ),
                )
            }
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }

    const loadRoadData = async () => {
        const start = moment(formikVolume.values.StartDate, 'MM/YYYY')
        const end = moment(formikVolume.values.EndDate, 'MM/YYYY')
        try {
            const fecthData = await fetchBarData(formikVolume.values, 'road')
            if (!fecthData.isSuccess) return
            const updatedData: IBarChartRoadData = {
                totalFclVolume: 0,
                totalLclVolume: 0,
                totalFtlVolume: 0,
                barchartData: [],
            }

            // load data percontainer
            fecthData?.data?.totalPerContainer?.forEach((item) => {
                const totalValue = numberToFixed(item.total)
                switch (item.containerMode.toLowerCase()) {
                    case 'fcl':
                        updatedData.totalFclVolume = totalValue
                        break
                    case 'lcl':
                        updatedData.totalLclVolume = totalValue
                        break
                    case 'ftl':
                        updatedData.totalFtlVolume = totalValue
                        break
                }
            })

            while (start.isSameOrBefore(end, 'month')) {
                const month = start.format('MMM')
                const year = start.format('YYYY')
                const fcl = fecthData.data.totalPerMonth.find(
                    (item) =>
                        item.date.month.toLowerCase() === month.toLowerCase() &&
                        item.date.year.toString() === year &&
                        item.container.toLowerCase() === 'fcl',
                )?.total
                const lcl = fecthData.data.totalPerMonth.find(
                    (item) =>
                        item.date.month.toLowerCase() === month.toLowerCase() &&
                        item.date.year.toString() === year &&
                        item.container.toLowerCase() === 'lcl',
                )?.total
                const ftl = fecthData.data.totalPerMonth.find(
                    (item) =>
                        item.date.month.toLowerCase() === month.toLowerCase() &&
                        item.date.year.toString() === year &&
                        item.container.toLowerCase() === 'ftl',
                )?.total

                updatedData.barchartData.push({
                    name: start.format('MMM'),
                    secName: year,
                    ...(fcl && {
                        f1: numberToFixed(fcl),
                    }),
                    ...(lcl && {
                        f2: numberToFixed(lcl),
                    }),
                    ...(ftl && {
                        f3: numberToFixed(ftl),
                    }),
                    amt: 10,
                })

                start.add(1, 'month')
            }
            dispatch(setBarChartRoad(updatedData))
        } catch (error) {
            console.error('Error fetching road data:', error)
        } finally {
            setIsLoading((prevLoading) => ({
                ...prevLoading,
                road: false,
            }))
        }
    }

    const loadSeaData = async () => {
        const start = moment(formikVolume.values.StartDate, 'MM/YYYY')
        const end = moment(formikVolume.values.EndDate, 'MM/YYYY')

        try {
            const fecthData = await fetchBarData(formikVolume.values, 'sea')
            if (!fecthData.isSuccess) return
            const updatedData: IBarChartSeaAndRailData = {
                totalFclVolume: 0,
                totalLclVolume: 0,
                barchartData: [],
            }

            // load data percontainer
            fecthData?.data?.totalPerContainer?.forEach((item) => {
                const totalValue = numberToFixed(item.total)
                switch (item.containerMode.toLowerCase()) {
                    case 'fcl':
                        updatedData.totalFclVolume = totalValue
                        break
                    case 'lcl':
                        updatedData.totalLclVolume = totalValue
                        break
                }
            })

            while (start.isSameOrBefore(end, 'month')) {
                const month = start.format('MMM')
                const year = start.format('YYYY')
                const fcl = fecthData.data.totalPerMonth.find(
                    (item) =>
                        item.date.month.toLowerCase() === month.toLowerCase() &&
                        item.date.year.toString() === year &&
                        item.container.toLowerCase() === 'fcl',
                )?.total
                const lcl = fecthData.data.totalPerMonth.find(
                    (item) =>
                        item.date.month.toLowerCase() === month.toLowerCase() &&
                        item.date.year.toString() === year &&
                        item.container.toLowerCase() === 'lcl',
                )?.total

                updatedData.barchartData.push({
                    name: start.format('MMM'),
                    secName: year,
                    ...(fcl && {
                        f1: numberToFixed(fcl),
                    }),
                    ...(lcl && {
                        f2: numberToFixed(lcl),
                    }),
                    amt: 10,
                })

                start.add(1, 'month')
            }
            dispatch(setBarChartSea(updatedData))
        } catch (error) {
            console.error('Error fetching sea data:', error)
        } finally {
            setIsLoading((prevLoading) => ({
                ...prevLoading,
                sea: false,
            }))
        }
    }

    const loadAirData = async () => {
        const start = moment(formikVolume.values.StartDate, 'MM/YYYY')
        const end = moment(formikVolume.values.EndDate, 'MM/YYYY')
        try {
            const fecthData = await fetchBarData(formikVolume.values, 'air')
            if (!fecthData.isSuccess) return
            const updatedData: IBarChartAirData = {
                totalLseVolume: numberToFixed(
                    fecthData?.data?.totalPerContainer[0]?.total,
                ),
                barchartData: [],
            }

            while (start.isSameOrBefore(end, 'month')) {
                const month = start.format('MMM')
                const year = start.format('YYYY')
                const lse = fecthData.data.totalPerMonth.find(
                    (item2) =>
                        item2.date.month.toLowerCase() ===
                            month.toLowerCase() &&
                        item2.date.year.toString() === year,
                )

                updatedData.barchartData.push({
                    name: start.format('MMM'),
                    secName: year,
                    ...(lse && {
                        f1: numberToFixed(lse.total),
                    }),
                    amt: 10,
                })

                start.add(1, 'month')
            }
            dispatch(setBarChartAir(updatedData))
        } catch (error) {
            console.error('Error fetching air data :', error)
        } finally {
            setIsLoading((prevLoading) => ({
                ...prevLoading,
                air: false,
            }))
        }
    }

    const loadRailData = async () => {
        const start = moment(formikVolume.values.StartDate, 'MM/YYYY')
        const end = moment(formikVolume.values.EndDate, 'MM/YYYY')
        try {
            const fecthData = await fetchBarData(formikVolume.values, 'rail')
            if (!fecthData.isSuccess) return
            const updatedData: IBarChartSeaAndRailData = {
                totalFclVolume: 0,
                totalLclVolume: 0,
                barchartData: [],
            }

            // load data percontainer
            fecthData?.data?.totalPerContainer?.forEach((item) => {
                const totalValue = numberToFixed(item?.total)
                switch (item.containerMode.toLowerCase()) {
                    case 'fcl':
                        updatedData.totalFclVolume = totalValue
                        break
                    case 'lcl':
                        updatedData.totalLclVolume = totalValue
                        break
                }
            })

            while (start.isSameOrBefore(end, 'month')) {
                const month = start.format('MMM')
                const year = start.format('YYYY')
                const fcl = fecthData.data.totalPerMonth.find(
                    (item) =>
                        item.date.month.toLowerCase() === month.toLowerCase() &&
                        item.date.year.toString() === year &&
                        item.container.toLowerCase() === 'fcl',
                )?.total
                const lcl = fecthData.data.totalPerMonth.find(
                    (item) =>
                        item.date.month.toLowerCase() === month.toLowerCase() &&
                        item.date.year.toString() === year &&
                        item.container.toLowerCase() === 'lcl',
                )?.total

                updatedData.barchartData.push({
                    name: start.format('MMM'),
                    secName: year,
                    ...(fcl && {
                        f1: numberToFixed(fcl),
                    }),
                    ...(lcl && {
                        f2: numberToFixed(lcl),
                    }),
                    amt: 10,
                })

                start.add(1, 'month')
            }
            dispatch(setBarChartRail(updatedData))
        } catch (error) {
            console.error('Error fetching air data :', error)
        } finally {
            setIsLoading((prevLoading) => ({
                ...prevLoading,
                rail: false,
            }))
        }
    }

    const loadCourierData = async () => {
        const start = moment(formikVolume.values.StartDate, 'MM/YYYY')
        const end = moment(formikVolume.values.EndDate, 'MM/YYYY')
        try {
            const fecthData = await fetchBarData(formikVolume.values, 'courier')
            if (!fecthData.isSuccess) return
            const updatedData: IBarChartCourierData = {
                totalCourierVolume: numberToFixed(
                    fecthData?.data?.totalPerContainer[0]?.total,
                ),
                barchartData: [],
            }
            while (start.isSameOrBefore(end, 'month')) {
                const month = start.format('MMM')
                const year = start.format('YYYY')
                const fcl = fecthData.data.totalPerMonth.find(
                    (item) =>
                        item.date.month.toLowerCase() === month.toLowerCase() &&
                        item.date.year.toString() === year,
                )?.total

                updatedData.barchartData.push({
                    name: start.format('MMM'),
                    secName: year,
                    ...(fcl && {
                        f1: numberToFixed(fcl),
                    }),
                    amt: 10,
                })

                start.add(1, 'month')
            }
            dispatch(setBarChartCour(updatedData))
        } catch (error) {
            console.error('Error fetching air data :', error)
        } finally {
            setIsLoading((prevLoading) => ({
                ...prevLoading,
                courier: false,
            }))
        }
    }

    // useEffects
    useEffect(() => {
        formikVolume.submitForm()
    }, [])
    useEffect(() => {
        fetchDataOnlyOnce()
    }, [])
    useEffect(() => {
        const { errors, initialValues, values } = formikVolume
        const timer =
            initialValues.EndDate !== values.EndDate ||
            initialValues.EndDate !== values.EndDate
                ? 2500
                : 1000

        const handler = setTimeout(() => {
            if (Object.keys(errors).length !== 0) {
                Toast({
                    header: 'Improper Filter Selection!',
                    message: formikVolume.errors.EndDate || '',
                    type: 'error',
                })
                return
            }
            setIsLoading({
                sea: true,
                air: true,
                road: true,
                rail: true,
                courier: true,
                donat: true,
            })
            fetchData()
        }, timer)
        return () => clearTimeout(handler)
    }, [formikVolume.values, formikVolume.errors])

    return {
        volemeDropdown,
        weightDropdown,
        itemTotalChart,
        isLoading,
        formikVolume,
        barData,
        donatDatashipment,
        itemActive,
        setItemActive,
        lastValueUnitMeasurement,
        setLastValueUnitMeasurement,
    }
}

export default useVolumes
