import { ITabItem } from '@components/tab/tab.interface'
import { ITableColumn } from '@components/table/table.interface'
import { OrderDataDummy } from './orders.dummy'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { IOrder } from './orders.interface'
import { IConfigureColumns } from '@components/configure-columns-modal/configure-column-modal.interface'

export const OrderStatusHeader: ITabItem[] = [
    {
        label: 'All Orders',
        labelTranslationCode: 'status.all_orders',
        totalData: OrderDataDummy.length,
        key: 'status',
        value: 'allOrders',
        className: 'brand-v2',
    },
    {
        label: 'Completed',
        labelTranslationCode: 'status.completed',
        totalData: OrderDataDummy.filter(
            (x: any) => x.orderStatus === 'Completed',
        ).length,
        key: 'status',
        value: 'Completed',
        className: 'brand-v2',
    },
    {
        label: 'On Hold',
        labelTranslationCode: 'status.on_hold',
        totalData: OrderDataDummy.filter(
            (x: any) => x.orderStatus === 'On Hold',
        ).length,
        key: 'status',
        value: 'On Hold',
        className: 'brand-v2',
    },
    {
        label: 'Cancelled',
        labelTranslationCode: 'status.canceled',
        totalData: OrderDataDummy.filter(
            (x: any) => x.orderStatus === 'Cancelled',
        ).length,
        key: 'status',
        value: 'Cancelled',
        className: 'brand-v2',
    },
    {
        label: 'Others',
        labelTranslationCode: 'status.others',
        totalData: OrderDataDummy.filter((x: any) => x.orderStatus === 'Others')
            .length,
        key: 'status',
        value: 'Others',
        className: 'brand-v2',
    },
]

export const OrderSummaryHeader: ITableColumn[] = [
    {
        label: 'Docket ID',
        accessor: 'docketID',
    },
    {
        label: 'Warehouse',
        accessor: 'warehouseName',
    },
    {
        label: 'External Reference',
        accessor: 'externalReference',
    },
    {
        label: 'Order Status',
        accessor: 'orderStatus',
    },
    {
        label: 'Required Date',
        accessor: 'requiredDate',
    },
    {
        label: 'Finalised Date',
        accessor: 'finalisedDate',
    },
    {
        label: 'Total Units',
        accessor: 'totalUnits',
    },
]

export const OrderConfigureColumns: IConfigureColumns<IOrder>[] = [
    {
        title: 'Flags',
        columns: [
            {
                statusColumn: 'selectedColumns',
                label: 'Docket ID',
                labelTranslation: 'orders.docket_id',
                accessor: 'docketID',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Warehouse',
                labelTranslation: 'orders.warehouse_name',
                accessor: 'warehouseName',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'External Reference',
                labelTranslation: 'orders.external_reference',
                accessor: 'externalReference',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Order Status',
                labelTranslation: 'orders.order_status',
                accessor: 'orderStatus',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Required Date',
                labelTranslation: 'orders.required_date',
                accessor: 'requiredDate',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Finalised Date',
                labelTranslation: 'orders.finalised_date',
                accessor: 'finalisedDate',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Total Units',
                labelTranslation: 'orders.total_units',
                accessor: 'totalUnits',
            },
        ],
    },
]

export const filterWarehouse: IDropdownItem<unknown>[] = [
    {
        label: 'HarborView Warehouse',
        value: 'HarborView Warehouse',
    },
    {
        label: 'Summit Storage Solutions',
        value: 'Summit Storage Solutions',
    },
    {
        label: 'Riverside Logistics Center',
        value: 'Riverside Logistics Center',
    },
    {
        label: 'MetroWare Warehouse',
        value: 'MetroWare Warehouse',
    },
    {
        label: 'Valley Vista Warehouse',
        value: 'Valley Vista Warehouse',
    },
    {
        label: 'Coastal Cargo Warehouse',
        value: 'Coastal Cargo Warehouse',
    },
    {
        label: 'Prime Storage Facility',
        value: 'Prime Storage Facility',
    },
    {
        label: 'UrbanHub Distribution Center',
        value: 'UrbanHub Distribution Center',
    },
    {
        label: 'Skyline Storage Depot',
        value: 'Skyline Storage Depot',
    },
    {
        label: 'Pioneer Warehouse Solutions',
        value: 'Pioneer Warehouse Solutions',
    },
    {
        label: 'Evergreen Logistics Hub',
        value: 'Evergreen Logistics Hub',
    },
    {
        label: 'Horizon Storage Services',
        value: 'Horizon Storage Services',
    },
    {
        label: 'Unity Warehouse Complex',
        value: 'Unity Warehouse Complex',
    },
    {
        label: 'SwiftStock Warehousing Solutions',
        value: 'SwiftStock Warehousing Solutions',
    },
    {
        label: 'PeakLogix Distribution Center',
        value: 'PeakLogix Distribution Center',
    },
]
