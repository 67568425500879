import moment from 'moment'
import * as Yup from 'yup'
import { ICOFilterByMetrics } from '../pages/control-tower/control-tower.interface'

// interface for validation
export interface IFilterShipmentEventCO {
    IMP: boolean
    EXP: boolean
    CrossTrade: boolean
}
export interface IFilterDataCategoryCO {
    Shipments: boolean
    Revenue: boolean
    GrossProfit: boolean
    Volume: boolean
}
export interface IFilterCO {
    ShipmentDateBy: string
    StartDate: string
    EndDate: string
    DataCategory: IFilterDataCategoryCO
    // FieldDataCategory: ICOFilterByMetrics
    ShipmentEvent: IFilterShipmentEventCO
    Company: string
    TransportMode: string
    ContainerMode: string
}

// form validation YUP for formik
export const filterCOValidation = Yup.object({
    StartDate: Yup.string()
        .required('Start Month is required')
        .matches(
            /^(0[1-9]|1[0-2])\/\d{4}$/,
            'Invalid Start Month format (MM/YYYY)',
        )
        .test(
            'is-greater',
            'End Month cannot be earlier then the start month',
            function (value) {
                const { EndDate } = this.parent
                if (!value || !EndDate) return false

                const start = moment(EndDate, 'MM/YYYY')
                const end = moment(value, 'MM/YYYY')

                return start.isAfter(end)
            },
        )
        .test(
            'max-range',
            'Date filter range cannot be more than 12 months',
            function (value) {
                const { EndDate } = this.parent
                if (!value || !EndDate) return false

                const end = moment(EndDate, 'MM/YYYY')
                const start = moment(value, 'MM/YYYY')

                return end.diff(start, 'months') <= 12
            },
        ),

    EndDate: Yup.string()
        .required('End Month is required')
        .matches(
            /^(0[1-9]|1[0-2])\/\d{4}$/,
            'Invalid End Month format (MM/YYYY)',
        )
        .test(
            'not-same',
            'Start month and end month cannot be the same selection',
            function (value) {
                const { StartDate } = this.parent
                if (!value || !StartDate) return false

                return value !== StartDate
            },
        )
        .test(
            'is-greater',
            'End Month cannot be earlier then the start month',
            function (value) {
                const { StartDate } = this.parent
                if (!value || !StartDate) return false

                const start = moment(StartDate, 'MM/YYYY')
                const end = moment(value, 'MM/YYYY')

                return end.isAfter(start)
            },
        )
        .test(
            'max-range',
            'Date filter range cannot be more than 12 months',
            function (value) {
                const { StartDate } = this.parent
                if (!value || !StartDate) return false

                const start = moment(StartDate, 'MM/YYYY')
                const end = moment(value, 'MM/YYYY')

                return end.diff(start, 'months') <= 12
            },
        ),
})

// form initial data
const monthNow = moment().subtract(0, 'months').format('MM/YYYY')
const sixMonthsAgo = moment().subtract(5, 'months').format('MM/YYYY')
export const filterCOInitialValue: IFilterCO = {
    ShipmentDateBy: 'eta',
    StartDate: sixMonthsAgo,
    EndDate: monthNow,
    Company: 'All Company',
    TransportMode: 'All Modes',
    ContainerMode: 'All Modes',
    DataCategory: {
        Shipments: true,
        Revenue: false,
        GrossProfit: false,
        Volume: false,
    },
    ShipmentEvent: {
        IMP: true,
        EXP: false,
        CrossTrade: false,
    },
    // FieldDataCategory: 'shipment',
}
