import { useModal } from '@components//modal/modal.service'
import useOverlay from '@components/overlay/overlay.service'
import { ITabItem } from '@components/tab/tab.interface'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ITableColumn } from '@components/table/table.interface'
import { ShipmentConfigureColumns } from './shipments.static'
import { getShipmentsData } from 'client/ifb/repository/shipment.repository'
import { useAppDispatch } from 'store'
import { useSelector } from 'react-redux'
import {
    // filterSelector,
    setFilter,
    setPageNumber,
    setSelectedStatus,
    setShipmentData,
    // shipmentMetaSelector,
    // shipmentsDataSelector,
    // shipmentsStatusTotalSelector,
    shipmentsSummaryDataSelector,
    // tabStatusFilterSelector,
} from './shipments.slice'
import {
    IRShipment,
    IRShipmentFilter,
    IRShipmentStatusTotal,
} from 'client/ifb/repository/interface/shipment.interface'
import { Toast } from '@components/toast/toast.component'
import { isEmptyArray } from 'formik'
import { initialFilter } from 'common/common.static'

const useShipments = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    // selector
    const shipmentsData = useSelector(shipmentsSummaryDataSelector)

    // state
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [headers, setHeaders] = useState<ITableColumn<IRShipment>[]>([])
    const [tabItems, setTabItems] = useState<ITabItem[]>()
    const [isInitialized, setIsInitialized] = useState<boolean>(false)

    // variable

    // overlay
    const filterOverlayService = useOverlay()
    const newBookingOverlayService = useOverlay()

    // modal
    const generateReportModalService = useModal()
    const ConfigureColumnsModalService = useModal()

    // variable
    const { filter, meta, data, statusTotal, statusSelected } = shipmentsData
    const { pageNumber, pageSize, status, search } = filter

    useEffect(() => {
        dispatch(setFilter(initialFilter))
        setIsInitialized(true)
    }, [])

    useEffect(() => {
        generateTabItems(statusTotal)
    }, [statusTotal])

    // get data
    useEffect(() => {
        if (isInitialized) loadData()
    }, [pageNumber, pageSize, status, statusSelected, search, isInitialized])

    // Get Data Function
    const loadData = async () => {
        const status = statusSelected.value

        let tmpFilter: IRShipmentFilter = filter
        if (statusSelected?.childStatus) {
            const inProgressStat = statusSelected?.childStatus[0].value
            tmpFilter = { ...filter, status, inProgressStatus: inProgressStat }
        } else {
            tmpFilter = { ...filter, status }
        }
        try {
            setLoading(true)
            const actionResult = await getShipmentsData(tmpFilter)
            dispatch(setShipmentData(actionResult))
            setLoading(false)
            if (isEmptyArray(actionResult.data)) {
                Toast({
                    header: 'Search Not Found!',
                    message:
                        "The keyword you've entered does not display any result.",
                    type: 'error',
                })
            }
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            setErrorMessage(errorMessage)
        }
    }

    // generate tab Items
    const generateTabItems = (status: IRShipmentStatusTotal) => {
        const tabItems: ITabItem[] = [
            {
                label: 'All Shipments',
                totalData: status.all,
                key: 'status',
                value: 'all',
                className: 'brand-v2',
            },
            {
                label: 'Arrived',
                totalData: status.arrived,
                key: 'status',
                value: 'arrived',
                className: 'brand-v2',
            },
            {
                label: 'In Transit',
                totalData: status.inTransit,
                key: 'status',
                value: 'inTransit',
                className: 'brand-v2',
            },
            {
                label: 'Delayed',
                totalData: status.delayed,
                key: 'status',
                value: 'delayed',
                className: 'brand-v2',
            },
            {
                label: 'Others',
                totalData: status.noStatus,
                key: 'status',
                value: 'others',
                className: 'brand-v2',
            },
        ]

        setTabItems(tabItems)
    }

    const setPageData = (pageNumber: number) => {
        dispatch(setPageNumber(pageNumber))
    }

    const handleSearch = (values: string) => {
        if (values.length >= 3) {
            dispatch(setFilter({ ...filter, search: values, pageNumber: 1 }))
        } else if (values.length <= 0)
            dispatch(setFilter({ ...filter, search: '', pageNumber: 1 }))
    }

    const setTabFilter = async (data: ITabItem) => {
        dispatch(setSelectedStatus(data))
        dispatch(setPageNumber(1))
    }

    return {
        filterOverlayService,
        newBookingOverlayService,
        generateReportModalService,
        tabItems,
        statusSelected,
        loading,
        errorMessage,
        meta,
        data,
        setPageData,
        setLoading,
        setErrorMessage,
        navigate,
        handleSearch,
        setTabItems,
        setTabFilter,
        shipmentsData,
        ConfigureColumnsModalService,
        headers,
        ShipmentConfigureColumns,
        setHeaders,
    }
}

export default useShipments
