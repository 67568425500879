import { IRoute } from 'common/common.interface'
import Dashboard from 'client/ifb/pages/dashboard/dashboard.component'
import Bookings from 'client/ifb/pages/bookings/bookings.component'
import Inventory from 'client/ifb/pages/inventory/inventory.component'
import Products from 'client/ifb/pages/products/products.component'
import Quotations from 'client/ifb/pages/quotation/quotations.component'
import Shipments from 'client/ifb/pages/shipments/shipments.component'
import Invoices from 'client/ifb/pages/invoices/invoices.component'
import Volumes from 'client/ifb/pages/volumes/volumes.component'
import Receipts from 'client/ifb/pages/receipts/receipts.component'
import Orders from 'client/ifb/pages/orders/orders.component'
import ManageUsers from 'client/ifb/pages/manage-users/manage-users.component'
import ManageUserForm from 'client/ifb/pages/manage-users/manage-user-form/manage-user-form.component'
import ManageUserDetails from 'client/ifb/pages/manage-users/manage-user-details/manage-user-details.component'
import Declarations from 'client/ifb/pages/declarations/declarations.component'
import Overview from 'client/ifb/pages/overview/overview.component'
import Reports from 'client/ifb/pages/reports/reports.component'
import Settings from 'client/common/pages/settings/settings.component'
import QuotationDetails from 'client/ifb/pages/quotation/quotation-details/quotation-details.component'
import CreateQuotations from 'client/ifb/pages/quotation/create-quotations/create-quotation.component'
import Trackings from 'client/ifb/pages/trackings/trackings.component'
import TrackingDetails from 'client/ifb/pages/trackings/tracking-details/trackings-details.component'
import Performances from 'client/ifb/pages/performances/performances.component'
import BookingDetails from 'client/ifb/pages/bookings/booking-details/booking-details.component'
import NewBooking from 'client/ifb/pages/bookings/create-booking/create-booking.component'
import EditBooking from 'client/ifb/pages/bookings/edit-booking/edit-booking.component'
import BookAgain from 'client/ifb/pages/bookings/book-again/book-again.component'
import Emissions from 'client/ifb/pages/emissions/emissions.component'
import ShipmentDetails from 'client/ifb/pages/shipments/shipment-details/shipment-details.component'
import ProductsDetails from 'client/ifb/pages/products/products-details/products-details.component'
import ReceiptsDetails from 'client/ifb/pages/receipts/receipts-details/receipts-details.component'
import OrdersDetails from 'client/ifb/pages/orders/orders-details/orders-details.component'
import ControlTower from 'client/ifb/pages/control-tower/control-tower.component'

export const ifbRoutes: IRoute[] = [
    {
        path: '/',
        text: 'Dashboard',
        Content: Dashboard,
        show: false,
        icon: 'ri-dashboard-line',
        textTranslationCode: 'menu.dashboard',
    },
    {
        path: '/dashboard',
        text: 'Dashboard',
        Content: Dashboard,
        show: true,
        icon: 'ri-dashboard-line',
        textTranslationCode: 'menu.dashboard',
    },
    {
        path: 'forwarding',
        text: 'Forwarding',
        show: true,
        icon: 'ri-truck-line',
        textTranslationCode: 'menu.forwarding',
        expandable: true,
        sub: [
            {
                path: '/quotes',
                text: 'Quotes',
                Content: Quotations,
                show: true,
                textTranslationCode: 'menu.quotes',
            },
            {
                path: '/bookings',
                text: 'Bookings',
                Content: Bookings,
                show: true,
                textTranslationCode: 'menu.bookings',
            },
            {
                path: '/shipments',
                text: 'Shipments',
                Content: Shipments,
                show: true,
                textTranslationCode: 'menu.shipments',
            },
            {
                path: '/trackings',
                text: 'Trackings',
                Content: Trackings,
                show: true,
                textTranslationCode: 'menu.tracking',
            },
        ],
    },
    {
        path: 'booking-details/:id',
        text: 'Booking Details',
        Content: BookingDetails,
        textTranslationCode: 'menu.bookings_details',
    },
    {
        path: 'edit-booking',
        text: 'Edit Booking',
        Content: EditBooking,
        textTranslationCode: 'menu.bookings_edit',
    },
    {
        path: 'book-again',
        text: 'Booking Again',
        Content: BookAgain,
        textTranslationCode: 'menu.bookings_again',
    },
    {
        path: 'create-booking',
        text: 'New Booking',
        Content: NewBooking,
        textTranslationCode: 'menu.bookings_create',
    },
    {
        path: 'shipment-details/:id',
        text: 'Shipment Details',
        Content: ShipmentDetails,
        textTranslationCode: 'menu.shipments_details',
    },
    {
        path: 'trackings-details/:id/:data',
        text: 'Trackings Details',
        Content: TrackingDetails,
        textTranslationCode: 'menu.tracking_details',
    },
    {
        path: 'products-details/:id',
        text: 'Products Details',
        Content: ProductsDetails,
        textTranslationCode: 'menu.products_details',
    },
    {
        path: 'receipts-details/:id',
        text: 'Receipts Details',
        Content: ReceiptsDetails,
        textTranslationCode: 'menu.receipts_details',
    },
    {
        path: 'orders-details/:id',
        text: 'Orders Details',
        Content: OrdersDetails,
        textTranslationCode: 'menu.orders_details',
    },
    {
        path: 'quotation-details/:id',
        text: 'Quotation Details',
        Content: QuotationDetails,
        textTranslationCode: 'menu.quotes_details',
    },
    {
        path: 'create-quote',
        text: 'New Quote',
        Content: CreateQuotations,
        textTranslationCode: 'menu.quotes_new',
    },
    {
        path: 'customs',
        text: 'Customs',
        show: true,
        icon: 'ri-survey-line',
        expandable: true,
        textTranslationCode: 'menu.customs',
        sub: [
            {
                path: '/declarations',
                text: 'Declarations',
                Content: Declarations,
                show: true,
                textTranslationCode: 'menu.declarations',
            },
            {
                path: '/overview',
                text: 'Overview',
                Content: Overview,
                show: true,
                textTranslationCode: 'menu.overview',
            },
        ],
    },
    {
        path: 'warehouse',
        text: 'Warehouse',
        show: true,
        icon: 'ri-building-4-line',
        expandable: true,
        textTranslationCode: 'menu.warehouse',
        sub: [
            {
                path: '/inventory',
                text: 'Inventory',
                Content: Inventory,
                show: true,
                textTranslationCode: 'menu.inventory',
            },
            {
                path: '/products',
                text: 'Products',
                Content: Products,
                show: true,
                textTranslationCode: 'menu.products',
            },
            {
                path: '/receipts',
                text: 'Receipts',
                Content: Receipts,
                show: true,
                textTranslationCode: 'menu.receipts',
            },
            {
                path: '/orders',
                text: 'Orders',
                Content: Orders,
                show: true,
                textTranslationCode: 'menu.orders',
            },
        ],
    },
    {
        path: '/invoices',
        text: 'Invoices',
        Content: Invoices,
        show: true,
        icon: 'ri-refund-line',
        textTranslationCode: 'menu.invoices',
    },
    {
        path: '/manage-users',
        text: 'Manage User',
        Content: ManageUsers,
        show: true,
        icon: 'ri-shield-user-line',
        textTranslationCode: 'menu.manage_user',
    },
    {
        path: 'manage-users/details/:id',
        text: 'User Details',
        Content: ManageUserDetails,
        textTranslationCode: 'menu.manage_user',
    },
    {
        path: '/profile',
        text: 'Profile',
        Content: () => ManageUserForm({ isNew: false, isProfilePage: true }),
        textTranslationCode: 'menu.profile',
    },
    {
        path: 'manage-users/create/',
        text: 'Create User',
        Content: () => ManageUserForm({ isNew: true, isProfilePage: false }),
        textTranslationCode: 'menu.manage_user_create',
    },
    {
        path: 'manage-users/update/:id',
        text: 'Update User',
        Content: () => ManageUserForm({ isNew: false, isProfilePage: false }),
        textTranslationCode: 'menu.manage_user_update',
    },
    {
        path: '/reports',
        text: 'Reports',
        parentId: '15',
        Content: Reports,
        show: true,
        icon: 'ri-file-text-line',
        textTranslationCode: 'menu.reports',
    },
    {
        path: 'analytics',
        text: 'Analytics',
        show: true,
        icon: 'ri-line-chart-line',
        expandable: true,
        textTranslationCode: 'menu.analytics',
        sub: [
            {
                path: '/volumes',
                text: 'Volumes',
                icon: 'ri-arrow-right-s-line',
                Content: Volumes,
                show: true,
                textTranslationCode: 'menu.volumes',
            },
            {
                path: '/peformance',
                text: 'Peformances',
                icon: 'ri-arrow-right-s-line',
                Content: Performances,
                show: true,
                textTranslationCode: 'menu.performances',
            },
            {
                path: '/emissions',
                text: 'Emissions',
                icon: 'ri-arrow-right-s-line',
                Content: Emissions,
                show: true,
                textTranslationCode: 'menu.emissions',
            },
        ],
    },
    {
        path: 'control-tower',
        text: 'Control Tower',
        show: true,
        icon: 'ri-bar-chart-grouped-fill',
        Content: ControlTower,
    },
    {
        path: '/settings',
        text: 'Settings',
        parentId: '16',
        Content: Settings,
        show: true,
        icon: 'ri-settings-line',
        textTranslationCode: 'menu.settings',
    },
]
