import { IPillItem } from '@components/pills/pills.component'
import { ITabItem } from '@components/tab/tab.interface'
import {
    IIndustryPillItems,
    IUserRolePillItems,
} from './manage-user-form.interface'

export const tabFilterFormUser: ITabItem[] = [
    {
        key: 'status',
        value: 'details',
        label: 'Details',
        className: 'brand',
    },
    {
        key: 'status',
        value: 'configurations',
        label: 'Configurations',
        className: 'brand',
    },
]

export const userRolePillItems: IPillItem<IUserRolePillItems>[] = [
    {
        label: 'CUSTOMER',
        value: 'customer',
    },
    {
        label: 'STAFF',
        value: 'agent',
    },
    {
        label: 'CONTROL TOWER',
        value: 'controlTower',
    },
]

export const industryPillItems: IPillItem<IIndustryPillItems>[] = [
    {
        label: 'ALL INDUSTRY',
        value: 'all',
    },
    {
        label: 'FORWARDING',
        value: 'forwarding',
    },
    {
        label: 'WAREHOUSE',
        value: 'warehouse',
    },
    {
        label: 'ACCOUNTING',
        value: 'accounting',
    },
]
