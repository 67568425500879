/* eslint-disable no-unused-vars */
import { useModal } from '@components//modal/modal.service'
import useOverlay from '@components/overlay/overlay.service'
import { ITabItem } from '@components/tab/tab.interface'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { bookingConfigureColumns } from './bookings.static'
import {
    IBookings,
    IBookingsFilter,
    IBookinsStatusTotal,
} from './bookings.interface'
import { ITableColumn } from '@components/table/table.interface'
import ConfigureColumnsModal from '@components/configure-columns-modal/configure-columns-modal.component'
import { getBookingsData } from 'client/ifb/repository/booking.repository'
import { useDispatch, useSelector } from 'react-redux'
import {
    bookingsDataSelector,
    bookingsFilterSelector,
    bookingsMetaSelector,
    bookingsStatusTotalSelector,
    bookingsTabStatusFilterSelector,
    setBookinsData,
    setFilter,
    setPageNumber,
    setSelectedStatus,
} from './bookings.slice'
import { isEmptyArray } from 'formik'
import { Toast } from '@components/toast/toast.component'
import { initialFilter } from 'common/common.static'

const useBookings = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // overlay
    const filterOverlayService = useOverlay()
    const newBookingOverlayService = useOverlay()

    // modal
    const generateReportModalService = useModal()
    const ConfigureColumnsModalService = useModal()

    // state
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [tabItems, setTabItems] = useState<ITabItem[]>()
    const [headers, setHeaders] = useState<ITableColumn<IBookings>[]>([])

    const bookingsData = useSelector(bookingsDataSelector)
    const totalStatus = useSelector(bookingsStatusTotalSelector)
    const tabFilter = useSelector(bookingsTabStatusFilterSelector)
    const filter = useSelector(bookingsFilterSelector)
    const bookingsDataMeta = useSelector(bookingsMetaSelector)
    const [isInitialized, setIsInitialized] = useState<boolean>(false)
    // variable
    const { pageNumber, pageSize, status, search } = filter

    useEffect(() => {
        dispatch(setFilter(initialFilter))
        setIsInitialized(true)
    }, [])

    useEffect(() => {
        generateTabItems(totalStatus)
    }, [totalStatus])

    // get data
    useEffect(() => {
        if (isInitialized) loadData()
    }, [pageNumber, pageSize, status, tabFilter, search, isInitialized])

    // Get Data Function
    const loadData = async () => {
        const status = tabFilter.value

        let tmpFilter: IBookingsFilter = filter
        if (tabFilter?.childStatus) {
            const inProgressStat = tabFilter?.childStatus[0].value
            tmpFilter = { ...filter, status, inProgressStatus: inProgressStat }
        } else {
            tmpFilter = { ...filter, status }
        }
        try {
            setLoading(true)
            const actionResult = await getBookingsData(tmpFilter)
            dispatch(setBookinsData(actionResult))
            setLoading(false)
            if (isEmptyArray(actionResult.data)) {
                Toast({
                    header: 'Search Not Found!',
                    message:
                        "The keyword you've entered does not display any result.",
                    type: 'error',
                })
            }
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            setErrorMessage(errorMessage)
        }
    }

    // generate tab Items
    const generateTabItems = (status: IBookinsStatusTotal) => {
        const tabItems: ITabItem[] = [
            {
                label: 'All Bookings',
                labelTranslationCode: 'status.all_bookings',
                totalData: status.all,
                key: 'status',
                value: 'all',
                className: 'brand-v2',
            },
            {
                label: 'Arrived',
                totalData: status.arrived,
                key: 'status',
                value: 'confirmed',
                className: 'brand-v2',
            },
            {
                label: 'In Transit',
                // labelTranslationCode: 'status.wait_listed',
                totalData: status.inTransit,
                key: 'status',
                value: 'inTransit',
                className: 'brand-v2',
            },
            {
                label: 'Delayed',
                // labelTranslationCode: 'status.booked',
                totalData: status.delayed,
                key: 'status',
                value: 'delayed',
                className: 'brand-v2',
            },
            {
                label: 'Others',
                totalData: status.others,
                key: 'status',
                value: 'others',
                className: 'brand-v2',
            },
        ]
        setTabItems(tabItems)
    }

    const setPageData = (pageNumber: number) => {
        dispatch(setPageNumber(pageNumber))
    }

    const handleSearch = (values: string) => {
        if (values.length >= 3) {
            dispatch(setFilter({ ...filter, search: values, pageNumber: 1 }))
        } else if (values.length <= 0)
            dispatch(setFilter({ ...filter, search: '', pageNumber: 1 }))
    }

    const setTabFilter = async (data: ITabItem) => {
        dispatch(setSelectedStatus(data))
        dispatch(setPageNumber(1))
    }

    // segment general
    const allMNodal = (
        <>
            <ConfigureColumnsModal<IBookings>
                modalService={ConfigureColumnsModalService}
                columns={bookingConfigureColumns}
                setHeaders={setHeaders}
            />
        </>
    )

    return {
        filterOverlayService,
        newBookingOverlayService,
        generateReportModalService,
        tabItems,
        tabFilter,
        loading,
        errorMessage,
        bookingsDataMeta,
        setPageData,
        setLoading,
        handleSearch,
        setErrorMessage,
        navigate,
        setTabItems,
        setTabFilter,
        bookingsData,
        allMNodal,
        ConfigureColumnsModalService,
        headers,
    }
}

export default useBookings
