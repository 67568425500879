import TabMaster from '@components/tab-master/tab-master.component'
import Table from '@components/table/table.component'
import useShipments from './shipments.service'
import ShipmentFilterOverlay from './components/shipment-filter-overlay.component'
import './shipments.style.css'
import NewBookingOverlay from './components/new-booking-overlay.component'
import { IRShipment } from 'client/ifb/repository/interface/shipment.interface'
import { useTranslation } from 'react-i18next'
import ConfigureColumnsModal from '@components/configure-columns-modal/configure-columns-modal.component'

const Shipments = () => {
    const { t } = useTranslation()
    const {
        filterOverlayService,
        newBookingOverlayService,
        statusSelected,
        tabItems,
        loading,
        meta,
        handleSearch,
        setTabFilter,
        navigate,
        setPageData,
        data,
        headers,
        ShipmentConfigureColumns,
        setHeaders,
        ConfigureColumnsModalService,
    } = useShipments()

    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)] pt-0 xl:pt-0 sm:pt-0">
                <TabMaster
                    items={tabItems || []}
                    tabFilter={statusSelected}
                    onChange={(item) => setTabFilter(item)}
                    //  search ----------------------------------------------------------------
                    useSearch={true}
                    placeHolderSearch={'Search Shipment Number'}
                    onSearchSubmit={handleSearch}
                    containerSearchClassName={'flex-1'}
                    //  button for overlay or something ----------------------------------------
                    iconButton1={{
                        onClick: () =>
                            filterOverlayService.toggleOverlayHandling(),
                        icon: 'ri-filter-2-line',
                        filterOverlayComponent: (
                            <ShipmentFilterOverlay
                                filterOverlayService={filterOverlayService}
                            />
                        ),
                    }}
                    iconButton2={{
                        onClick: () =>
                            ConfigureColumnsModalService.openModalHandling(),
                        icon: 'ri-settings-2-line',
                    }}
                    actionButton1={{
                        onClick: () => {
                            navigate('/create-booking')
                        },
                        OverlayComponent: (
                            <NewBookingOverlay
                                overlayService={newBookingOverlayService}
                            />
                        ),
                        label: t('menu.bookings_new').toUpperCase(),
                        className: '!text-size-S',
                        variant: 'brand-v2-inverse',
                    }}
                />

                <Table<IRShipment>
                    headers={headers}
                    data={data}
                    loading={loading}
                    nextHandling={(page) => {
                        setPageData(page)
                    }}
                    previousHandling={(page) => {
                        setPageData(page)
                    }}
                    meta={meta}
                    moduleTitle={'Shipment'}
                    onRowClick={(data) => {
                        const id = data.shipmentID
                        navigate('/shipment-details/' + id)
                    }}
                    enableExport={true}
                    variantButtonExport="brand-v2-inverse"
                    // modalService={generateReportModalService}
                    // components={<ShipmentFilter isGenerateReport={true} />}
                    // resetFilter={() => {}}
                    // getDataGenerate={() => {}}
                    // generateReportData={ShipmentDataDummy}
                    // GenerateReportHeaders={ShipmentSummaryHeader}
                    // removeFilter={() => {}}
                />
            </div>

            {/* all modal*/}
            <>
                <ConfigureColumnsModal<IRShipment>
                    modalService={ConfigureColumnsModalService}
                    columns={ShipmentConfigureColumns}
                    setHeaders={setHeaders}
                />
            </>
        </div>
    )
}

export default Shipments
