var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var TrackerV2 = function (_a) {
    var items = _a.items;
    return !items ? (_jsx(_Fragment, {})) : (_jsx("div", __assign({ className: "flex flex-col leading-none" }, { children: items.map(function (item, index) { return (_jsxs("div", __assign({ className: "flex items-start space-x-4 h-[48px]" }, { children: [_jsxs("div", __assign({ className: "flex flex-col items-center h-full  ".concat(index === items.length - 1 ? 'justify-start mt-[6px]' : 'justify-end') }, { children: [index === 0 ? (_jsx("div", { className: "w-4 h-4 rounded-full themes-bg-gray-v9" })) : (_jsx("div", { className: "w-4 h-4 border-4 themes-border-color rounded-full" })), index < items.length - 1 && (_jsx("div", { className: "h-[20px] w-[4px] rounded mt-[6px] themes-bg-gray-v3" }))] })), _jsxs("div", __assign({ className: "flex flex-col" }, { children: [_jsx("span", __assign({ className: "text-sm themes-text-gray-v5" }, { children: item.date })), _jsx("span", __assign({ className: "text-base themes-text-gray-v9 ".concat(index === 0 ? ' font-semibold' : '') }, { children: item.statusDesc }))] }))] }), index)); }) })));
};
export default TrackerV2;
