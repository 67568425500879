import SimpleTable from '@components/simple-table/simple-table.component'
import { containerHeaders } from '../booking-details.static'
import { IBookingDetailsContainer } from '../../bookings.interface'
import { metaDummy2 } from 'common/common.static'

const Container = ({ data }: { data: IBookingDetailsContainer[] }) => {
    return (
        <div className="h-full max-h-[calc(100vh-283px)]">
            <SimpleTable<IBookingDetailsContainer>
                headers={containerHeaders}
                data={data}
                usePagination
                nextHandling={(page) => {
                    console.log('hit Next API -> ' + page)
                }}
                previousHandling={(page) => {
                    console.log('hit Prev API -> ' + page)
                }}
                meta={metaDummy2(data.length)}
                isBorder={false}
            />
        </div>
    )
}

export default Container
