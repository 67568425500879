import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IMeta, IResponseData } from 'common/common.interface'
import { ITabItem } from '@components/tab/tab.interface'
import { RootState } from 'store'
import {
    EPortalAccess,
    ERoleType,
    IActionListModule,
    IFilterModuleTemplates,
    IManageUser,
    IManageUserFilter,
    IManageUserStatusTotal,
    IModuleTemplate,
    // IManageUserDetails,
} from './manage-users.interface'
import {
    IDetailUser,
    IManageUserLog,
} from './manage-user-details/manage-users-details.interface'
// import { intialValueShipmentDetails } from './shipment-details/shipment-details.static'

interface IManageUserSlice {
    data: IManageUser[]
    dataLog: IDetailUser
    dataDetail: IManageUser
    meta: IMeta
    search: string | null
    responseMessage: string
    filter: IManageUserFilter
    statusSelected: ITabItem
    statusTotal: IManageUserStatusTotal

    // segment details
    // detailsData: IManageUserDetails,

    // segment create user
    currentUser: IManageUser
    moduleTemplates: IModuleTemplate[]
    filterModuleTemplate: IFilterModuleTemplates
    chooseModule: IModuleTemplate
}

export const initialState: IManageUserSlice = {
    data: [],
    dataLog: {
        data: [],
        meta: {
            current_page: 0,
            last_page: 0,
            per_page: 0,
            total_page: 0,
            total_Items: 0,
            from: 0,
            to: 0,
            index_end: 0,
            index_start: 0,
        },
        filter: {
            status: '',
            search: '',
            pageNumber: 1,
            pageSize: 50,
        },
    },
    dataDetail: {
        id: '',
        userName: '',
        fullName: '',
        email: '',
        jobTitle: '',
        nativeStaffData: null,
        jobCategory: '',
        organizationName: '',
        country: '',
        province: '',
        city: '',
        addressDetails: '',
        mobilePhoneNumber: '',
        homePhoneNumber: '',
        contactName: '',
        phoneNumber: '',
        role: null,
        lastLogin: '',
        createdAt: '',
        updatedAt: '',
        deletedAt: null,
        suspendedAt: null,
        createdBy: null,
        updatedBy: null,
        deletedBy: null,
        isActive: false,
        resetPassword: null,
    },
    meta: {
        current_page: 0,
        last_page: 0,
        per_page: 0,
        total_page: 0,
        total_Items: 0,
        from: 0,
        to: 0,
        index_end: 0,
        index_start: 0,
    },
    search: '',
    responseMessage: '',
    filter: {
        status: '',
        inProgressStatus: '',
        pageNumber: 1,
        pageSize: 50,
    },
    statusTotal: {
        all: 0,
        active: 0,
        suspended: 0,
    },
    statusSelected: {
        value: 'all',
        key: 'status',
        label: 'All User',
        totalData: 0,
    },

    currentUser: {
        id: '',
        userName: '',
        fullName: '',
        email: '',
        jobTitle: '',
        nativeStaffData: null,
        jobCategory: '',
        organizationName: '',
        country: '',
        province: '',
        city: '',
        addressDetails: '',
        mobilePhoneNumber: '',
        homePhoneNumber: '',
        contactName: '',
        phoneNumber: '',
        role: {
            roleType: ERoleType.Admin,
            portalAccess: EPortalAccess.Agent,
            moduleAccessList: [],
        },
        lastLogin: '',
        createdAt: '',
        updatedAt: '',
        deletedAt: null,
        suspendedAt: null,
        createdBy: null,
        updatedBy: null,
        deletedBy: null,
        isActive: false,
        resetPassword: null,
    },
    moduleTemplates: [],
    filterModuleTemplate: {
        portalAcess: 'customer',
        industry: '',
    },
    chooseModule: {
        id: '',
        parentId: '',
        moduleName: '',
        actionList: [],
        portalAccess: [],
        industry: [],
        loadActionList: [],
    },
}

const ManageUserSlice = createSlice({
    name: 'ManageUserIFB',
    initialState,
    reducers: {
        setManageUserData(
            state,
            action: PayloadAction<IResponseData<IManageUser[]>>,
        ) {
            const { data, isSuccess, message, meta, links, additionals } =
                action.payload

            let AllData: number = 0
            let activeData: number = 0
            let suspendedData: number = 0

            additionals.stats.forEach(
                (data: { status: string; count: number }) => {
                    if (data.status === 'Active') {
                        activeData = data.count
                    } else if (data.status === 'Suspended') {
                        suspendedData = data.count
                    } else if (data.status === 'All') {
                        AllData = data.count
                    }
                },
            )

            const d = {
                data: data,
                isSuccess,
                message,
                meta,
                links,
                statusTotal: {
                    ...state.statusTotal,
                    all: AllData,
                    active: activeData,
                    suspended: suspendedData,
                },
            }

            return {
                ...state,
                ...d,
            }
        },
        setManageUserDataLog(
            state,
            action: PayloadAction<IResponseData<IManageUserLog[]>>,
        ) {
            const { data, isSuccess, message, meta, links } = action.payload

            const d = {
                dataLog: { ...state.dataLog, data, meta },
                isSuccess,
                message,
                links,
            }

            return {
                ...state,
                ...d,
            }
        },
        setManageUserDataDetail(state, action: PayloadAction<IManageUser>) {
            // const { data, isSuccess, message, meta, links } = action.payload

            const d = {
                dataDetail: action.payload,
            }

            return {
                ...state,
                ...d,
            }
        },
        setSelectedStatus(state, action: PayloadAction<ITabItem>) {
            const statusSelected = action.payload

            return {
                ...state,
                statusSelected,
            }
        },
        setFilter(state, action: PayloadAction<IManageUserFilter>) {
            const filter = action.payload
            return {
                ...state,
                filter,
            }
        },
        setPageNumber(state, action: PayloadAction<number>) {
            const pageNumber = action.payload
            const filter = { ...state.filter, pageNumber }
            return {
                ...state,
                filter,
            }
        },
        setLogFilter(state, action: PayloadAction<IManageUserFilter>) {
            const filter = action.payload
            return {
                ...state,
                dataLog: { ...state.dataLog, filter },
            }
        },
        setLogPageNumber(state, action: PayloadAction<number>) {
            const pageNumber = action.payload
            const filter = { ...state.filter, pageNumber }
            return {
                ...state,
                dataLog: { ...state.dataLog, filter },
            }
        },

        // segment create user
        setCurrentUser(
            state,
            action: PayloadAction<IResponseData<IManageUser>>,
        ) {
            const currentUser = action.payload.data
            return {
                ...state,
                currentUser,
            }
        },
        setModuleTemplate(
            state,
            action: PayloadAction<IResponseData<IModuleTemplate[]>>,
        ) {
            const moduleTemplates = action.payload.data
            const setData = moduleTemplates.map((item) => {
                const loadActionList = item.actionList.map((action) => {
                    let label = ''
                    switch (action) {
                        case 'accessModule':
                            label = 'Access Module'
                            break
                        case 'viewDetails':
                            label = 'View Details'
                            break
                        case 'create':
                            label = 'Create'
                            break
                        case 'update':
                            label = 'Update'
                            break
                        case 'delete':
                            label = 'Delete'
                            break
                    }

                    return {
                        label,
                        value: action,
                        isActive: true,
                    }
                })
                return {
                    ...item,
                    loadActionList,
                }
            })
            return {
                ...state,
                moduleTemplates: setData,
                chooseModule: setData[0],
            }
        },
        setFilterModule(state, action: PayloadAction<IFilterModuleTemplates>) {
            const filterModuleTemplate = action.payload
            return {
                ...state,
                filterModuleTemplate,
            }
        },
        setChooseModule(state, action: PayloadAction<IModuleTemplate>) {
            const data = action.payload
            const moduleTemplate = state.moduleTemplates
            const updateData = moduleTemplate.map((item) =>
                item.id === data.id ? { ...item, ...data } : item,
            )
            return {
                ...state,
                moduleTemplates: updateData,
                chooseModule: data,
            }
        },
    },
})

// these all the variables used for selector
export const manageUserDataSelector = (state: RootState) =>
    state.manageUserIFB.data || {}
export const manageUserFilterSelector = (state: RootState) =>
    state.manageUserIFB.filter || {}
export const manageUserMetaSelector = (state: RootState) =>
    state.manageUserIFB.meta || {}
export const manageUserLogMetaSelector = (state: RootState) =>
    state.manageUserIFB.dataLog.meta || {}
export const manageUserLogFilterSelector = (state: RootState) =>
    state.manageUserIFB.dataLog.filter || {}
export const manageUserStatusTotalSelector = (state: RootState) =>
    state.manageUserIFB.statusTotal || {}
export const manageUserTabStatusFilterSelector = (state: RootState) =>
    state.manageUserIFB.statusSelected || {}

// segment details
export const manageUserDataLogSelector = (state: RootState) =>
    state.manageUserIFB.dataLog.data || {}
export const manageUserDetailSelector = (state: RootState) =>
    state.manageUserIFB.dataDetail || {}

// segment create user
export const currentUserSelector = (state: RootState) =>
    state.manageUserIFB.currentUser || {}
export const moduleTemplatesSelector = (state: RootState) =>
    state.manageUserIFB.moduleTemplates || {}
export const filterModuleTemplateSelector = (state: RootState) =>
    state.manageUserIFB.filterModuleTemplate || {}
export const chooseModuleSelector = (state: RootState) =>
    state.manageUserIFB.chooseModule || {}

// all actions
export const {
    setManageUserDataLog,
    setManageUserDataDetail,
    setManageUserData,
    setSelectedStatus,
    setPageNumber,
    setFilter,
    setLogFilter,
    setLogPageNumber,
    setCurrentUser,
    setModuleTemplate,
    setFilterModule,
    setChooseModule,
} = ManageUserSlice.actions

// Reducer
export default ManageUserSlice.reducer
