import { useEffect, useRef, useState } from 'react';
export function useTableExpandable(_a) {
    var data = _a.data;
    var _b = useState(null), expandedRow = _b[0], setExpandedRow = _b[1];
    var _c = useState(null), hoveredRowIndex = _c[0], setHoveredRowIndex = _c[1];
    var contentRef = useRef(null);
    useEffect(function () {
        if (contentRef.current) {
            contentRef.current.style.maxHeight =
                expandedRow === null
                    ? '0px'
                    : "".concat(contentRef.current.scrollHeight, "px");
        }
    }, [expandedRow]);
    var toggleExpandRow = function (idx) {
        setExpandedRow(expandedRow === idx ? null : idx);
    };
    return {
        data: data,
        expandedRow: expandedRow,
        toggleExpandRow: toggleExpandRow,
        contentRef: contentRef,
        hoveredRowIndex: hoveredRowIndex,
        setHoveredRowIndex: setHoveredRowIndex,
    };
}
