import { IUseUserAccessForm } from 'client/common/pages/user-acces/user-access-form/user-access-form.service'
import './manage-user-form.style.css'
import useManageUserForm from './manage-user-form.service'
import Details from './contents/details.component'
import Configurations from './contents/configurations.component'
import Tab from '@components/tab/tab.component'
import { tabFilterFormUser } from './manage-user-form.static'
import Button from '@components/button/button.component'
import { FormikProvider } from 'formik'
import Spinner from '@components/spinner/spinner.component'

const ManageUserForm = ({ isNew, isProfilePage }: IUseUserAccessForm) => {
    const useService = useManageUserForm(isNew, isProfilePage)

    const loadContent = () => {
        const contents: Record<string, JSX.Element> = {
            details: (
                <Details
                    useService={useService}
                    statusPage={{ isNew, isProfilePage }}
                />
            ),
            configurations: <Configurations useService={useService} />,
        }
        return contents[useService.tabFilter.value]
    }

    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-col flex-grow w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)]">
                <Tab
                    containerClassName="px-3"
                    items={isNew ? tabFilterFormUser : [tabFilterFormUser[0]]}
                    tabFilter={useService.tabFilter}
                    onChange={(item) => {
                        useService.setTabFilter(item)
                    }}
                />
                <div className="border-b"></div>

                <div className="h-[calc(100vh-14rem)] overflow-auto lg:overflow-hidden  ">
                    {useService.loading ? (
                        <div className="h-full flex justify-center items-center">
                            <Spinner label="Please Wait..." />
                        </div>
                    ) : (
                        <FormikProvider
                            value={
                                isNew
                                    ? useService.formikCreate
                                    : useService.formikUpdate
                            }
                        >
                            {loadContent()}
                        </FormikProvider>
                    )}
                </div>

                <div className="border-b"></div>
                <div className="p-3 flex justify-between ">
                    <Button
                        onClick={() => {
                            useService.navigate('/manage-users')
                        }}
                        label="BACK"
                        className="w-btnRegularWidth mobile:w-btnSmallWidth"
                        variant="brand-v2-inverse"
                    />
                    {!isProfilePage && (
                        <Button
                            onClick={() => {
                                useService.submitForm()
                            }}
                            label="SAVE"
                            className="w-btnRegularWidth mobile:w-btnSmallWidth"
                            variant="brand-v2-inverse"
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default ManageUserForm
