export const ifbEndpoints = {
    // dashboard
    news_scrapper: 'Scrapper/ifb',
    scfi_index: 'Scrapper/sse',
    shipmentStatus: 'UniversalShipment/GetShipmentStatus',
    shipmentMilestoneStatus: 'UniversalShipment/GetShipmentMilestones',
    bookingStatus: 'BookingShipment/GetCountStatus',
    containerModeStatus: 'UniversalShipment/GetContainerTransportStatus',

    //bookings
    bookings: 'BookingShipment',
    bookingsGetMetadata: 'BookingShipment/GetMetadata',
    bookingsGetContactMetadata: 'BookingShipment/GetContactMetadata',
    GetOriginDestination: 'BookingShipment/GetOriginDestination',
    bookingsCreate: 'BookingShipment/create',

    //volume
    barChartVolume: 'Analytics/Volume/Transport',
    donatChartVolume: 'Analytics/Volume',

    // performance
    performance_getStatus: 'Analytics/Performance/ShipmentStatus',
    performance_getByPorts: 'Analytics/Performance/PerformanceByPorts',
    performance_averageTransitTime: 'Analytics/Performance/ShipmentTransitTime',
    performance_shipmentsTransitTimeByPorts:
        'Analytics/Performance/ShipmentTransitTimeByPort',
    performance_averageTransitTimeByMode:
        'Analytics/Performance/ShipmentTransitRecordedByModes',
    performance_totalCostByModes: 'Analytics/Performance/TotalCostByModes',
    performance_totalMonthlyCost: 'Analytics/Performance/TotalMonthlyCost',
    performance_portCharges: 'Analytics/Performance/PortCharges',
    performance_totalCostByPorts: 'Analytics/Performance/TotalCostByPorts',
    performance_milestoneGroupDayRange:
        'Analytics/Performance/MilestoneGroupDayRange',
    performance_averageDaysDelayed: 'Analytics/Performance/MonthlyDelayed',
    performance_transitTimeRouteGroup:
        'Analytics/Performance/TransitTimeRouteGroup',

    // control tower
    control_tower_RelatedKeyFigure: 'ControlTower/Overview/RelatedKeyFigure',
    control_tower_Overview: 'ControlTower/Overview/',
    control_tower_CustomerHistoryDevelopmnent:
        'ControlTower/Overview/Customer-History-Development',

    //shipments
    shipment_getAll: 'UniversalShipment',

    // trackings
    tracking_getAll: 'Container/Get',

    //manage user
    manage_user_getAll: 'Staff',
    manage_user_getCurrentUser: 'Staff/current-staff',
    manage_user_getModuleTemplates: 'Staff/module-templates',
}
