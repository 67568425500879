import { ITabItem } from '@components/tab/tab.interface'
import { ITableColumn } from '@components/table/table.interface'
import { ReceiptDataDummy } from './receipts.dummy'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { IReceipt } from './receipts.interface'
import { IConfigureColumns } from '@components/configure-columns-modal/configure-column-modal.interface'

export const ReceiptStatusHeader: ITabItem[] = [
    {
        label: 'All Receipts',
        labelTranslationCode: 'status.all_receipts',
        totalData: ReceiptDataDummy.length,
        key: 'status',
        value: 'allReceipts',
        className: 'brand-v2',
    },
    {
        label: 'Received',
        labelTranslationCode: 'status.received',
        totalData: ReceiptDataDummy.filter(
            (x: any) => x.docketStatus === 'Received',
        ).length,
        key: 'status',
        value: 'Received',
        className: 'brand-v2',
    },
    {
        label: 'In Transit',
        labelTranslationCode: 'status.in_transit',
        totalData: ReceiptDataDummy.filter(
            (x: any) => x.docketStatus === 'In Transit',
        ).length,
        key: 'status',
        value: 'In Transit',
        className: 'brand-v2',
    },
    {
        label: 'Delayed',
        labelTranslationCode: 'status.delayed',
        totalData: ReceiptDataDummy.filter(
            (x: any) => x.docketStatus === 'Delayed',
        ).length,
        key: 'status',
        value: 'Delayed',
        className: 'brand-v2',
    },
    {
        label: 'Others',
        labelTranslationCode: 'status.others',
        totalData: ReceiptDataDummy.filter(
            (x: any) => x.docketStatus === 'Others',
        ).length,
        key: 'status',
        value: 'Others',
        className: 'brand-v2',
    },
]

export const ReceiptSummaryHeader: ITableColumn[] = [
    {
        label: 'Customer Ref',
        accessor: 'customerRef',
    },
    {
        label: 'Warehouse',
        accessor: 'warehouseName',
    },
    {
        label: 'Docket ID',
        accessor: 'docketID',
    },
    {
        label: 'Booking Date',
        accessor: 'bookingDate',
    },
    {
        label: 'Arrival Date',
        accessor: 'arrivalDate',
    },
    {
        label: 'Docket Status',
        accessor: 'docketStatus',
    },
    {
        label: 'Total Units',
        accessor: 'totalUnits',
    },
    {
        label: 'Total Pallets',
        accessor: 'totalPallets',
    },
    {
        label: 'Finalised Date',
        accessor: 'finalisedDate',
    },
    {
        label: 'Service Level',
        accessor: 'serviceLevel',
    },
]

export const ReceiptConfigureColumns: IConfigureColumns<IReceipt>[] = [
    {
        title: 'Flags',
        columns: [
            {
                statusColumn: 'selectedColumns',
                label: 'Customer Ref',
                labelTranslation: 'receipts.customer_ref',
                accessor: 'customerRef',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Warehouse',
                labelTranslation: 'receipts.warehouse_name',
                accessor: 'warehouseName',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Docket ID',
                labelTranslation: 'receipts.docket_id',
                accessor: 'docketID',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Booking Date',
                labelTranslation: 'receipts.booking_date',
                accessor: 'bookingDate',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Arrival Date',
                labelTranslation: 'receipts.arrival_date',
                accessor: 'arrivalDate',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Docket Status',
                labelTranslation: 'receipts.docket_status',
                accessor: 'docketStatus',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Total Units',
                labelTranslation: 'receipts.total_units',
                accessor: 'totalUnits',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Total Pallets',
                labelTranslation: 'receipts.total_pallets',
                accessor: 'totalPallets',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Finalised Date',
                labelTranslation: 'receipts.finalised_date',
                accessor: 'finalisedDate',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Service Level',
                labelTranslation: 'receipts.service_level',
                accessor: 'serviceLevel',
            },
        ],
    },
]

export const filterWarehouse: IDropdownItem<unknown>[] = [
    {
        label: 'HarborView Warehouse',
        value: 'HarborView Warehouse',
    },
    {
        label: 'Summit Storage Solutions',
        value: 'Summit Storage Solutions',
    },
    {
        label: 'Riverside Logistics Center',
        value: 'Riverside Logistics Center',
    },
    {
        label: 'MetroWare Warehouse',
        value: 'MetroWare Warehouse',
    },
    {
        label: 'Valley Vista Warehouse',
        value: 'Valley Vista Warehouse',
    },
    {
        label: 'Coastal Cargo Warehouse',
        value: 'Coastal Cargo Warehouse',
    },
    {
        label: 'Prime Storage Facility',
        value: 'Prime Storage Facility',
    },
    {
        label: 'UrbanHub Distribution Center',
        value: 'UrbanHub Distribution Center',
    },
    {
        label: 'Skyline Storage Depot',
        value: 'Skyline Storage Depot',
    },
    {
        label: 'Pioneer Warehouse Solutions',
        value: 'Pioneer Warehouse Solutions',
    },
    {
        label: 'Evergreen Logistics Hub',
        value: 'Evergreen Logistics Hub',
    },
    {
        label: 'Horizon Storage Services',
        value: 'Horizon Storage Services',
    },
    {
        label: 'Unity Warehouse Complex',
        value: 'Unity Warehouse Complex',
    },
    {
        label: 'SwiftStock Warehousing Solutions',
        value: 'SwiftStock Warehousing Solutions',
    },
    {
        label: 'PeakLogix Distribution Center',
        value: 'PeakLogix Distribution Center',
    },
]

export const filterServiceLevels: IDropdownItem<unknown>[] = [
    {
        label: 'Standard',
        value: 'Standard',
    },
    {
        label: 'Expedity',
        value: 'Expedity',
    },
    {
        label: 'Economy',
        value: 'Economy',
    },
    {
        label: 'Priority',
        value: 'Priority',
    },
    {
        label: 'Next-Day',
        value: 'Next-Day',
    },
    {
        label: 'Same-Day',
        value: 'Same-Day',
    },
    {
        label: 'Overnight',
        value: 'Overnight',
    },
    {
        label: 'Rush',
        value: 'Rush',
    },
    {
        label: 'Basic',
        value: 'Basic',
    },
    {
        label: 'Premium',
        value: 'Premium',
    },
    {
        label: 'Express',
        value: 'Express',
    },
    {
        label: 'Ground',
        value: 'Ground',
    },
    {
        label: 'Scheduled',
        value: 'Scheduled',
    },
]
