import {
    IDataTradeLanesTable,
    IUseControlTower,
} from '../control-tower.interface'
import SplitTable from '@components/split-table/split-table.component'
import {
    tabItemFilterTradeLanes,
    tableTradeLanesHeaders,
} from '../control-tower.static'
import Tab from '@components/tab/tab.component'
import Pagination from '@components/pagination/pagination.component'
import { metaDummy } from '../../bookings/bookings.dummy'
import InfoCardGroup from '@components/InfoCardGroup/Info-card-group.component'
import { DoughnutChart } from '@components/rechart-component'
import ColorPieChart from '../components/color-pie-chart.component'

const TradeLanes = ({ service }: { service: IUseControlTower }) => {
    return (
        <>
            <div className="col-span-4 border-r" style={{ height: 'inherit' }}>
                <div className="p-2 ">
                    <div className="font-bold text-size-S">Region</div>
                    <div className="flex-grow">
                        <InfoCardGroup
                            items={[
                                {
                                    value: '225,518',
                                    label: 'Overall Shipments',
                                },
                                {
                                    value: '45',
                                    label: 'Overall Region',
                                },
                            ]}
                            titleClass="!text-size-XS"
                            descClass="!text-[9px]"
                        />
                        <DoughnutChart
                            data={service.pieChartTradeLanes.regionPieChart}
                            colorChart={
                                <ColorPieChart
                                    items={
                                        service.pieChartTradeLanes
                                            .regionPieChart
                                    }
                                />
                            }
                        />
                    </div>
                </div>
                <div className="border-b"></div>
                <div className="p-2">
                    <div className="px-2 font-bold text-size-S">Carrier</div>
                    <div className="flex-grow">
                        <InfoCardGroup
                            items={[
                                {
                                    value: '64',
                                    label: 'Overall Carrier',
                                },
                            ]}
                            titleClass="!text-size-XS"
                            descClass="!text-[9px]"
                        />
                        <DoughnutChart
                            data={service.pieChartTradeLanes.carrierPieChart}
                            colorChart={
                                <ColorPieChart
                                    items={
                                        service.pieChartTradeLanes
                                            .carrierPieChart
                                    }
                                />
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="col-span-4 border-r" style={{ height: 'inherit' }}>
                <div className="flex justify-between items-center px-2  h-[40px]">
                    <Tab
                        items={tabItemFilterTradeLanes}
                        tabFilter={service.tabFilterTradeLanes}
                        onChange={(item) => {
                            service.setTabFilterTradeLanes(item)
                        }}
                        containerClassName="!overflow-y-hidden !text-size-XS !p-0 !border-none"
                        containerItemClass="!min-h-[40px] h-[40px]"
                    />
                    <Pagination
                        containerClassName="!p-0 flex items-center h-full"
                        className="!h-[27px]"
                        btnClassName="!h-[27px] flex items-center justify-center cursor-pointer"
                        meta={metaDummy}
                        previousHandling={(page) => {}}
                        nextHandling={(page) => {}}
                    />
                </div>

                <div className="h-[calc(100vh-17rem)] border-t">
                    <SplitTable<IDataTradeLanesTable>
                        columns={tableTradeLanesHeaders}
                        data={service.dataTradeLanesTable}
                        thClass="text-size-XS"
                        tdClass="text-size-XS"
                        // onRowClick={service.loadHistoryData}
                    />
                </div>
            </div>
        </>
    )
}

export default TradeLanes
