/* eslint-disable no-unused-vars */
import FormPackLineModal from './components/form-pack-line-modal.component'
import ConfirmationCreateBookingModal from './components/confirmation-create-booking-modal.component'
import BookBy from './contents/book-by.component'
import FormCreateBooking from './contents/form-create-booking'
import useCreateBooking from './create-booking.service'
import FormContainerModal from './components/form-container.component'

const CreateBooking = () => {
    const CBService = useCreateBooking()

    const loadContent = () => {
        const contents: Record<string, JSX.Element> = {
            bookBy: <BookBy CBService={CBService} />,
            goToFormCreate: <FormCreateBooking CBService={CBService} />,
        }
        return contents[CBService.tabFilter.value]
    }

    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)]">
                {loadContent()}
            </div>
            <ConfirmationCreateBookingModal CBService={CBService} />
            <FormPackLineModal CBService={CBService} />
            <FormContainerModal CBService={CBService} />
        </div>
    )
}

export default CreateBooking
