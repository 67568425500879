import GoogleMap from '@components/google-map/google-map.component'
import SimpleTable from '@components/simple-table/simple-table.component'
import Pagination from '@components/pagination/pagination.component'
import { ITabItem } from '@components/tab/tab.interface'
import { useState } from 'react'
import TabMaster from '@components/tab-master/tab-master.component'
import {
    PointMap,
    shipmentTableTab,
    shipmentTrackingHeader,
} from '../dashboard.static'
import { IRShipment } from 'client/ifb/repository/interface/shipment.interface'
import { IMeta } from '@components/pagination/pagination.interface'

interface IMapWithTableProps {
    meta?: IMeta
    data: IRShipment[]
    loading: boolean
    tabFilter: ITabItem
    setPageData: (nextIndex: number) => void
    setTabFilter: (data: ITabItem) => Promise<void>
}
function MapWithTable({
    meta,
    data,
    loading,
    setPageData,
    setTabFilter,
    tabFilter,
}: IMapWithTableProps) {
    return (
        <div className="grid grid-rows-12 h-full ">
            <div className="row-span-6 flex flex-col">
                <div className="lg:!h-[40vh] md:!h-[30vh] sm:!h-[30vh] p-2">
                    <GoogleMap points={PointMap()} />
                </div>
                <div className="text-[16px] font-bold themes-text-gray-v9 border-b pt-2 px-2 text-title-card-dashboard">
                    Tracking Shipment Location
                </div>
                <div className="items-center grid grid-cols-2">
                    <TabMaster
                        items={shipmentTableTab}
                        tabFilter={tabFilter}
                        onChange={(item) => setTabFilter(item)}
                        useSearch={false}
                        placeHolderSearch={'Search Shipment Number'}
                        onSearchSubmit={() => {}}
                        containerSearchClassName={'flex'}
                        tabClassname={'text-[14px]'}
                        containerClassname={'!border-0'}
                    />
                    <div className="flex justify-end sm:justify-center">
                        <Pagination
                            containerClassName="!py-0"
                            meta={meta}
                            previousHandling={(page) => {
                                setPageData(page)
                            }}
                            nextHandling={(page) => {
                                setPageData(page)
                            }}
                        />
                    </div>
                </div>
            </div>
            {/* Table */}
            <div className="lg:row-span-6 md:row-span-6 sm:row-span-6 overflow-y-auto scrollable-content lg:h-auto md:h-[35vh] sm:h-[38vh]">
                <SimpleTable<IRShipment>
                    headers={shipmentTrackingHeader}
                    headerCostum={'themes-bg-gray-v1'}
                    loading={loading}
                    data={data}
                    thClass={'first:!pl-[0.3rem] !px-[0.2rem]'}
                    tdClass={'first:!pl-[0.3rem] !px-[0.2rem]'}
                />
            </div>
        </div>
    )
}

export default MapWithTable
