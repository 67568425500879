import { IBarChartStackedData } from '@components/rechart-component/rechart-interface'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
import {
    IBarChartRoadData,
    IDonatChartShipmentRespon,
    IBarChartSeaAndRailData,
    IBarChartAirData,
    IBarChartCourierData,
    IPieChartAdditionalData,
    IResponseDropdownVolumeDashboard,
} from './volumes.interface'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { IFieldDropdownMetaData } from '../bookings/bookings.interface'
import { numberToCurrency } from 'common/common.service'
import { IPieChartData } from '@components/rechart-component/pie/doughnut-chart.component'

interface IVolumeState {
    itemTotalBarChart: {
        sea: {
            fcl: number
            lcl: number
        }
        road: {
            fcl: number
            lcl: number
            ftl: number
        }
        rail: {
            fcl: number
            lcl: number
        }
        air: {
            lse: number
        }
        cour: {
            fcl: number
        }
        donatShipment: number
    }
    barChart: {
        road: IBarChartStackedData
        sea: IBarChartStackedData
        rail: IBarChartStackedData
        air: IBarChartStackedData
        courier: IBarChartStackedData
    }
    donatChart: IPieChartData<IPieChartAdditionalData>[]
    volumeMeasure: IDropdownItem[]
    weightMeasure: IDropdownItem[]
}

export const initialState: IVolumeState = {
    barChart: {
        road: {
            key: [
                {
                    id: 'f1',
                    color: '#9D75AD',
                },
                {
                    id: 'f2',
                    color: '#D1B0DE',
                },
                {
                    id: 'f3',
                    color: '#EDDAFC',
                },
            ],
            data: [],
        },
        sea: {
            key: [
                {
                    id: 'f1',
                    color: '#5280C6',
                },
                {
                    id: 'f2',
                    color: '#DADEFC',
                },
            ],
            data: [],
        },
        rail: {
            key: [
                {
                    id: 'f1',
                    color: '#E3CD9A',
                },
                {
                    id: 'f2',
                    color: '#FFF6E1',
                },
            ],
            data: [],
        },
        air: {
            key: [
                {
                    id: 'f1',
                    color: '#76C6CC',
                },
            ],
            data: [],
        },
        courier: {
            key: [
                {
                    id: 'f1',
                    color: '#C98167',
                },
            ],
            data: [],
        },
    },
    itemTotalBarChart: {
        sea: {
            fcl: 0,
            lcl: 0,
        },
        road: {
            fcl: 0,
            lcl: 0,
            ftl: 0,
        },
        rail: {
            fcl: 0,
            lcl: 0,
        },
        air: {
            lse: 0,
        },
        cour: {
            fcl: 0,
        },
        donatShipment: 0,
    },
    donatChart: [
        {
            name: 'SEA',
            value: 0,
            fill: '#5280C6',
            textColor: '#FFFFFF',
        },
        {
            name: 'AIR',
            value: 0,
            fill: '#76C6CC',
            textColor: '#FFFFFF',
        },
        {
            name: 'ROAD',
            value: 0,
            fill: '#9D75AD',
            textColor: '#FFFFFF',
        },
        {
            name: 'RAIL',
            value: 0,
            fill: '#E3CD9A',
            textColor: '#FFFFFF',
        },
        {
            name: 'COURIER',
            value: 0,
            fill: '#C98167',
            textColor: '#FFFFFF',
        },
    ],
    volumeMeasure: [],
    weightMeasure: [],
}

const volumeSlice = createSlice({
    name: 'VolumeIFB',
    initialState,
    reducers: {
        setBarChartSea: (
            state,
            action: PayloadAction<IBarChartSeaAndRailData>,
        ) => {
            const { totalFclVolume, totalLclVolume, barchartData } =
                action.payload
            state.barChart.sea.data = barchartData
            state.itemTotalBarChart.sea.fcl = totalFclVolume
            state.itemTotalBarChart.sea.lcl = totalLclVolume
        },
        setBarChartRoad: (state, action: PayloadAction<IBarChartRoadData>) => {
            const {
                totalFclVolume,
                totalFtlVolume,
                totalLclVolume,
                barchartData,
            } = action.payload
            state.barChart.road.data = barchartData
            state.itemTotalBarChart.road.fcl = totalFclVolume
            state.itemTotalBarChart.road.lcl = totalLclVolume
            state.itemTotalBarChart.road.ftl = totalFtlVolume
        },
        setBarChartAir: (state, action: PayloadAction<IBarChartAirData>) => {
            const { totalLseVolume, barchartData } = action.payload
            state.itemTotalBarChart.air.lse = totalLseVolume
            state.barChart.air.data = barchartData
        },
        setBarChartRail: (
            state,
            action: PayloadAction<IBarChartSeaAndRailData>,
        ) => {
            const { totalFclVolume, totalLclVolume, barchartData } =
                action.payload
            state.itemTotalBarChart.rail.fcl = totalFclVolume
            state.itemTotalBarChart.rail.lcl = totalLclVolume
            state.barChart.rail.data = barchartData
        },
        setBarChartCour: (
            state,
            action: PayloadAction<IBarChartCourierData>,
        ) => {
            const { totalCourierVolume, barchartData } = action.payload
            state.itemTotalBarChart.cour.fcl = totalCourierVolume
            state.barChart.courier.data = barchartData
        },
        setDonatChartShipment: (
            state,
            action: PayloadAction<{
                data?: IDonatChartShipmentRespon
                unitMeasurement: string
            }>,
        ) => {
            const { data, unitMeasurement } = action.payload

            let updatedChartData: IPieChartData<IPieChartAdditionalData>[] = []

            if (!data) {
                updatedChartData = state.donatChart.map((chartItem) => {
                    return {
                        ...chartItem,
                        value: 0,
                        additionalValue: {
                            value: numberToCurrency(0),
                            unitMeasurement,
                        },
                    }
                })
            } else {
                updatedChartData = state.donatChart.map((chartItem) => {
                    const findDataByTransportMode = data?.perTransport?.find(
                        (item) =>
                            item?.transportMode?.toLowerCase() ===
                            chartItem?.name?.toLowerCase(),
                    )
                    return {
                        ...chartItem,
                        value: findDataByTransportMode?.total || 0,
                        additionalValue: {
                            value: numberToCurrency(
                                findDataByTransportMode?.total || 0,
                            ),
                            unitMeasurement,
                        },
                    }
                })
            }

            state.donatChart = updatedChartData
            state.itemTotalBarChart.donatShipment = data?.total || 0
        },
        setVolumeDropdown(
            state,
            action: PayloadAction<IResponseDropdownVolumeDashboard>,
        ) {
            const convertToDropdownItems = (
                dropdown: IFieldDropdownMetaData[],
            ): IDropdownItem[] => {
                return dropdown.map((data) => ({
                    label: data.item + ' - ' + data.value,
                    value: data.item,
                }))
            }
            const { volumeMeasure: volume, weightMeasure: weight } =
                action.payload
            return {
                ...state,
                volumeMeasure: convertToDropdownItems(volume),
                weightMeasure: convertToDropdownItems(weight),
            }
        },
    },
})

export const {
    setBarChartSea,
    setBarChartRoad,
    setBarChartAir,
    setBarChartRail,
    setBarChartCour,
    setDonatChartShipment,
    setVolumeDropdown,
} = volumeSlice.actions

export const barChartSelector = (state: RootState) => state.volumeIFB.barChart
export const donatChartSelector = (state: RootState) =>
    state.volumeIFB.donatChart
export const volumeDropdownSelector = (state: RootState) =>
    state.volumeIFB.volumeMeasure
export const weightDropdownSelector = (state: RootState) =>
    state.volumeIFB.weightMeasure
export const itemTotalChartSelector = (state: RootState) =>
    state.volumeIFB.itemTotalBarChart

export default volumeSlice.reducer
