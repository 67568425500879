import GoogleMap from '@components/google-map/google-map.component'
import TrackingTableExpandableCustom from 'client/common/components/tracking-table-expandable-custom.component'
import { IUseShipmentDetails } from '../shipment-details.interface'

const Tracking = ({ useService }: { useService: IUseShipmentDetails }) => {
    return (
        <div className="flex sm:flex-wrap w-full h-[calc(100vh-283px)]">
            <div className="w-1/2 sm:w-full sm:h-[50%]">
                <TrackingTableExpandableCustom
                    data={useService.shipmentDetails.tracking.data}
                    meta={useService.shipmentDetails.tracking.meta}
                    setPageData={(page) =>
                        useService.handlingPageNumberTracking(page)
                    }
                    loading={false}
                />
            </div>
            <div className="w-1/2 sm:w-full themes-bg-white rounded-[10px] p-[5px]  sm:mt-[60px] sm:h-[40%] h-[calc(100vh-14rem)] shadow-md ">
                <GoogleMap />
            </div>
        </div>
    )
}

export default Tracking
