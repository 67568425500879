import { IResponseData } from 'common/common.interface'
import { fetch, rangeDate } from 'common/common.service'
import { ifbEndpoints } from '../endpoints/ifb.endpoints'
import { IFilterPerformance } from './interface/performance.interface'
import { IFilterCO } from '../form-validation/control-tower.validation'
import {
    IListApiHistoryByTabItem,
    IOverviewTable2Response,
    IRelatedKeyFiguresResponse,
} from './interface/control-tower.interface'
import { initialResponseData } from 'common/common.static'
import { IButtonContentItems } from '../pages/control-tower/control-tower.interface'

const setUpdatedFilter = <T = {}>(filter: IFilterCO, customField?: T) => {
    const { StartDate, EndDate } = filter

    const loadRangeDate = rangeDate(StartDate, EndDate)

    const convertToParams = <T extends object>(data: T, prefix: string) => {
        const params: Record<string, T[keyof T]> = {}

        ;(Object.keys(data) as Array<keyof T>).forEach((key) => {
            params[`${prefix}.${key as string}`] = data[key]
        })

        return params
    }

    const { DataCategory, ShipmentEvent, ...props } = filter

    const updatedFilter = {
        ...props,
        ...convertToParams(filter.DataCategory, 'DataCategory'),
        ...convertToParams(filter.ShipmentEvent, 'ShipmentEvent'),
        StartDate: loadRangeDate.formattedStartMonth,
        EndDate: loadRangeDate.formattedEndMonth,
        Company: filter.Company === 'All Company' ? '' : filter.Company,
        TransportMode:
            filter.TransportMode === 'All Modes' ? '' : filter.TransportMode,
        ContainerMode:
            filter.ContainerMode === 'All Modes' ? '' : filter.ContainerMode,
        ...(customField && {
            ...customField,
        }),
    }

    return updatedFilter
}

export const fetchRelatedKeyFigures = async (filter: IFilterCO) => {
    const updatedFilter = setUpdatedFilter(filter)
    try {
        const response = await fetch<
            IResponseData<IRelatedKeyFiguresResponse>,
            IFilterPerformance
        >({
            endpoint: ifbEndpoints.control_tower_RelatedKeyFigure,
            params: updatedFilter,
        })
        return response
    } catch (error: any) {
        const err = error as IResponseData<IRelatedKeyFiguresResponse>
        console.error(err)
        err.isSuccess = false
        return err
    }
}
export const fetchOverviewTable2 = async (
    filter: IFilterCO,
    tabFilter: string,
) => {
    const customFieldPayload = {
        countryCode: 'PL', // change country code from user login if already exists
    }
    const updatedFilter = setUpdatedFilter(filter, customFieldPayload)
    try {
        const response = await fetch<
            IResponseData<IOverviewTable2Response[]>,
            IFilterPerformance
        >({
            endpoint: ifbEndpoints.control_tower_Overview + tabFilter,
            params: updatedFilter,
        })
        return response
    } catch (error: any) {
        const errResponse: IResponseData<IOverviewTable2Response[]> =
            initialResponseData
        errResponse.message = error.message
        console.error(error)
        return errResponse
    }
}

export const listApiHistoryByTabItem: IListApiHistoryByTabItem = {
    overview: {
        development: {
            Customer: 'Customer-History-Development',
            Branch: 'Branch-History-Development',
            Agent: 'Agent-History-Development',
        },
        spliRecord: {
            Customer: '',
            Branch: '',
            Agent: '',
        },
    },
    tradeLanes: {
        development: {
            Ports: 'Ports-History-Development',
            Countries: 'Countries-History-Development',
        },
        spliRecord: {
            Ports: '',
        },
    },
    customers: {
        development: {
            Ports: '',
        },
        spliRecord: {
            Ports: '',
        },
    },
    sales: {
        development: {
            Ports: '',
        },
        spliRecord: {
            Ports: '',
        },
    },
    finance: {
        development: {
            Ports: '',
        },
        spliRecord: {
            Ports: '',
        },
    },
}

export const fetchControlTowerHistory = async <T = {}, U = {}>(
    filter: IFilterCO,
    items: {
        field: U
        pageName: IButtonContentItems
        historyType: 'development' | 'splitRecord'
        tabValue: string
    },
) => {
    const additionalEndpoint =
        listApiHistoryByTabItem[
            items.pageName as keyof typeof listApiHistoryByTabItem
        ][
            items.historyType as keyof (typeof listApiHistoryByTabItem)[keyof typeof listApiHistoryByTabItem]
        ][
            items.tabValue as keyof (typeof listApiHistoryByTabItem)[keyof typeof listApiHistoryByTabItem][keyof (typeof listApiHistoryByTabItem)[keyof typeof listApiHistoryByTabItem]]
        ]

    const customFieldPayload = {
        countryCode: 'PL', // change country code from user login if already exists
        ...items.field,
    }

    const updatedFilter = setUpdatedFilter(filter, customFieldPayload)
    // IRHistoryDevelopment
    try {
        const response = await fetch<IResponseData<T>, IFilterPerformance>({
            endpoint: ifbEndpoints.control_tower_Overview + additionalEndpoint,
            params: updatedFilter,
        })
        return response
    } catch (error: any) {
        const errResponse: IResponseData<T> = initialResponseData
        errResponse.message = error.message
        console.error(error)
        return errResponse
    }
}
