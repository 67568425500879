import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    fetchBookingStatus,
    fetchContainerModeStatus,
    fetchNewsData,
    fetchScfiData,
    fetchShipmenteStatus,
    fetchShipmenteStatus2,
    fetchShipmentsData,
    fetchtDropdownDashboard,
} from 'client/ifb/repository/dashboard.repository'
import {
    barContainerSelector,
    barInvoiceSelector,
    dropdownTransModeSelector,
    filterShipmentDataSelector,
    openInvoicesSelector,
    itemInvoiceSelector,
    itemInvoiceUnPaidSelector,
    itemSelector,
    newsSelector,
    setBarInvoice,
    setBookingStatus,
    setContainermodeStatus,
    setDashboardDropdown,
    setNews,
    setPageNumber,
    setSelectedStatus,
    setShipmentStatus,
    setShipmentStatus2,
    setTrackingShipmentLocation,
    statusContainerModeSelector,
    tabStatusFilterSelector,
    trackingShipmentLocationeSelector,
    setOpenInvoices,
} from './dashboard.slice'
import { useFormik } from 'formik'
import {
    IDashboardFilterValidation,
    useDashboardFilterValidation,
} from 'client/ifb/form-validation/dashboard.validation'
import { IInfoCardProps } from './dashboard.interface'
import { metaDataDropdown } from '../bookings/bookings.interface'
import { ITabItem } from '@components/tab/tab.interface'
import moment from 'moment'
import { ILineChartItem } from '@components/rechart-component/rechart-interface'
import { faker } from '@faker-js/faker'
import { Toast } from '@components/toast/toast.component'
import { noDataMilestone } from './dashboard.static'

const useDashboard = () => {
    const dispatch = useDispatch()

    //  selectors
    const dataItem = useSelector(itemSelector)
    const filterShipmentData = useSelector(filterShipmentDataSelector)
    const openInvoices = useSelector(openInvoicesSelector)
    const barInvoiceData = useSelector(barInvoiceSelector)
    const barContainerData = useSelector(barContainerSelector)
    const itemInvoiceData = useSelector(itemInvoiceSelector)
    const itemInvoiceDataUnPaid = useSelector(itemInvoiceUnPaidSelector)
    const statusContainerMode = useSelector(statusContainerModeSelector)
    const news = useSelector(newsSelector)
    const transModeData = useSelector(dropdownTransModeSelector)
    const trackingShipmentLocatione = useSelector(
        trackingShipmentLocationeSelector,
    )
    const tabFilter = useSelector(tabStatusFilterSelector)

    // states
    const [isLoading, setIsLoading] = useState({
        shipmentStatus: true,
        shipmentStatus2: true,
        bookingStatus: true,
        containerModeStatus: true,
        shipmentData: true,
        totalShipmentsByInvoicePaid: true,
        openInvoices: true,
    })
    const [isLoadingNews, setIsLoadingNews] = useState(false)
    const [dataItems, setDataItems] = useState<IInfoCardProps[]>([])
    const { pageNumber, pageSize, status, search } = filterShipmentData
    const [isInitialRender, setIsInitialRender] = useState(true) // state for handle first render

    const { dashboardFilterValidation, dashboardFilterInitialValue } =
        useDashboardFilterValidation()

    const formikDashboard = useFormik<IDashboardFilterValidation>({
        validationSchema: dashboardFilterValidation,
        initialValues: dashboardFilterInitialValue,
        onSubmit: async () => {},
    })

    const fetchData = async () => {
        try {
            const shipmentStatusPromise = fetchShipmenteStatus(
                formikDashboard.values,
            )
                .then((shipmentStatus) => {
                    dispatch(setShipmentStatus(shipmentStatus.data))
                    setIsLoading((prevLoading) => ({
                        ...prevLoading,
                        shipmentStatus: false,
                    }))
                })
                .catch((error) => {
                    console.error('Error fetching Shipment Status data:', error)
                    setIsLoading((prevLoading) => ({
                        ...prevLoading,
                        shipmentStatus: false,
                    }))
                })

            const shipmentStatusPromise2 = fetchShipmenteStatus2(
                formikDashboard.values,
            )
                .then((shipmentStatus2) => {
                    if (shipmentStatus2.isSuccess) {
                        dispatch(setShipmentStatus2(shipmentStatus2.data))
                    }
                    setIsLoading((prevLoading) => ({
                        ...prevLoading,
                        shipmentStatus2: false,
                    }))
                })
                .catch((error) => {
                    console.error('Error fetching Shipment Status data:', error)
                    setIsLoading((prevLoading) => ({
                        ...prevLoading,
                        shipmentStatus: false,
                    }))
                })

            const bookingStatusPromise = fetchBookingStatus(
                formikDashboard.values,
            )
                .then((bookingStatus) => {
                    if (bookingStatus.isSuccess)
                        dispatch(setBookingStatus(bookingStatus.data))
                    setIsLoading((prevLoading) => ({
                        ...prevLoading,
                        bookingStatus: false,
                    }))
                })
                .catch((error) => {
                    console.error('Error fetching Shipment Status data:', error)
                    setIsLoading((prevLoading) => ({
                        ...prevLoading,
                        bookingStatus: false,
                    }))
                })

            const containerModeStatusPromise = fetchContainerModeStatus(
                formikDashboard.values,
            )
                .then((containerModeStatus) => {
                    dispatch(setContainermodeStatus(containerModeStatus.data))
                    setIsLoading((prevLoading) => ({
                        ...prevLoading,
                        containerModeStatus: false,
                    }))
                })
                .catch((error) => {
                    console.error('Error fetching Shipment Status data:', error)
                    setIsLoading((prevLoading) => ({
                        ...prevLoading,
                        containerModeStatus: false,
                    }))
                })

            const totalShipmentsByInvoicePaid =
                loadTotalShipmentsByInvoicePaid()

            const dataShipmentTable = loadDataShipment()

            const dataOpenInvoices = loadOpenInvoices()

            await Promise.allSettled([
                shipmentStatusPromise,
                shipmentStatusPromise2,
                bookingStatusPromise,
                containerModeStatusPromise,
                totalShipmentsByInvoicePaid,
                dataShipmentTable,
                dataOpenInvoices,
            ])
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }

    const fetchDataOnlyOnce = async () => {
        try {
            const [dataNews, dataDropdown] = await Promise.all([
                fetchNewsData(),
                fetchtDropdownDashboard(),
            ])
            dispatch(setNews(dataNews))
            dispatch(setDashboardDropdown(dataDropdown as metaDataDropdown))
        } catch (error) {
            console.error('Error fetching data:', error)
        } finally {
            setIsLoadingNews(false)
        }
    }

    // Get Data Function
    const loadDataShipment = async () => {
        let tmpFilter = filterShipmentData
        if (tabFilter.value) {
            const inProgressStat = tabFilter.value
            tmpFilter = {
                ...filterShipmentData,
                status,
                inProgressStatus: inProgressStat,
            }
        } else {
            tmpFilter = { ...filterShipmentData, status }
        }
        const filterData = {
            ...tmpFilter,
            ...formikDashboard.values,
        }
        try {
            setIsLoading((prevLoading) => ({
                ...prevLoading,
                shipmentData: true,
            }))
            const actionResult = await fetchShipmentsData(filterData)
            if (actionResult.isSuccess)
                dispatch(setTrackingShipmentLocation(actionResult))
        } catch (e) {
            console.error('Error fetching data:', e)
        } finally {
            setIsLoading((prevLoading) => ({
                ...prevLoading,
                shipmentData: false,
            }))
        }
    }

    const setPageData = (pageNumber: number) => {
        dispatch(setPageNumber(pageNumber))
    }

    const setTabFilter = async (data: ITabItem) => {
        dispatch(setSelectedStatus(data))
        dispatch(setPageNumber(1))
    }

    const loadTotalShipmentsByInvoicePaid = () => {
        const start = moment(formikDashboard.values.StartMonth, 'MM/YYYY')
        const end = moment(formikDashboard.values.EndMonth, 'MM/YYYY')
        try {
            const data: ILineChartItem[] = []
            while (start.isSameOrBefore(end, 'month')) {
                data.push({
                    name: start.format('MMM').toUpperCase(),
                    number: faker.number.int({ min: 10, max: 50 }),
                    secName: start.format('YYYY'),
                    fill: '#D9DDE1',
                })

                start.add(1, 'month')
            }
            dispatch(setBarInvoice(data))
        } catch (error) {
            console.error('Error fetching data avarage days delayed:', error)
        } finally {
            setIsLoading((prevLoading) => ({
                ...prevLoading,
                totalShipmentsByInvoicePaid: false,
            }))
        }
    }

    useEffect(() => {
        setIsLoadingNews(true)
        fetchDataOnlyOnce()
        formikDashboard.submitForm()
    }, [])

    useEffect(() => {
        if (!isInitialRender) loadDataShipment()
    }, [pageNumber, pageSize, status, search, filterShipmentData])

    useEffect(() => {
        const handler = setTimeout(() => {
            if (Object.keys(formikDashboard.errors).length !== 0) {
                Toast({
                    header: 'Improper Filter Selection!',
                    message: formikDashboard.errors.EndMonth || '',
                    type: 'error',
                })
                return
            }
            setIsInitialRender(false)
            setIsLoading({
                shipmentStatus: true,
                shipmentStatus2: true,
                bookingStatus: true,
                containerModeStatus: true,
                shipmentData: false,
                totalShipmentsByInvoicePaid: true,
                openInvoices: true,
            })
            fetchData()
        }, 1000)
        return () => clearTimeout(handler)
    }, [formikDashboard.values, formikDashboard.errors])

    useEffect(() => {
        if (dataItem) {
            const formatingData = [
                {
                    icon: 'ri-price-tag-2-line',
                    total: dataItem.quote.total,
                    title: 'Quotes',
                    items: [
                        { label: 'Approved', value: dataItem.quote.approved },
                        { label: 'Pending', value: dataItem.quote.pending },
                        { label: 'Delayed', value: dataItem.quote.delayed },
                        {
                            label: 'New Quotes',
                            value: dataItem.quote.newQuotes,
                        },
                    ],
                },
                {
                    icon: 'ri-book-marked-line',
                    total: dataItem.bookings.TotalBooking,
                    title: 'Bookings',
                    items: [
                        { label: 'Booked', value: dataItem.bookings.Booked },
                        {
                            label: 'Confirmed',
                            value: dataItem.bookings.Confirmed,
                        },
                        {
                            label: 'Cancelled',
                            value: dataItem.bookings.Cancelled,
                        },
                        {
                            label: 'New Bookings',
                            value: dataItem.bookings.NewBookings,
                        },
                    ],
                },
                {
                    icon: 'ri-ship-line',
                    total: dataItem.shipment2.totalStatus ?? '0',
                    title: 'Shipments',
                    items:
                        Object.keys(dataItem.shipment2).length > 0
                            ? Object.entries(dataItem.shipment2)
                                  .filter(([key]) => key !== 'totalStatus')
                                  .map(([label, value]) => ({
                                      label:
                                          label.charAt(0).toUpperCase() +
                                          label.slice(1),
                                      value,
                                  }))
                                  .sort((a, b) => b.value - a.value)
                            : noDataMilestone,
                },
                {
                    icon: 'ri-box-3-line',
                    total: dataItem.warehouse.total,
                    title: 'Warehouse',
                    items: [
                        {
                            label: 'In stock',
                            value: dataItem.warehouse.inStock,
                        },
                        {
                            label: 'Low stock',
                            value: dataItem.warehouse.lowStock,
                        },
                        {
                            label: 'Out of Stock',
                            value: dataItem.warehouse.outOfStock,
                        },
                        {
                            label: 'New Orders',
                            value: dataItem.warehouse.newOrders,
                        },
                    ],
                },
                {
                    icon: 'ri-leaf-line',
                    total: dataItem.co2Emissions.total,
                    title: 'CO2 Emissions',
                    items: [
                        { label: 'SEA', value: dataItem.co2Emissions.sea },
                        { label: 'AIR', value: dataItem.co2Emissions.air },
                        { label: 'ROAD', value: dataItem.co2Emissions.road },
                        { label: 'RAIL', value: dataItem.co2Emissions.rail },
                    ],
                },
            ]
            setDataItems(formatingData)
        }
    }, [dataItem])

    const loadOpenInvoices = () => {
        const data = Array.from({ length: 6 }).map((_, index) => ({
            currency: 'PLN',
            value: faker.number.int({ min: 20001, max: 49000 }),
        }))
        dispatch(setOpenInvoices(data))
        setIsLoading((prevLoading) => ({
            ...prevLoading,
            openInvoices: false,
        }))
    }

    return {
        news,
        isLoadingNews,
        transModeData,
        barContainerData,
        barInvoiceData,
        openInvoices,
        fetchNewsData,
        fetchScfiData,
        isLoading,
        formikDashboard,
        itemInvoiceData,
        itemInvoiceDataUnPaid,
        dataItems,
        statusContainerMode,
        trackingShipmentLocatione,
        setPageData,
        tabFilter,
        setTabFilter,
    }
}

export default useDashboard
