var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-unused-vars */
import FormInput from '@components/form-input/form-input.component';
import Tooltip from '@components/tooltip/tooltip.component';
import { FormikProvider, useFormik } from 'formik';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DraggabbleList from './draggable-list.component';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import SpinnerTable from '@components/spinner-table/spinner-table.component';
import EmptyResult from '@components/empty-result/empty-result.component';
var List = function (_a) {
    var header = _a.header, items = _a.items, onSearchSubmit = _a.onSearchSubmit, moveItem = _a.moveItem, moveItemUp = _a.moveItemUp, moveItemDown = _a.moveItemDown, _b = _a.isDraggable, isDraggable = _b === void 0 ? false : _b, _c = _a.useBorder, useBorder = _c === void 0 ? true : _c, _d = _a.isLoading, isLoading = _d === void 0 ? false : _d, _e = _a.containerClass, containerClass = _e === void 0 ? '' : _e, _f = _a.contentClass, contentClass = _f === void 0 ? '' : _f, _g = _a.contentHeaderClass, contentHeaderClass = _g === void 0 ? '' : _g, _h = _a.contentBodyClass, contentBodyClass = _h === void 0 ? '' : _h;
    var t = useTranslation().t;
    var _j = useState(0), active = _j[0], setActive = _j[1];
    var _k = useState(null), hoveredRowIndex = _k[0], setHoveredRowIndex = _k[1];
    var formik = useFormik({
        initialValues: { searchTerm: '' },
        onSubmit: function (values) {
            onSearchSubmit && onSearchSubmit(values.searchTerm);
        },
        validate: function (values) {
            var errors = {};
            if (values.searchTerm.length < 3 &&
                values.searchTerm.length !== 0) {
                errors.searchTerm = 'Search term must be at least 3 characters';
            }
            return errors;
        },
    });
    var heightContainer = onSearchSubmit
        ? 'h-[calc(100vh-20rem)]  mobile:h-auto'
        : 'h-full';
    var borderClass = useBorder ? 'border rounded' : '';
    var loadingMessage = "Loading Data . . .";
    var notFoundMessage = "No data found";
    return (_jsx(_Fragment, { children: isLoading ? (_jsx(SpinnerTable, { className: "w-full", message: loadingMessage })) : (_jsxs("div", __assign({ className: "".concat(containerClass, " h-full") }, { children: [onSearchSubmit && (_jsx(FormikProvider, __assign({ value: formik }, { children: _jsx("form", __assign({ className: "w-full flex justify-end mb-3", onSubmit: function (e) {
                            e.preventDefault();
                            formik.handleSubmit();
                        } }, { children: _jsx(Tooltip, __assign({ text: 'Search term must be at least 3 characters', isShow: formik.errors.searchTerm ? true : false }, { children: _jsx(FormInput, { autoComplete: "auto-off", hideError: true, placeholder: t('action.search', {
                                    value: t('additional.items'),
                                }), parentDivClassName: "w-full  mobile:min-w-0 mobile:w-full !mb-0 ", icon: "ri-search-line", name: "searchTerm", onKeyDown: function (event) {
                                    if (event.key === 'Backspace' &&
                                        formik.values.searchTerm
                                            .length === 1) {
                                        formik.values.searchTerm = '';
                                        formik.handleSubmit();
                                    }
                                } }) })) })) }))), _jsxs("div", __assign({ className: "flex flex-col ".concat(borderClass, " ").concat(heightContainer, " ").concat(contentClass) }, { children: [header && (_jsx("div", __assign({ className: "border-b p-3 font-bold themes-text-gray-v5 ".concat(contentHeaderClass) }, { children: header }))), !items.length ? (_jsx(EmptyResult, { className: "w-full", message: notFoundMessage })) : isDraggable ? (_jsx(DndProvider, __assign({ backend: HTML5Backend }, { children: _jsx("div", __assign({ className: "h-full overflow-auto" }, { children: items === null || items === void 0 ? void 0 : items.map(function (item, index) {
                                    return (_jsx("div", { children: _jsx(DraggabbleList, { item: item, index: index, moveItem: moveItem, moveItemUp: moveItemUp, moveItemDown: moveItemDown, itemLength: items.length }) }, index));
                                }) })) }))) : (_jsx("div", __assign({ className: "overflow-auto ".concat(contentBodyClass) }, { children: items.map(function (item, index) {
                                var cursorPointer = item.rowOnClick
                                    ? 'cursor-pointer'
                                    : '';
                                var activeClass = item.rowOnClick && active === index
                                    ? 'themes-bg-brand-v2'
                                    : "";
                                var hoverClass = item.rowOnClick &&
                                    hoveredRowIndex === index
                                    ? 'themes-bg-gray-v3'
                                    : '';
                                return (_jsx("div", __assign({ className: "p-3 border-b ".concat(cursorPointer, " ").concat(activeClass, " ").concat(hoverClass, " "), onClick: function () {
                                        if (item.rowOnClick) {
                                            item.rowOnClick();
                                            setActive(index);
                                        }
                                    }, onMouseEnter: function () {
                                        return setHoveredRowIndex(index);
                                    }, onMouseLeave: function () {
                                        return setHoveredRowIndex(null);
                                    } }, { children: item.content }), index));
                            }) })))] }))] }))) }));
};
export default List;
