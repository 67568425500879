/* eslint-disable no-unused-vars */
import { ITabItem } from '@components/tab/tab.interface'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { tabDetailHeaders } from './shipment-details.static'

import { useAppDispatch } from 'store'
import { useSelector } from 'react-redux'
import {
    setPageNumberPackLineSlice,
    setPageNumberTrackingSlice,
    setShipmentDetailsData,
    setShipmentDetailsPackLine,
    setShipmentDetailsTracking,
    shipmentDetailsSelector,
} from '../shipments.slice'
import { Toast } from '@components/toast/toast.component'
import { getShipmentDetails } from 'client/ifb/repository/shipment.repository'
import {
    IShipmentDetailsRawData,
    IUseShipmentDetails,
} from './shipment-details.interface'

const useShipmentDetails = (): IUseShipmentDetails => {
    const { id } = useParams()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    // selectors
    const shipmentDetails = useSelector(shipmentDetailsSelector)

    // states
    const [tabFilter, setTabFilter] = useState<ITabItem>(tabDetailHeaders[0])
    const [rawData, setRawData] = useState<IShipmentDetailsRawData>({
        tracking: [],
        packLine: [],
    })

    // function detail
    const loadDetailData = async () => {
        if (!id || id === '') {
            return
        }

        try {
            const actionResult = await getShipmentDetails(id)
            if (actionResult.isSuccess) {
                dispatch(
                    setShipmentDetailsTracking(
                        actionResult?.data?.shipmentTracking,
                    ),
                )
                dispatch(
                    setShipmentDetailsPackLine(actionResult?.data?.packLines),
                )
                setRawData({
                    tracking: actionResult?.data?.shipmentTracking,
                    packLine: actionResult?.data?.packLines,
                })
                dispatch(setShipmentDetailsData(actionResult))
            }
        } catch (e: any) {
            const errorMessage = e.message
            console.error(e)
            Toast({
                header: 'Failed Get Detail Shipments',
                message: errorMessage,
                type: 'error',
            })
            setTimeout(() => {
                navigate('/shipments')
            }, 100)
        }
    }

    // segment function packline
    const loadShipmentDetailsPackLines = () => {
        dispatch(setShipmentDetailsPackLine(rawData.packLine))
    }
    const handlingPageNumberPackLine = (pageNumber: number) => {
        if (pageNumber <= 0) return
        dispatch(setPageNumberPackLineSlice(pageNumber))
    }

    // segment function tracking
    const loadShipmentDetailsTracking = () => {
        dispatch(setShipmentDetailsTracking(rawData.tracking))
    }
    const handlingPageNumberTracking = (pageNumber: number) => {
        if (pageNumber <= 0) return
        dispatch(setPageNumberTrackingSlice(pageNumber))
    }

    // Use Effect ----------------------------------------------
    useEffect(() => {
        if (!id) return
        loadDetailData()
    }, [id])

    useEffect(() => {
        loadShipmentDetailsPackLines()
    }, [shipmentDetails.packLine.pageNumber])

    // useEffect tracking
    useEffect(() => {
        loadShipmentDetailsTracking()
    }, [shipmentDetails.tracking.pageNumber])

    return {
        // global
        navigate,
        setTabFilter,
        tabFilter,
        id,

        // segment details
        shipmentDetails,

        // segment packline
        handlingPageNumberPackLine,

        // segment tracking
        handlingPageNumberTracking,
    }
}

export default useShipmentDetails
