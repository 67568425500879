var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Fragment } from 'react';
import { useTableExpandable } from './table-expandable-custom.services';
import './table-expandable-custom.style.css';
import Pagination from '@components/pagination/pagination.component';
import SpinnerTable from '@components/spinner-table/spinner-table.component';
import EmptyResult from '@components/empty-result/empty-result.component';
function TableExpandableCustom(_a) {
    var _b = _a.trBodyClass, trBodyClass = _b === void 0 ? '' : _b, props = __rest(_a, ["trBodyClass"]);
    var _c = useTableExpandable(props), expandedRow = _c.expandedRow, toggleExpandRow = _c.toggleExpandRow, contentRef = _c.contentRef, hoveredRowIndex = _c.hoveredRowIndex, setHoveredRowIndex = _c.setHoveredRowIndex;
    var loadingMessage = "Loading Data . . .";
    var notFoundMessage = "No data found";
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "table-expandable-custom" }, { children: props.loading === true ? (_jsx("div", __assign({ className: "h-full" }, { children: _jsx(SpinnerTable, { className: "w-full", message: loadingMessage }) }))) : props.data.length <= 0 ? (_jsx("div", __assign({ className: "h-full" }, { children: _jsx(EmptyResult, { className: "w-full", message: notFoundMessage }) }))) : (_jsx("div", __assign({ className: "flex-1 ".concat(!props.data.length && 'flex') }, { children: _jsxs("table", __assign({ className: "w-full table-auto" }, { children: [(props === null || props === void 0 ? void 0 : props.useHeader) && (_jsx("thead", __assign({ className: "thead-expandable-custom" }, { children: _jsxs("tr", { children: [props.headers.map(function (data, idx) {
                                            var showLabel = data.showLabel === undefined
                                                ? true
                                                : data.showLabel;
                                            return (_jsx("th", __assign({ className: "th-expandable-custom themes-bg-gray-v2" }, { children: _jsx("div", __assign({ className: "" }, { children: !showLabel
                                                        ? null
                                                        : data.label }), data === null || data === void 0 ? void 0 : data.accessor) }), 'header-' + idx));
                                        }), _jsx("th", __assign({ className: "th-expandable-custom h-full !p-0 sticky right-0 z-10 ml-2 themes-bg-gray-v2" }, { children: _jsx("div", { className: "border-l p-2 ml-2" }) }))] }) }))), _jsx("tbody", { children: props.data.map(function (row, idx) {
                                    var iconExpand = expandedRow === idx
                                        ? 'ri-arrow-up-s-line '
                                        : 'ri-arrow-down-s-line ';
                                    var rowBgColor = expandedRow !== idx ||
                                        props.highlightOnExpand === false
                                        ? ''
                                        : 'themes-bg-brand-v2';
                                    var hoverClass = hoveredRowIndex === idx
                                        ? 'themes-bg-gray-v2'
                                        : '';
                                    return (_jsxs(Fragment, { children: [_jsxs("tr", __assign({ className: "tr-expandable-custom ".concat(rowBgColor, " ").concat(trBodyClass, " ").concat(hoverClass), onClick: function () {
                                                    toggleExpandRow(idx);
                                                    props.onRowClick &&
                                                        props.onRowClick(row);
                                                }, onMouseEnter: function () {
                                                    return setHoveredRowIndex(idx);
                                                }, onMouseLeave: function () {
                                                    return setHoveredRowIndex(null);
                                                } }, { children: [props.headers.map(function (col, idx2) {
                                                        var accessor = col.accessor;
                                                        var value = row[accessor];
                                                        var val = !value
                                                            ? '-'
                                                            : value;
                                                        return (_jsx("td", __assign({ className: "td-expandable-custom" }, { children: _jsx("div", __assign({ className: "".concat((col === null || col === void 0 ? void 0 : col.classNames) || '', " ") }, { children: !col.customBuild
                                                                    ? val
                                                                    : col.customBuild({
                                                                        data: val,
                                                                        rowData: row,
                                                                    }) })) }), 'col-' +
                                                            idx2));
                                                    }), _jsx("td", __assign({ className: "th-expandable-custom h-full !p-0 sticky right-0 z-10 ml-2  ".concat(rowBgColor, " ").concat(hoverClass), onClick: function () {
                                                            toggleExpandRow(idx);
                                                        }, onMouseEnter: function () {
                                                            return setHoveredRowIndex(idx);
                                                        }, onMouseLeave: function () {
                                                            return setHoveredRowIndex(null);
                                                        } }, { children: _jsx("div", __assign({ className: "border-l themes-border-color p-3 ml-2 flex justify-center" }, { children: _jsx("i", { className: "".concat(iconExpand) }) })) }))] })), expandedRow === idx && (_jsx("tr", __assign({ className: "tr-expand-custom", onMouseEnter: function () {
                                                    return setHoveredRowIndex(idx);
                                                }, onMouseLeave: function () {
                                                    return setHoveredRowIndex(null);
                                                } }, { children: _jsx("td", __assign({ className: "p-3", colSpan: props.headers
                                                        .length + 1 }, { children: _jsx("div", __assign({ className: "extendable-custom", ref: contentRef }, { children: props.compoenntExpand })) })) })))] }, idx));
                                }) })] })) }))) })), props.usePagination && (_jsx("div", __assign({ className: "border-t themes-border-color flex justify-between sm:justify-center" }, { children: _jsx(Pagination, { meta: props.meta, previousHandling: function (page) {
                        (props === null || props === void 0 ? void 0 : props.previousHandling) &&
                            props.previousHandling(page);
                    }, nextHandling: function (page) {
                        (props === null || props === void 0 ? void 0 : props.nextHandling) && props.nextHandling(page);
                    } }) })))] }));
}
export default TableExpandableCustom;
