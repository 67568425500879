var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-unused-vars */
import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import Button from '@components/button/button.component';
var DraggabbleList = function (_a) {
    var item = _a.item, index = _a.index, moveItem = _a.moveItem, moveItemUp = _a.moveItemUp, moveItemDown = _a.moveItemDown, itemLength = _a.itemLength;
    var ItemTypes = {
        ITEM: 'item',
    };
    var ref = useRef(null);
    var _b = useDrag({
        type: ItemTypes.ITEM,
        item: { type: ItemTypes.ITEM, index: index },
        collect: function (monitor) { return ({
            isDragging: monitor.isDragging(),
        }); },
    }), isDragging = _b[0].isDragging, drag = _b[1];
    var _c = useDrop({
        accept: ItemTypes.ITEM,
        hover: function (draggedItem, monitor) {
            if (!ref.current) {
                return;
            }
            var dragIndex = draggedItem.index;
            var hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return;
            }
            var hoverBoundingRect = ref.current.getBoundingClientRect();
            var hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            var clientOffset = monitor.getClientOffset();
            if (clientOffset) {
                var hoverClientY = clientOffset.y - hoverBoundingRect.top;
                if ((dragIndex < hoverIndex && hoverClientY < hoverMiddleY) ||
                    (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)) {
                    return;
                }
                moveItem && moveItem(dragIndex, hoverIndex);
                draggedItem.index = hoverIndex; // Necessary to update the state locally
            }
        },
    }), drop = _c[1];
    drag(drop(ref));
    return (_jsxs("div", __assign({ ref: ref, className: "p-3 border-b cursor-pointer flex justify-between", style: { opacity: isDragging ? 0.5 : 1 } }, { children: [_jsxs("div", __assign({ className: "flex gap-3 items-center" }, { children: [_jsx("i", { className: "ri-draggable" }), item.content] })), _jsxs("div", __assign({ className: "flex gap-2" }, { children: [_jsx(Button, { onClick: function () {
                            moveItemUp && moveItemUp(index);
                        }, icon: "ri-arrow-drop-up-fill text-size-XL", className: "w-btnIconWidth", isDisabled: index === 0 }), _jsx(Button, { onClick: function () {
                            moveItemDown && moveItemDown(index);
                        }, icon: "ri-arrow-drop-down-fill text-size-XL", className: "w-btnIconWidth", isDisabled: index === itemLength - 1 })] }))] }), index));
};
export default DraggabbleList;
