import Pills from '@components/pills/pills.component'
import List, { IListItem } from '@components/list/list.component'
import { IUseManageUserForm } from '../manage-user-form.interface'
import {
    industryPillItems,
    userRolePillItems,
} from '../manage-user-form.static'
import ToggleSwitch from '@components/toggle-switch/toggle-switch.component'
import { debounceVoid } from 'common/common.service'

const Configurations = ({ useService }: { useService: IUseManageUserForm }) => {
    const loadModuleFeatureAccess = (): IListItem[] =>
        useService?.chooseModule?.loadActionList?.map((item) => {
            const content = (
                <ToggleSwitch
                    initialOn={item.isActive}
                    label={item.label}
                    onClick={() => {
                        useService.handleToggle(item?.value)
                    }}
                />
            )
            return { content }
        }) ?? []

    const loadModulePage = (): IListItem[] =>
        useService.moduleTemplates.map((item) => {
            const content = (
                <div className="flex justify-between">
                    <div>{item.moduleName}</div>
                </div>
            )
            const rowOnClick = debounceVoid(() => {
                // add function here
                useService.onClickModule(item)
            }, 300)

            return {
                content,
                rowOnClick,
            }
        })

    return (
        <div className="h-auto flex flex-col lg:h-full lg:flex-row">
            <div className="p-3 flex flex-col overflow-auto gap-3 w-[100%] lg:w-[25%]">
                <div>
                    <div className="themes-text-gray-9 text-size-L">
                        User Role
                    </div>
                    <div>What type of role is this user made user for?</div>
                    <Pills
                        items={userRolePillItems}
                        onChange={(data) => {
                            useService.setFilterLoadModule({
                                portalAcess: data.value,
                            })
                        }}
                        // property class
                        containerClass="flex-col gap-2 !w-full"
                        itemClass="flex items-center h-[40px]"
                        isRounded={false}
                        variant="brand-v2"
                    />
                </div>
                <div>
                    <div className="themes-text-gray-9 text-size-L">
                        Industry
                    </div>
                    <div>
                        What type of industry is this user responsible for?
                    </div>
                    <Pills
                        items={industryPillItems}
                        onChange={(data) => {
                            useService.setFilterLoadModule({
                                industry: data.value,
                            })
                        }}
                        // property class
                        isRounded={false}
                        containerClass="flex-col gap-2 !w-full"
                        itemClass="flex items-center h-[40px]"
                        variant="brand-v2"
                    />
                </div>
            </div>
            <div className="border-x flex flex-col w-[100%] h-full lg:w-[25%]">
                <List
                    isDraggable={false}
                    items={loadModulePage()}
                    useBorder={false}
                    header="Module Pages"
                    contentHeaderClass={
                        'p-3 border-b themes-text-gray-9 text-size-L font-normal'
                    }
                    contentBodyClass={'h-[calc(100vh-17.4rem)]'}
                    isLoading={useService.loadingModule.modulePages}
                />
            </div>
            <div className="h-full w-[100%] lg:w-[50%]">
                <List
                    isDraggable={false}
                    items={loadModuleFeatureAccess()}
                    useBorder={false}
                    // isLoading={useService.loadingModuleFeatureAccess}
                    isLoading={useService.loadingModule.moduleFeaturesAccess}
                    header="Module Feature Access"
                    contentHeaderClass={
                        'p-3 border-b themes-text-gray-9 text-size-L font-normal'
                    }
                    contentBodyClass={'h-[calc(100vh-17.4rem)]'}
                />
            </div>
        </div>
    )
}

export default Configurations
