import { IMeasurement } from '@components/measurement-input/measurement-input.interface'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

export interface ICreateBookingValidation {
    // new
    shipperAddress: IShipperAddress[]
    consigneeAddress: IShipperAddress[]

    // segment origin
    originPortCode: string
    originPortName: string
    pickupEquipmentNeededCode: string
    pickupEquipmentNeededDescription: string
    estimatedPickup: string
    pickupRequiredBy: string

    // segment destination
    destinationPortCode: string
    destinationPortName: string
    deliveryEquipmentNeededCode: string
    deliveryEquipmentNeededDescription: string
    estimatedDelivery: string
    deliveryRequiredBy: string

    // segment booking information
    transportModeCode: string
    transportModeDesc: string
    containerMode: string
    loglinesBookingCreateBookingDetails: {
        shippersRef: string
        serviceLevelCode: string
        serviceLevelDescription: string
        incoTermCode: string
        incoTermDescription: string
        additionalTerms: string
        goodsValue: number
        goodsValueCurrencyCode: string
        goodsValueCurrencyDescription: string
        insuranceValue: number
        insuranceValueCurrencyCode: string
        insuranceValueCurrencyDescription: string
        packs?: string
        outerPacksUnitCode?: string
        outerPacksUnitDescription?: string
        volumeMeasure?: number
        volumeMeasureUnitCode?: string
        volumeMeasureUnitDescription?: string
        weightMeasure?: number
        weightMeasureUnitCode?: string
        weightMeasureUnitDescription?: string
        goodsDescription: string
        marksAndNumbers: string
    }
    chargesApplyCode: string
    chargesApplyDescription: string

    // segment packlines
    // loglinesBookingCreatePacklines: ICreateBookingPackLinesValidationForm[]
    loglinesBookingCreatePacklines: Omit<
        ICreateBookingPackLinesValidation,
        'specialField'
    >[]

    // segment containers
    loglinesContainerCollection: Omit<
        ICreateBookingContainersValidation,
        'id'
    >[]

    // special measurement input field
    specialField: { volumeMeasure: IMeasurement; weightMeasure: IMeasurement }
}

export interface IShipperAddress {
    idCompany: string
    company: string
    address1: string
    address2: string
    addressType:
        | 'ConsignorPickupDeliveryAddress'
        | 'ConsignorDocumentaryAddress'
        | 'ConsigneePickupDeliveryAddress'
        | 'ConsigneeDocumentaryAddress'
    city: string
    state: {
        description: string
        value: string
    }
    postCode: string
    countryCode: string
    countryName: string
    contactName: string
    contactPhoneNumber: string
    contactMobileNumber: string
    contactEmailAddress: string
}

export interface ICreateBookingPackLinesValidation {
    packs: string
    packageType: string
    packageTypeCode: string
    packageTypeDescription: string
    lengthMeasure: number
    lengthMeasureUnitCode: string
    lengthMeasureUnitDescription: string
    widthMeasure: number
    widthMeasureUnitCode: string
    widthMeasureUnitDescription: string
    heightMeasure: number
    heightMeasureUnitCode: string
    heightMeasureUnitDescription: string
    weightMeasure: number
    weightMeasureUnitCode: string
    weightMeasureUnitDescription: string
    volumeMeasure: number
    volumeMeasureUnitCode: string
    volumeMeasureUnitDescription: string
    goodsDescription: string

    // parsing empty field
    flagsCustomFlag1: string
    flagsCustomFlag2: string
    flagsDepartureTransitWarehouseExcluded: boolean
    requiresTemperatureControl: boolean
    datesCustomDate1: string
    datesCustomDate2: string
    datesLastKnownTransitWarehouseStatusDateTime: string
    datesSystemCreateTimeUTC: string
    datesSystemLastEditTimeUTC: string
    measurementOutturnHeightMeasure: number
    measurementOutturnLengthMeasure: number
    measurementOutturnVolumeMeasure: number
    measurementOutturnWeightMeasure: number
    measurementOutturnWidthMeasure: number
    numericCustomDecimal1: number
    numericCustomDecimal2: number
    numericDamagedPackages: number
    numericEndPackNumber: number
    numericItemNo: number
    numericLinePrice: number
    numericLoadMet: number
    numericOutturnedPackages: number
    numericPackingOrder: number
    numericPillagedPackages: number
    numericRequiredTemperatureMaximum: number
    numericRequiredTemperatureMinimum: number
    numericVehicleNumberOfDoors: number
    numericVehicleYear: number
    textCommodityCode: string
    textCreatedByStaff: string
    textLastEditedByStaff: string
    textLastKnownTransitWarehouseAddress: string
    textOrigin: string
    textCustomAttribute1: string
    textCustomAttribute2: string
    textCustomAttribute3: string
    textCustomAttribute4: string
    textExportReferenceNumber: string
    textHarmonizedCode: string
    textImportReferenceNumber: string
    textLastKnownTransitWarehouseStatus: string
    textOriginTransitWarehouseStatus: string
    textOutturnComment: string
    textPackLineId: string
    textProductCode: string
    textReferenceNumber: string
    textRequiredTemperatureUnitCode: string
    textRequiredTemperatureUnitDescription: string
    textVehicleColor: string
    textVehicleMake: string
    textVehicleModel: string
    textVehicleTransmission: string

    // missing field and need back end to add in payload
    marksAndNumbers: string
    priceCurrency: string
    tariffNumber: string

    // special Field (not include in payload)
    specialField: {
        id: number
        unitDimension: string
        length: string
        lengthMeasure: IMeasurement
        widthMeasure: IMeasurement
        heightMeasure: IMeasurement
        weightMeasure: IMeasurement
        volumeMeasure: IMeasurement
    }
}

export interface ICreateBookingContainersValidation {
    id: number
    containerCount: string
    containerType: {
        code: string
        category: {
            code: string
            description: string
        }
        description: string
        isoCode: string
    }
    containerNumber: string
}

export const useCreateBookingValidation = () => {
    const { t } = useTranslation()

    const today = new Date()
    const createBookingValidation = Yup.object().shape({
        shipperAddress: Yup.array()
            .of(
                Yup.object().shape({
                    company: Yup.string().nullable(),
                }),
            )
            .test(
                'validate-first-item-only',
                'Company is required for the first item',
                function (value) {
                    if (Array.isArray(value) && value.length > 0) {
                        if (!value[0].company) {
                            return this.createError({
                                path: `shipperAddress[0].company`,
                                message: 'Company is required',
                            })
                        }
                    }
                    return true
                },
            ),
        consigneeAddress: Yup.array()
            .of(
                Yup.object().shape({
                    company: Yup.string().nullable(),
                }),
            )
            .test(
                'validate-first-item-only',
                'Company is required for the first item',
                function (value) {
                    if (Array.isArray(value) && value.length > 0) {
                        if (!value[0].company) {
                            return this.createError({
                                path: `consigneeAddress[0].company`,
                                message: 'Company is required',
                            })
                        }
                    }
                    return true
                },
            ),
        pickupRequiredBy: Yup.date()
            .nullable()
            .transform((value, originalValue) =>
                originalValue === '' ? null : value,
            )
            .min(today, 'Pickup date cannot be in the past'),
        deliveryRequiredBy: Yup.date()
            .nullable()
            .transform((value, originalValue) =>
                originalValue === '' ? null : value,
            )
            .min(today, 'Delivery date cannot be in the past'),
        transportModeCode: Yup.string().required('Transport mode is required'),
        containerMode: Yup.string().required('Container mode is required'),
        originPortCode: Yup.string().required('Origin port is required'),
        destinationPortCode: Yup.string().required(
            'Destination port is required',
        ),
    })

    const createBookingPackLinesValidation = Yup.object().shape({
        packs: Yup.number()
            .required(t('message.required', { value: 'Pieces' }))
            .min(1),
    })

    const createContainersBookingsValidation = Yup.object().shape({
        containerCount: Yup.number()
            .required(
                t('message.required', {
                    value: 'Container count',
                }),
            )
            .min(1),
        containerType: Yup.object().shape({
            code: Yup.string().required(
                t('message.required', { value: 'Container type' }),
            ),
        }),
    })

    const createBookingInitialValue: ICreateBookingValidation = {
        shipperAddress: [
            {
                idCompany: '',
                company: '',
                address1: '',
                address2: '',
                addressType: 'ConsignorPickupDeliveryAddress',
                city: '',
                state: {
                    description: '',
                    value: '',
                },
                postCode: '',
                countryCode: '',
                countryName: '',
                contactName: '',
                contactPhoneNumber: '',
                contactMobileNumber: '',
                contactEmailAddress: '',
            },
            {
                idCompany: '',
                company: '',
                address1: '',
                address2: '',
                addressType: 'ConsignorDocumentaryAddress',
                city: '',
                state: {
                    description: '',
                    value: '',
                },
                postCode: '',
                countryCode: '',
                countryName: '',
                contactName: '',
                contactPhoneNumber: '',
                contactMobileNumber: '',
                contactEmailAddress: '',
            },
        ],
        consigneeAddress: [
            {
                idCompany: '',
                company: '',
                address1: '',
                address2: '',
                addressType: 'ConsigneePickupDeliveryAddress',
                city: '',
                state: {
                    description: '',
                    value: '',
                },
                postCode: '',
                countryCode: '',
                countryName: '',
                contactName: '',
                contactPhoneNumber: '',
                contactMobileNumber: '',
                contactEmailAddress: '',
            },
            {
                idCompany: '',
                company: '',
                address1: '',
                address2: '',
                addressType: 'ConsigneeDocumentaryAddress',
                city: '',
                state: {
                    description: '',
                    value: '',
                },
                postCode: '',
                countryCode: '',
                countryName: '',
                contactName: '',
                contactPhoneNumber: '',
                contactMobileNumber: '',
                contactEmailAddress: '',
            },
        ],
        originPortCode: '',
        originPortName: '',
        pickupEquipmentNeededCode: '',
        pickupEquipmentNeededDescription: '',
        estimatedPickup: '',
        pickupRequiredBy: '',
        destinationPortCode: '',
        destinationPortName: '',
        deliveryEquipmentNeededCode: '',
        deliveryEquipmentNeededDescription: '',
        estimatedDelivery: '',
        deliveryRequiredBy: '',
        transportModeCode: '',
        transportModeDesc: '',
        containerMode: '',
        loglinesBookingCreateBookingDetails: {
            shippersRef: '',
            serviceLevelCode: '',
            serviceLevelDescription: '',
            incoTermCode: '',
            incoTermDescription: '',
            additionalTerms: '',
            goodsValue: 0,
            goodsValueCurrencyCode: '',
            goodsValueCurrencyDescription: '',
            insuranceValue: 0,
            insuranceValueCurrencyCode: '',
            insuranceValueCurrencyDescription: '',
            packs: '',
            outerPacksUnitCode: '',
            outerPacksUnitDescription: '',
            volumeMeasure: 0,
            volumeMeasureUnitCode: '',
            volumeMeasureUnitDescription: '',
            weightMeasure: 0,
            weightMeasureUnitCode: '',
            weightMeasureUnitDescription: '',
            goodsDescription: '',
            marksAndNumbers: '',
        },
        chargesApplyCode: '',
        chargesApplyDescription: '',
        loglinesBookingCreatePacklines: [],
        loglinesContainerCollection: [],
        specialField: {
            volumeMeasure: {
                qty: '',
                unit: 'M3',
            },
            weightMeasure: {
                qty: '',
                unit: 'KG',
            },
        },
    }

    const createBookingPackLinesInitialValue: ICreateBookingPackLinesValidation =
        {
            packs: '',
            packageTypeCode: '',
            packageType: '',
            packageTypeDescription: '',
            lengthMeasure: 0,
            lengthMeasureUnitCode: '',
            lengthMeasureUnitDescription: '',
            widthMeasure: 0,
            widthMeasureUnitCode: '',
            widthMeasureUnitDescription: '',
            heightMeasure: 0,
            heightMeasureUnitCode: '',
            heightMeasureUnitDescription: '',
            weightMeasure: 0,
            weightMeasureUnitCode: '',
            weightMeasureUnitDescription: '',
            volumeMeasure: 0,
            volumeMeasureUnitCode: '',
            volumeMeasureUnitDescription: '',
            goodsDescription: '',
            flagsCustomFlag1: '',
            flagsCustomFlag2: '',
            flagsDepartureTransitWarehouseExcluded: false,
            requiresTemperatureControl: false,
            datesCustomDate1: '',
            datesCustomDate2: '',
            datesLastKnownTransitWarehouseStatusDateTime: '',
            datesSystemCreateTimeUTC: '',
            datesSystemLastEditTimeUTC: '',
            measurementOutturnHeightMeasure: 0,
            measurementOutturnLengthMeasure: 0,
            measurementOutturnVolumeMeasure: 0,
            measurementOutturnWeightMeasure: 0,
            measurementOutturnWidthMeasure: 0,
            numericCustomDecimal1: 0,
            numericCustomDecimal2: 0,
            numericDamagedPackages: 0,
            numericEndPackNumber: 0,
            numericItemNo: 0,
            numericLinePrice: 0,
            numericLoadMet: 0,
            numericOutturnedPackages: 0,
            numericPackingOrder: 0,
            numericPillagedPackages: 0,
            numericRequiredTemperatureMaximum: 0,
            numericRequiredTemperatureMinimum: 0,
            numericVehicleNumberOfDoors: 0,
            numericVehicleYear: 0,
            textCommodityCode: '',
            textCreatedByStaff: '',
            textLastEditedByStaff: '',
            textLastKnownTransitWarehouseAddress: '',
            textOrigin: '',
            textCustomAttribute1: '',
            textCustomAttribute2: '',
            textCustomAttribute3: '',
            textCustomAttribute4: '',
            textExportReferenceNumber: '',
            textHarmonizedCode: '',
            textImportReferenceNumber: '',
            textLastKnownTransitWarehouseStatus: '',
            textOriginTransitWarehouseStatus: '',
            textOutturnComment: '',
            textPackLineId: '',
            textProductCode: '',
            textReferenceNumber: '',
            textRequiredTemperatureUnitCode: '',
            textRequiredTemperatureUnitDescription: '',
            textVehicleColor: '',
            textVehicleMake: '',
            textVehicleModel: '',
            textVehicleTransmission: '',
            specialField: {
                id: 1,
                lengthMeasure: {
                    unit: 'CM',
                },
                widthMeasure: {
                    unit: 'CM',
                },
                heightMeasure: {
                    unit: 'CM',
                },
                weightMeasure: {
                    unit: 'KG',
                    unitLabel: 'KG',
                },
                volumeMeasure: {
                    unit: 'CM',
                    unitLabel: 'CM',
                },
                unitDimension: 'M',
                length: '',
            },
            marksAndNumbers: '',
            priceCurrency: '',
            tariffNumber: '',
        }

    const createContainerBookingsInitialValue: ICreateBookingContainersValidation =
        {
            id: 1,
            containerCount: '',
            containerType: {
                code: '',
                category: {
                    code: '',
                    description: '',
                },
                description: '',
                isoCode: '',
            },
            containerNumber: '',
        }

    return {
        createBookingValidation,
        createBookingPackLinesValidation,
        createContainersBookingsValidation,
        createBookingInitialValue,
        createBookingPackLinesInitialValue,
        createContainerBookingsInitialValue,
    }
}
