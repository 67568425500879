import TableExpandableCustom from '@components/table-expandable-custom/table-expandable-custom.component'
import { ITECColumn } from '@components/table-expandable-custom/table-expandable-custom.interface'
import TrackerV2, {
    ITrackerItemV2,
} from '@components/tracker-v2/tracker-v2.component'
import { IMeta } from 'common/common.interface'
import { formatDate } from 'common/common.service'
import { useState } from 'react'

export interface ITrackingTable {
    transportModeCode: string
    containerTypeCode: string
    containerMode: {
        code: string
        desc: string
    }
    deliverymode: string
    consolNumber: string
    seal: string
    vesselName: string
    voyageFlightNo: string
    relatedShipmentNumber: string[]
    trackerItems: ITrackerItemV2[]
    containerNumber: string
    portOfLoading: {
        code: string
        desc: string
    }
    portOfDischarge: {
        code: string
        desc: string
    }
    etd: string
    eta: string
}

const TrackingExpand = ({ data }: { data?: ITrackingTable }) => {
    const buidContent = ({
        label,
        value,
    }: {
        label: string
        value: string
    }) => (
        <div className="leading-none">
            <div className="themes-text-gray-v5">{label}</div>
            <div className="themes-text-gray-v9">{value || '='}</div>
        </div>
    )

    return !data ? (
        <></>
    ) : (
        <div className="flex justify-between">
            <div className="flex flex-col gap-3 border-r mr-3 w-full">
                {buidContent({
                    label: 'Container Type',
                    value: data.containerTypeCode,
                })}
                {buidContent({
                    label: 'Container Mode',
                    value: data.containerMode.code
                        ? `${data.containerMode.code} - ${data.containerMode.desc}`
                        : data.containerMode.desc,
                })}
                {buidContent({
                    label: 'Delivery Mode',
                    value: `${data.deliverymode}`,
                })}
                {buidContent({
                    label: 'Consol Number',
                    value: `${data.consolNumber}`,
                })}
                {buidContent({
                    label: 'Seal Number',
                    value: `${data.seal}`,
                })}
                {buidContent({
                    label: 'Vessel',
                    value: `${data.vesselName}`,
                })}
                {buidContent({
                    label: 'Voyage',
                    value: `${data.voyageFlightNo}`,
                })}
                {buidContent({
                    label: 'Related Shipment',
                    value: data.relatedShipmentNumber.join(' / '),
                })}
            </div>
            <div className="w-full">
                <TrackerV2 items={data.trackerItems} />
            </div>
        </div>
    )
}

const tableExpandableCustomheaders: ITECColumn<ITrackingTable>[] = [
    {
        label: '',
        accessor: 'transportModeCode',
        customBuild: ({ rowData }) => {
            const transportModeCode =
                rowData?.transportModeCode?.toLocaleLowerCase()
            let iconTransportMode: string
            switch (transportModeCode) {
                case 'air':
                    iconTransportMode = 'ri-plane-line'
                    break
                case 'sea':
                    iconTransportMode = 'ri-ship-line'
                    break
                case 'roa':
                    iconTransportMode = 'ri-truck-line'
                    break
                default:
                    iconTransportMode = 'ri-plane-line'
            }

            return (
                <i
                    className={`${iconTransportMode} text-size-L themes-text-gray-v6 flex items-center font-bold`}
                ></i>
            )
        },
    },
    {
        label: '',
        accessor: 'containerNumber',
        classNames: 'font-bold',
    },
    {
        label: '',
        accessor: 'portOfLoading',
        customBuild: ({ rowData }) => {
            const loadingImgCode = rowData?.portOfLoading?.code
                .slice(0, 2)
                .toLowerCase()
            const dischargeImgCode = rowData?.portOfDischarge?.code
                .slice(0, 2)
                .toLowerCase()
            const loadingImg = `https://flagcdn.com/${loadingImgCode}.svg`
            const loadingName = rowData?.portOfLoading?.desc
            const dischargeImg = `https://flagcdn.com/${dischargeImgCode}.svg`
            const dischargeName = rowData?.portOfDischarge.desc

            return (
                <div className="h-full flex gap-3 leading-none items-center">
                    <div className="w-[150px]  min-w-[150px] max-w-[150px]">
                        <div className="themes-text-gray-v5">Load</div>
                        <div className="font-bold !text-ellipsis !whitespace-nowrap overflow-hidden">
                            {loadingName}
                            <span className="sm:hidden">
                                ( {rowData?.portOfLoading?.code} )
                            </span>
                        </div>
                        <div>{formatDate(rowData?.etd || '', 'DD MMM YY')}</div>
                    </div>
                    <img
                        src={loadingImg}
                        className="h-5 w-7 sm:hidden"
                        alt={loadingName}
                    />
                    <div
                        className={`
                            
                            w-[150px] min-w-[150px] max-w-[150px] p-2 flex items-center rounded-md text-center themes-bg-gray-v2 themes-text-gray-v6`}
                    >
                        <div className="text-ellipsis whitespace-nowrap overflow-hidden">
                            {rowData?.trackerItems[0]?.statusDesc ||
                                'No Activity'}
                        </div>
                    </div>

                    <img
                        src={dischargeImg}
                        className="h-5 w-7 sm:hidden"
                        alt={dischargeName}
                    />
                    <div className="w-[200px] min-w-[200px] max-w-[200px]">
                        <div className="themes-text-gray-v5">Discharge</div>
                        <div className="font-bold text-ellipsis whitespace-nowrap overflow-hidden">
                            {dischargeName}{' '}
                            <span className="sm:hidden">
                                ( {rowData?.portOfDischarge?.code} )
                            </span>
                        </div>
                        <div>{formatDate(rowData?.eta || '', 'DD MMM YY')}</div>
                    </div>
                </div>
            )
        },
        classNames: 'h-full',
    },
]

const TrackingTableExpandableCustom = ({
    ...props
}: {
    data: ITrackingTable[]
    meta: IMeta
    setPageData: (page: number) => void
    loading: boolean
}) => {
    const [selectData, setSelectData] = useState<ITrackingTable>()
    return (
        <TableExpandableCustom
            headers={tableExpandableCustomheaders}
            data={props.data || []}
            compoenntExpand={<TrackingExpand data={selectData} />}
            onRowClick={(data) => {
                setSelectData(data)
            }}
            // pagination segment
            meta={props.meta}
            usePagination
            nextHandling={(page) => {
                props.setPageData(page)
            }}
            previousHandling={(page) => {
                props.setPageData(page)
            }}
            loading={props.loading}
            // css segment
            trBodyClass="h-[60px]"
        />
    )
}

export default TrackingTableExpandableCustom
