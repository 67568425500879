import { IButton } from '@components/button/button.interface'
import {
    filterCOInitialValue,
    filterCOValidation,
    IFilterCO,
    IFilterDataCategoryCO,
    IFilterShipmentEventCO,
} from 'client/ifb/form-validation/control-tower.validation'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import {
    averageMonthlyPerformanceSelector,
    barChartOverviewSelector,
    customerChartOrganisationDataSelector,
    customerTableDataSelector,
    dataOverviewTable1Selector,
    dataOverviewTable2Selector,
    dataTradeLanesTableSelector,
    financePieChartSelector,
    financeTableDataSelector,
    historyDataSelector,
    joinChartOverviewSelector,
    pieChartTradeLanesSelector,
    setAverageMonthlyPerformance,
    setDataOverviewTable1,
    setDataOverviewTable2,
    setHistorySplitRecord,
    setHistoryDevelopment,
    setPageNumberOverviewTable2Slice,
    setHistoryFilterName,
    historyFilterNameSelector,
} from './control-tower.slice'
import { useEffect, useState } from 'react'
import { ITabItem } from '@components/tab/tab.interface'
import {
    tabItemFilterCustomers,
    tabItemFilterFinance,
    tabItemFilterOverview,
    tabItemFilterTradeLanes,
} from './control-tower.static'
import { faker } from '@faker-js/faker'
import {
    IButtonContentItems,
    ICOFilterByMetrics,
    IControlTowerRawData,
    ILoadingFinance,
    ILoadingHistory,
    ILoadingOverview,
    ILoadingTradeLanes,
    IUseControlTower,
} from './control-tower.interface'
import moment from 'moment'
import {
    fetchControlTowerHistory,
    fetchOverviewTable2,
    fetchRelatedKeyFigures,
} from 'client/ifb/repository/control-tower.repository'
import { Toast } from '@components/toast/toast.component'
import { IPillItem } from '@components/pills/pills.component'
import { IResponseData } from 'common/common.interface'
import {
    IRHistoryDevelopment,
    IRTrandeLanesCountriesHistoryDevelopment,
    IRTrandeLanesPortsHistoryDevelopment,
} from 'client/ifb/repository/interface/control-tower.interface'
import { numberWithCommas } from '@services/common.service'

const useControlTower = (): IUseControlTower => {
    const dispatch = useDispatch()

    // selector common
    const historyData = useSelector(historyDataSelector)
    const historyFilterName = useSelector(historyFilterNameSelector)

    // selector overview
    const dataOverviewTable1 = useSelector(dataOverviewTable1Selector)
    const dataOverviewTable2 = useSelector(dataOverviewTable2Selector)
    const joinChartOverview = useSelector(joinChartOverviewSelector)
    const barChartOverview = useSelector(barChartOverviewSelector)

    // selector trade lanes
    const pieChartTradeLanes = useSelector(pieChartTradeLanesSelector)
    const dataTradeLanesTable = useSelector(dataTradeLanesTableSelector)

    // selector trade lanes
    const customerChartOrganisationData = useSelector(
        customerChartOrganisationDataSelector,
    )
    const customerTableData = useSelector(customerTableDataSelector)

    // selector finance
    const financeTableData = useSelector(financeTableDataSelector)
    const financePieChart = useSelector(financePieChartSelector)
    const averageMonthlyPerformance = useSelector(
        averageMonthlyPerformanceSelector,
    )

    // state common
    const [buttonContent, setButtonContent] =
        useState<IButtonContentItems>('overview')
    const [loadingHistory, setLoadingHistory] = useState<ILoadingHistory>({
        development: true,
        splitRecord: true,
    })
    const [isInitialRender, setIsInitialRender] = useState<boolean>(true)
    const [rawData, setRawData] = useState<IControlTowerRawData>({
        dataOverviewTable2: [],
    })

    // state overview
    const [tabFilterOverview, setTabFilterOverview] = useState<ITabItem>(
        tabItemFilterOverview[0],
    )
    const [loadingOverview, setLoadingOverview] = useState<ILoadingOverview>({
        relatedKeyFigures: true,
        overviewTable2: true,
    })
    // state trade lanes
    const [tabFilterTradeLanes, setTabFilterTradeLanes] = useState<ITabItem>(
        tabItemFilterTradeLanes[0],
    )
    const [loadingTradeLanes, setLoadinTradeLanes] =
        useState<ILoadingTradeLanes>({
            region: true,
            table: true,
        })
    // state customers
    const [tabFilterCustomers, setTabFilterCustomers] = useState<ITabItem>(
        tabItemFilterCustomers[0],
    )
    // state finance
    const [tabFilterFinance, setTabFilterFinance] = useState<ITabItem>(
        tabItemFilterFinance[0],
    )
    const [loadingFinance, setLoadingFinance] = useState<ILoadingFinance>({
        averageMonthlyPerformance: true,
    })

    // formik
    const formikFilterCO = useFormik<IFilterCO>({
        validationSchema: filterCOValidation,
        initialValues: filterCOInitialValue,
        onSubmit: async () => {},
    })

    // function & variable common
    const buttonContentCO: IButton[] = [
        {
            onClick: () => setButtonContent('overview'),
            label: 'OVERVIEW',
            icon: 'ri-pages-line',
            className: 'col-span-6',
        },
        {
            onClick: () => setButtonContent('tradeLanes'),
            label: 'trade lanes',
            icon: 'ri-share-line',
            useUpperCase: true,
            className: 'col-span-6',
        },

        {
            onClick: () => setButtonContent('customers'),
            label: 'customers',
            icon: 'ri-user-heart-line',
            useUpperCase: true,
            className: 'col-span-4',
        },
        {
            onClick: () => setButtonContent('sales'),
            label: 'sales',
            icon: 'ri-money-cny-box-line',
            useUpperCase: true,
            className: 'col-span-4',
        },

        {
            onClick: () => setButtonContent('finance'),
            label: 'finance',
            icon: 'ri-book-2-line',
            useUpperCase: true,
            className: 'col-span-4',
        },
    ]
    const wrapFetchDataContent = async () => {
        if (Object.keys(formikFilterCO.errors).length !== 0) {
            Toast({
                header: 'Improper Filter Selection!',
                message: formikFilterCO.errors.EndDate || '',
                type: 'error',
            })
            return
        }
        setLoadingHistory({
            development: true,
            splitRecord: true,
        })
        switch (buttonContent) {
            case 'overview':
                fechtOverviewData()
                break
            case 'tradeLanes':
                fechtTradeLanesData()
                break
            case 'finance':
                loadAverageMonthlyPerformanceData()
                break
            default:
                fechtOverviewData()
        }
    }
    const handlePillsDataCategory = (
        data: IPillItem<IFilterDataCategoryCO>,
    ) => {
        const dataCategory: IFilterDataCategoryCO =
            formikFilterCO.values.DataCategory

        // Set semua properti ke false
        Object.keys(dataCategory).forEach((key) => {
            dataCategory[key as keyof IFilterDataCategoryCO] = false
        })

        if (Object.prototype.hasOwnProperty.call(dataCategory, data.value)) {
            dataCategory[data.value] = true
        }

        formikFilterCO.setValues({
            ...formikFilterCO.values,
            DataCategory: dataCategory,
        })
    }
    const handlePillsShipmentEvent = (
        data: IPillItem<IFilterShipmentEventCO>,
    ) => {
        const shipmentEvent = formikFilterCO.values.ShipmentEvent

        if (Object.prototype.hasOwnProperty.call(shipmentEvent, data.value)) {
            shipmentEvent[data.value] = data.isActive || false
        }

        formikFilterCO.setValues({
            ...formikFilterCO.values,
            ShipmentEvent: shipmentEvent,
        })
    }
    const wrapFetchDataHistoryDevelopment = async (name: string) => {
        setLoadingHistory({
            development: true,
            splitRecord: true,
        })
        switch (buttonContent) {
            case 'overview':
                loadHistoryDevelopment(name)
                loadHistorySplitRecord()
                break
            case 'tradeLanes':
                loadTradeLanesPortsHistoryDevelopment(name)
                loadHistorySplitRecord()
                break
            default:
                loadHistoryDevelopment(name)
                loadHistorySplitRecord()
        }
    }
    const loadHistoryDevelopment = async (name: string) => {
        const start = moment(formikFilterCO.values.StartDate, 'MM/YYYY')
        const end = moment(formikFilterCO.values.EndDate, 'MM/YYYY')

        const rangeMonth: string[] = []
        const line1 = []
        const line2 = []
        let index = 1
        try {
            let fecthData: IResponseData<IRHistoryDevelopment> | null = null

            if (name)
                fecthData = await fetchControlTowerHistory(
                    formikFilterCO.values,
                    {
                        field: {
                            companyName: name,
                        },
                        historyType: 'development',
                        pageName: buttonContent,
                        tabValue: tabFilterOverview.value,
                    },
                )

            const fieldName = getActiveFieldFilterDataCategory()

            while (start.isSameOrBefore(end, 'month')) {
                let currentValue = undefined
                let previousValue = undefined

                if (fecthData !== null) {
                    const findDataCurrent =
                        fecthData?.data?.current?.items.find(
                            (item) => item.label === start.format('YYYY-MM'),
                        )
                    const findDataPrevious =
                        fecthData?.data?.previous?.items &&
                        fecthData.data.previous.items.find(
                            (item) => item.label === start.format('YYYY-MM'),
                        )
                    currentValue = findDataCurrent
                        ? findDataCurrent[
                              fieldName.accessor as keyof typeof findDataCurrent
                          ]
                        : undefined
                    previousValue = findDataPrevious
                        ? findDataPrevious[
                              fieldName.accessor as keyof typeof findDataPrevious
                          ]
                        : undefined
                }
                rangeMonth.push(start.format('MMM'))
                line1.push({
                    x: index,
                    secName: start.format('YYYY'),
                    y: Number(currentValue) || 0,
                    keyId: 'line1',
                })
                line2.push({
                    x: index,
                    secName: start.format('YYYY'),
                    y: Number(previousValue) || 0,
                    keyId: 'line2',
                })

                start.add(1, 'month')
                index++
            }
            const infoCardItems = [
                {
                    value: '0',
                    label: `Average ${fieldName.label}`,
                },
            ]

            dispatch(
                setHistoryDevelopment({
                    line1,
                    line2,
                    tickItems: rangeMonth,
                    infoCardItems,
                }),
            )
        } catch (error) {
            console.error(error)
        } finally {
            setLoadingHistory((prevState) => ({
                ...prevState,
                development: false,
            }))
        }
    }
    const loadHistorySplitRecord = async () => {
        try {
            // the data still need to be fixed
            // const fecthData: IResponseData<IRHistorySpliRecord> =
            //     await fetchControlTowerHistory(formikFilterCO.values, {
            //         field: {
            //             companyName: filter.name,
            //         },
            //         historyType: 'splitRecord',
            //         pageName: buttonContent,
            //         tabValue: tabFilterOverview.value,
            //     })
            const barChartSplitRecord = [
                {
                    name: 'This Period',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
                {
                    name: 'Last Period',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
            ]

            dispatch(setHistorySplitRecord(barChartSplitRecord))
        } catch (error) {
            console.error(error)
        } finally {
            setLoadingHistory((prevState) => ({
                ...prevState,
                splitRecord: false,
            }))
        }
    }
    const handleHistoryFilter = (companyName: string) => {
        dispatch(setHistoryFilterName(companyName))
    }
    const getActiveFieldFilterDataCategory = () => {
        const activeFilter = Object.entries(
            formikFilterCO.values.DataCategory,
        ).find(([key, value]) => value === true)

        if (!activeFilter) {
            throw new Error('No active filter found')
        }

        const [key] = activeFilter

        // Map key filter ke nama field di items
        const fieldMapping: Record<
            string,
            { accessor: ICOFilterByMetrics; label: string }
        > = {
            Shipments: {
                accessor: 'shipment',
                label: 'Shipments',
            },
            Revenue: {
                accessor: 'revenue',
                label: 'Revenue',
            },
            GrossProfit: {
                accessor: 'gp',
                label: 'Gross Profit',
            },
            Volume: {
                accessor: 'volume',
                label: 'Volume',
            },
        }

        const fieldName = fieldMapping[key]

        if (!fieldName) {
            throw new Error(`Field mapping not found for key: ${key}`)
        }

        return fieldName
    }

    // function overview
    const fechtOverviewData = async () => {
        setLoadingOverview({
            relatedKeyFigures: true,
            overviewTable2: true,
        })
        try {
            const relatedKeyFigures = fetchRelatedKeyFigures(
                formikFilterCO.values,
            )
                .then((res) => {
                    if (res.isSuccess) {
                        dispatch(setDataOverviewTable1(res.data))
                    }
                })
                .catch((error) => {
                    console.error(
                        'Error fetching data related key figures:',
                        error,
                    )
                })
                .finally(() => {
                    setLoadingOverview((prevLoading) => ({
                        ...prevLoading,
                        relatedKeyFigures: false,
                    }))
                })

            const tableOverview2 = loadOverviewTable2()
            await Promise.allSettled([relatedKeyFigures, tableOverview2])
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }
    const loadOverviewTable2 = async () => {
        try {
            const loadData = await fetchOverviewTable2(
                formikFilterCO.values,
                tabFilterOverview.value,
            )
            const fieldName = getActiveFieldFilterDataCategory()

            dispatch(
                setDataOverviewTable2({
                    data: loadData.data,
                    filter: fieldName.accessor,
                }),
            )
            setRawData((prevState) => ({
                ...prevState,
                dataOverviewTable2: loadData.data,
            }))

            // set false if data not exits
            const historyDevelopment = loadHistoryDevelopment(
                loadData?.data[0]?.name || '',
            )
            const historySplitRecord = loadHistorySplitRecord()

            await Promise.allSettled([
                loadData,
                historyDevelopment,
                historySplitRecord,
            ])
        } catch (error) {
            console.error('Error fetching data:', error)
        } finally {
            setLoadingOverview((prevLoading) => ({
                ...prevLoading,
                overviewTable2: false,
            }))
        }
    }
    const loadOverviewTable2UseRawData = () => {
        dispatch(
            setDataOverviewTable2({
                data: rawData.dataOverviewTable2,
                filter: getActiveFieldFilterDataCategory().accessor,
                useRawData: true,
            }),
        )
        loadHistoryDevelopment(historyFilterName)
        loadHistorySplitRecord()
    }
    const handlingPageNumberOverviewTable2 = (pageNumber: number) => {
        if (pageNumber <= 0) return
        dispatch(setPageNumberOverviewTable2Slice(pageNumber))
    }

    // function trade lanes
    const fechtTradeLanesData = async () => {
        setLoadinTradeLanes({
            region: true,
            table: true,
        })
        try {
            switch (tabFilterTradeLanes.value) {
                case 'Countries':
                    loadTradeLanesPortsHistoryDevelopment('')
                    break
                case 'Ports':
                    loadTradeCountriesHistoryDevelopment('')
                    break
                case 'Lanes':
                    break
            }
            loadHistorySplitRecord()
        } catch (error) {
            console.error('Error fetching data:', error)
        } finally {
            setLoadinTradeLanes({
                region: false,
                table: false,
            })
        }
    }
    const loadTradeLanesPortsHistoryDevelopment = async (name: string) => {
        const start = moment(formikFilterCO.values.StartDate, 'MM/YYYY')
        const end = moment(formikFilterCO.values.EndDate, 'MM/YYYY')

        const rangeMonth: string[] = []
        const line1 = []
        const line2 = []
        let index = 1
        try {
            let fecthData: IResponseData<IRTrandeLanesPortsHistoryDevelopment> | null =
                null

            if (name)
                fecthData = await fetchControlTowerHistory(
                    formikFilterCO.values,
                    {
                        field: {
                            portCode: name,
                        },
                        historyType: 'development',
                        pageName: buttonContent,
                        tabValue: tabFilterOverview.value,
                    },
                )

            const fieldName = getActiveFieldFilterDataCategoryTradeLanes()

            while (start.isSameOrBefore(end, 'month')) {
                const month = start.format('M')
                const year = start.format('YYYY')
                let currentValue = undefined
                let previousValue = undefined

                if (fecthData !== null) {
                    const findDataByMonthYear = fecthData?.data?.data?.find(
                        (item) => item.month === month && item.year === year,
                    )
                    currentValue = findDataByMonthYear
                        ? findDataByMonthYear.current[
                              fieldName.accessor as keyof typeof findDataByMonthYear.current
                          ]
                        : undefined
                    previousValue = findDataByMonthYear
                        ? findDataByMonthYear.previous[
                              fieldName.accessor as keyof typeof findDataByMonthYear.previous
                          ]
                        : undefined
                }
                rangeMonth.push(start.format('MMM'))
                line1.push({
                    x: index,
                    secName: start.format('YYYY'),
                    y: Number(currentValue) || 0,
                    keyId: 'line1',
                })
                line2.push({
                    x: index,
                    secName: start.format('YYYY'),
                    y: Number(previousValue) || 0,
                    keyId: 'line2',
                })

                start.add(1, 'month')
                index++
            }

            const valueInfoCard = fecthData?.data?.port
                ? fecthData.data.port[
                      fieldName.infoCard as keyof typeof fecthData.data.port
                  ]
                : 0

            const infoCardItems = [
                {
                    value: numberWithCommas(valueInfoCard.toString()),
                    label: `Average ${fieldName.label}`,
                },
            ]

            dispatch(
                setHistoryDevelopment({
                    line1,
                    line2,
                    tickItems: rangeMonth,
                    infoCardItems,
                }),
            )
        } catch (error) {
            console.error(error)
        } finally {
            setLoadingHistory((prevState) => ({
                ...prevState,
                development: false,
            }))
        }
    }
    const loadTradeCountriesHistoryDevelopment = async (name: string) => {
        const start = moment(formikFilterCO.values.StartDate, 'MM/YYYY')
        const end = moment(formikFilterCO.values.EndDate, 'MM/YYYY')

        const rangeMonth: string[] = []
        const line1 = []
        const line2 = []
        let index = 1
        try {
            let fecthData: IResponseData<IRTrandeLanesCountriesHistoryDevelopment> | null =
                null

            if (name)
                fecthData = await fetchControlTowerHistory(
                    formikFilterCO.values,
                    {
                        field: {
                            portCode: name,
                        },
                        historyType: 'development',
                        pageName: buttonContent,
                        tabValue: tabFilterOverview.value,
                    },
                )

            const fieldName = getActiveFieldFilterDataCategoryTradeLanes()

            while (start.isSameOrBefore(end, 'month')) {
                const month = start.format('M')
                const year = start.format('YYYY')
                let currentValue = undefined
                let previousValue = undefined

                if (fecthData !== null) {
                    const findDataByMonthYear = fecthData?.data?.data?.find(
                        (item) => item.month === month && item.year === year,
                    )
                    currentValue = findDataByMonthYear
                        ? findDataByMonthYear.current[
                              fieldName.accessor as keyof typeof findDataByMonthYear.current
                          ]
                        : undefined
                    previousValue = findDataByMonthYear
                        ? findDataByMonthYear.previous[
                              fieldName.accessor as keyof typeof findDataByMonthYear.previous
                          ]
                        : undefined
                }
                rangeMonth.push(start.format('MMM'))
                line1.push({
                    x: index,
                    secName: start.format('YYYY'),
                    y: Number(currentValue) || 0,
                    keyId: 'line1',
                })
                line2.push({
                    x: index,
                    secName: start.format('YYYY'),
                    y: Number(previousValue) || 0,
                    keyId: 'line2',
                })

                start.add(1, 'month')
                index++
            }

            const valueInfoCard = fecthData?.data?.country
                ? fecthData.data.country[
                      fieldName.infoCard as keyof typeof fecthData.data.country
                  ]
                : 0

            const infoCardItems = [
                {
                    value: numberWithCommas(valueInfoCard.toString()),
                    label: `Average ${fieldName.label}`,
                },
            ]

            dispatch(
                setHistoryDevelopment({
                    line1,
                    line2,
                    tickItems: rangeMonth,
                    infoCardItems,
                }),
            )
        } catch (error) {
            console.error(error)
        } finally {
            setLoadingHistory((prevState) => ({
                ...prevState,
                development: false,
            }))
        }
    }
    const getActiveFieldFilterDataCategoryTradeLanes = () => {
        const activeFilter = Object.entries(
            formikFilterCO.values.DataCategory,
        ).find(([key, value]) => value === true)

        if (!activeFilter) {
            throw new Error('No active filter found')
        }

        const [key] = activeFilter

        // Map key filter ke nama field di items
        const fieldMapping: Record<
            string,
            { accessor: ICOFilterByMetrics; label: string; infoCard: string }
        > = {
            Shipments: {
                accessor: 'shipment',
                infoCard: 'avgCurrentShipment',
                label: 'Shipments',
            },
            Revenue: {
                accessor: 'revenue',
                label: 'Revenue',
                infoCard: 'avgCurrentRevenue',
            },
            GrossProfit: {
                accessor: 'gp',
                label: 'Gross Profit',
                infoCard: 'avgCurrentGp',
            },
            Volume: {
                accessor: 'volume',
                label: 'Volume',
                infoCard: 'avgCurrentVolume',
            },
        }

        const fieldName = fieldMapping[key]

        if (!fieldName) {
            throw new Error(`Field mapping not found for key: ${key}`)
        }

        return fieldName
    }

    // function finance
    const loadAverageMonthlyPerformanceData = async () => {
        setLoadingFinance({
            averageMonthlyPerformance: true,
        })

        const start = moment(formikFilterCO.values.StartDate, 'MM/YYYY')
        const end = moment(formikFilterCO.values.EndDate, 'MM/YYYY')

        const rangeMonth: string[] = []
        const line1 = []
        const line2 = []
        const line3 = []
        let index = 1

        while (start.isSameOrBefore(end, 'month')) {
            rangeMonth.push(start.format('MMM'))
            line1.push({
                x: index,
                secName: start.format('YYYY'),
                y: index === 1 ? 3 : faker.number.int({ min: 0, max: 10 }),
                keyId: 'line1',
            })
            line2.push({
                x: index,
                secName: start.format('YYYY'),
                y: index === 1 ? 3 : faker.number.int({ min: 0, max: 10 }),
                keyId: 'line2',
            })
            line3.push({
                x: index,
                secName: start.format('YYYY'),
                y: index === 1 ? 3 : faker.number.int({ min: 0, max: 10 }),
                keyId: 'line3',
            })

            start.add(1, 'month')
            index++
        }

        dispatch(
            setAverageMonthlyPerformance({
                line1,
                line2,
                line3,
                tickItems: rangeMonth,
            }),
        )
        setTimeout(() => {
            setLoadingFinance({
                averageMonthlyPerformance: false,
            })
        }, 1000)
    }

    // useEffects
    useEffect(() => {
        formikFilterCO.submitForm()
    }, [])
    useEffect(() => {
        const handler = setTimeout(() => {
            setIsInitialRender(false)
            wrapFetchDataContent()
        }, 1000)
        return () => clearTimeout(handler)
    }, [formikFilterCO.values, formikFilterCO.errors])
    useEffect(() => {
        if (!isInitialRender) {
            wrapFetchDataContent()
        }
    }, [buttonContent])
    useEffect(() => {
        if (!isInitialRender) {
            wrapFetchDataHistoryDevelopment(historyFilterName)
        }
    }, [historyFilterName])

    // useEffect overview
    useEffect(() => {
        if (!isInitialRender) {
            setLoadingOverview((prevState) => ({
                ...prevState,
                overviewTable2: true,
            }))
            loadOverviewTable2()
        }
    }, [tabFilterOverview])
    useEffect(() => {
        if (!isInitialRender) {
            loadOverviewTable2UseRawData()
        }
    }, [dataOverviewTable2.filter.pageNumber])

    return {
        // common
        formikFilterCO,
        buttonContentCO,
        buttonContent,
        historyData,
        loadingHistory,
        handlePillsDataCategory,
        handlePillsShipmentEvent,
        handleHistoryFilter,

        // overview
        dataOverviewTable1,
        dataOverviewTable2,
        tabFilterOverview,
        setTabFilterOverview,
        joinChartOverview,
        barChartOverview,
        loadingOverview,
        handlingPageNumberOverviewTable2,

        // trade lanes
        pieChartTradeLanes,
        tabFilterTradeLanes,
        setTabFilterTradeLanes,
        dataTradeLanesTable,

        // customers
        tabFilterCustomers,
        setTabFilterCustomers,
        customerChartOrganisationData,
        customerTableData,

        // finance
        loadingFinance,
        financeTableData,
        tabFilterFinance,
        setTabFilterFinance,
        financePieChart,
        averageMonthlyPerformance,
    }
}

export default useControlTower
