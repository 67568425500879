import { ITabItem } from '@components/tab/tab.interface'
import { IBookings } from './bookings.interface'
import { IConfigureColumns } from '@components/configure-columns-modal/configure-column-modal.interface'
import { ITableColumn } from '@components/table/table.interface'

export const BookingsHeader: ITableColumn[] = [
    {
        label: 'Mode',
        accessor: 'textTransportMode',
    },
    {
        label: 'Booking No',
        accessor: 'bookingNo',
    },
    {
        label: 'Consignor',
        accessor: 'consignorName',
    },
    {
        label: 'Goods Description',
        accessor: 'goodsDescription',
    },
    {
        label: "Shipper's Ref",
        accessor: 'shippersRef',
    },
    {
        label: 'Origin',
        accessor: 'originPort',
    },
    {
        label: 'Destination',
        accessor: 'destinationPort',
    },
    {
        label: 'Packs',
        accessor: 'packs',
    },
    {
        label: 'Weight Measure',
        accessor: 'weightMeasureKG',
    },
    {
        label: 'Volume Measure',
        accessor: 'volumeMeasureM3',
    },
    {
        label: 'Master Bill',
        accessor: 'masterBill',
    },
    {
        label: 'Load Port',
        accessor: 'textLoadPort',
    },
    {
        label: 'Updated By',
        accessor: 'datesUXMLUpdateCutOffDate',
    },
]

export const bookingConfigureColumns: IConfigureColumns<IBookings>[] = [
    {
        title: 'Flags',
        columns: [
            {
                statusColumn: 'selectedColumns',
                labelTranslation: 'additional.selected_column',
                label: 'Mode',
                accessor: 'textTransportMode',
                tdClassName: '!pt-0 !pb-0',
                className: '!h-auto',
                showLabel: false,
                sort: false,
                customBuild: (data) => {
                    let iconTransportMode: string
                    switch (data) {
                        case 'Airplane':
                            iconTransportMode = 'ri-plane-line'
                            break
                        case 'Ship':
                            iconTransportMode = 'ri-ship-line'
                            break
                        case 'Truck':
                            iconTransportMode = 'ri-truck-line'
                            break
                        default:
                            iconTransportMode = 'ri-plane-line'
                    }

                    return (
                        <i className={`${iconTransportMode} text-size-XL`}></i>
                    )
                },
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Status',
                accessor: 'textTransportMode',
                customBuild: (data) => {
                    let statusClass: string
                    let label: string
                    switch (data) {
                        case 'arrived':
                            statusClass = 'themes-bg-green-v3 themes-text-white'
                            label = 'Arrived'
                            break
                        case 'inTransit':
                            statusClass =
                                'themes-bg-yellow-v3 themes-text-white'
                            label = 'In Transit'
                            break
                        case 'delayed':
                            statusClass = 'themes-bg-red-v3 themes-text-white'
                            label = 'Delayed'
                            break
                        case 'others':
                            statusClass = 'themes-bg-gray-v3'
                            label = 'Others'
                            break
                        default:
                            statusClass = 'themes-bg-red-v3 themes-text-white'
                            label = 'Delayed'
                    }

                    return (
                        <div
                            className={`${statusClass}  px-2 rounded flex justify-center items-center`}
                        >
                            {label}
                        </div>
                    )
                },
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Booking No.',
                accessor: 'bookingNo',
                // customBuild: (data, row) => {
                //     let statusClass: string
                //     switch (row?.status) {
                //         case 'confirmed':
                //             statusClass = 'themes-bg-green-v3 themes-text-white'
                //             break
                //         case 'booked':
                //             statusClass =
                //                 'themes-bg-yellow-v3 themes-text-white'
                //             break
                //         case 'waitListed':
                //             statusClass = 'themes-bg-red-v3 themes-text-white'
                //             break
                //         default:
                //             statusClass = 'themes-bg-brand-v4 themes-text-white'
                //     }
                //     return (
                //         <div
                //             className={`${statusClass}  px-2 rounded w-[95px] flex justify-center`}
                //         >
                //             {data}
                //         </div>
                //     )
                // },
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Consignor',
                accessor: 'consignorName',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Consignee',
                accessor: 'consigneeName',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Goods Description',
                labelTranslation: 'products.goods_description',
                accessor: 'goodsDescription',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Origin',
                labelTranslation: 'bookings.origin',
                accessor: 'originPort',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Destination',
                labelTranslation: 'bookings.destination',
                accessor: 'destinationPort',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Packs',
                labelTranslation: 'products.packs',
                accessor: 'packs',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Total Weight',
                // labelTranslation: 'products.weight_measure',
                accessor: 'weightMeasureKG',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Total Volume',
                // labelTranslation: 'products.volume_measure',
                accessor: 'volumeMeasureM3',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'System Last Edit Date UTC',
                accessor: 'datesUXMLUpdateCutOffDate',
            },
        ],
    },
]

export const tabShipperHeaders: ITabItem[] = [
    {
        label: 'Shipper Address',
        // labelTranslationCode: 'bookings.pickup_address',
        key: 'status',
        value: 'shipper',
        className: 'brand',
    },
    {
        label: 'Documentary Address',
        // labelTranslationCode: 'bookings.documentary_address',
        key: 'status',
        value: 'documentaryAddress',
        className: 'brand',
    },
]

export const tabConsigneeHeaders: ITabItem[] = [
    {
        label: 'Consignee Address',
        // labelTranslationCode: 'bookings.pickup_address',
        key: 'status',
        value: 'consignee',
        className: 'brand',
    },
    {
        label: 'Documentary Address',
        // labelTranslationCode: 'bookings.documentary_address',
        key: 'status',
        value: 'ConsigneeDocumentaryAddress',
        className: 'brand',
    },
]
