import Input from '@components/input/input.component'
import { useTranslation } from 'react-i18next'

const Details = () => {
    const { t } = useTranslation()
    return (
        <div className="grid grid-cols-4 gap-3 px-3 py-2">
            <div className="flex flex-col">
                <div className="text-[16px] themes-text-gray-v5">
                    {t('products.product_profile')}
                </div>
                <div className="flex flex-col h-full gap-3 pt-2">
                    <Input
                        useUppercaseLabel={true}
                        label={t('products.part_number')}
                        placeholder={t('additional.not_available')}
                        disabled
                    />
                    <Input
                        useUppercaseLabel={true}
                        label={t('products.description')}
                        placeholder={t('additional.not_available')}
                        disabled
                    />
                    <Input
                        useUppercaseLabel={true}
                        label={t('products.commodity')}
                        placeholder={t('additional.not_available')}
                        disabled
                    />
                    <Input
                        useUppercaseLabel={true}
                        label={t('products.stock_keeping_unit')}
                        placeholder={t('additional.not_available')}
                        disabled
                    />
                    <Input
                        label={t('products.decimal_places')}
                        placeholder={t('additional.not_available')}
                        disabled
                    />
                    <Input
                        label={t('products.last_cost')}
                        placeholder={t('additional.not_available')}
                        disabled
                    />
                </div>
            </div>
            <div>
                <div className="text-[16px] themes-text-gray-v5">
                    {t('products.dimensions_and_weight')}
                </div>
                <div className="flex flex-col gap-3 pt-2">
                    <Input
                        useUppercaseLabel={true}
                        label={t('products.height')}
                        placeholder={t('additional.not_available')}
                        disabled
                    />
                    <Input
                        useUppercaseLabel={true}
                        label={t('products.width')}
                        placeholder={t('additional.not_available')}
                        disabled
                    />
                    <Input
                        useUppercaseLabel={true}
                        label={t('products.depth')}
                        placeholder={t('additional.not_available')}
                        disabled
                    />
                    <Input
                        useUppercaseLabel={true}
                        label={t('products.weight')}
                        placeholder={t('additional.not_available')}
                        disabled
                    />
                    <Input
                        useUppercaseLabel={true}
                        label={t('products.cubic')}
                        placeholder={t('additional.not_available')}
                        disabled
                    />
                    <Input
                        useUppercaseLabel={true}
                        label={t('products.units_per_pallet')}
                        placeholder={t('additional.not_available')}
                        disabled
                    />
                </div>
            </div>
        </div>
    )
}

export default Details
