import AvatarCircle from '@components/avatar-circle/avatar-circle.component'
import Button from '@components/button/button.component'
import Table from '@components/table/table.component'
import './manage-user-details.style.css'
import useManageUserDetails from './manage-user-details.service'
import { userActivityHeaders } from '../manage-users.static'
import { useTranslation } from 'react-i18next'
import { IManageUserLog } from './manage-users-details.interface'
import SuspendUserModal from 'client/common/pages/user-acces/modal/suspend-user-modal.component'
import Pagination from '@components/pagination/pagination.component'

const ManageUserDetails = () => {
    const { t } = useTranslation()
    const {
        id,
        manageUserDataLog,
        modalConfirmationService,
        manageUserDetailMeta,
        manageUserDetail,
        loading,
        navigate,
        changeStatus,
        setPageData,
    } = useManageUserDetails()

    return (
        <>
            <div
                className={`manage-user-detail transition-opacity duration-500 !overflow-visible container-global`}
            >
                <div className="flex sm:flex-wrap w-full">
                    <div className="content-1 h-full border-r">
                        {/* Flex 1, 2 rows */}

                        <div className="content-1-A w-full">
                            {/* Image Session */}
                            <div className="flex justify-between items-centers gap-4 p-4 border-b">
                                <div className="">
                                    <AvatarCircle
                                        name={'Darlene Osinski'}
                                        size={50}
                                    />
                                </div>
                                <div className="flex-1 flex items-center">
                                    <div className="text-size-M font-bold">
                                        {manageUserDetail.userName ??
                                            'Empty Name'}
                                    </div>
                                    {/* <div className="text-size-M">Admin Staff</div> */}
                                </div>
                                <div className="flex items-center">
                                    <Button
                                        label={`EDIT USER`}
                                        onClick={() => {
                                            navigate(
                                                '/manage-users/update/' + id,
                                            )
                                        }}
                                        useUpperCase={true}
                                        variant="brand-v2-inverse"
                                    />
                                </div>
                                {/* <SettingIcon
                                    isActive={manageUserDetail.isActive ? true : false}
                                    suspendFunction={() => {
                                        modalConfirmationService.openModalHandling()
                                    }}
                                /> */}
                            </div>

                            {/* Detail User Session */}
                            <div className="flex p-4 flex-col gap-4 overflow-auto max-h-[calc(100vh-262px)]">
                                <ValueLabel
                                    label={t('users.status')}
                                    value={
                                        manageUserDetail.isActive !== null
                                            ? manageUserDetail.isActive
                                                ? 'Active'
                                                : 'Suspended'
                                            : '-'
                                    }
                                />
                                <ValueLabel
                                    label={t('users.job_title')}
                                    value={manageUserDetail.jobTitle}
                                />
                                <ValueLabel
                                    label={t('users.phone')}
                                    value={manageUserDetail.phoneNumber}
                                />
                                <ValueLabel
                                    label={t('users.mobile_phone_number')}
                                    value={manageUserDetail.mobilePhoneNumber}
                                />
                                <ValueLabel
                                    label={`Address 1`}
                                    value={manageUserDetail.addressDetails}
                                />
                                <ValueLabel label={`Address 2`} value={''} />
                                <ValueLabel
                                    label={t('users.city')}
                                    value={manageUserDetail.city}
                                />
                                <ValueLabel label={`State`} value={''} />
                                <ValueLabel label={`Postcode`} value={''} />
                                <ValueLabel
                                    label={t('users.country')}
                                    value={manageUserDetail.country}
                                />
                                <ValueLabel
                                    label={t('users.role')}
                                    value={
                                        manageUserDetail.role?.moduleAccessList
                                            ?.length === 0 ||
                                        manageUserDetail.role
                                            ?.moduleAccessList === undefined
                                            ? '-'
                                            : manageUserDetail.role
                                                    ?.roleType === 0
                                              ? 'Admin'
                                              : 'User'
                                    }
                                />
                                <ValueLabel
                                    label={t('users.industry')}
                                    value={manageUserDetail.jobCategory}
                                />
                                <ValueLabel
                                    label={t('users.email')}
                                    value={manageUserDetail.email}
                                />
                                <ValueLabel
                                    label={t('users.default_currency')}
                                    value={''}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-grow flex-col sm:border-t">
                        <div className="flex items-center flex-grow text-size-L px-4 font-bold border-b h-[83px]">
                            {t('users.user_activity_logs')}
                        </div>
                        <div className="lg:grid h-[calc(100vh-330px)] w-full">
                            <Table<IManageUserLog>
                                headers={userActivityHeaders}
                                data={manageUserDataLog}
                                moduleTitle={t('users.user')}
                                usePagination={false}
                                nextHandling={(page) => {
                                    setPageData(page)
                                }}
                                previousHandling={(page) => {
                                    setPageData(page)
                                }}
                                meta={manageUserDetailMeta}
                                enableExport={false}
                                loading={loading}
                            />
                        </div>

                        <div className="border-t">
                            <Pagination
                                meta={manageUserDetailMeta}
                                previousHandling={(page) => {
                                    setPageData(page)
                                }}
                                nextHandling={(page) => {
                                    setPageData(page)
                                }}
                            />
                        </div>
                    </div>
                </div>

                {/* Modal for suspend and unsuspend user */}
                {/* <SelectModuleModal
                modalService={modalService}
                moduleList={moduleAdjustmentList}
                setModalSelectedPhase1={(selected) => {
                    setModalSelectedPhase1(selected)
                }}
                setModalSelectedPhase2={(selected) => {
                    setModalSelectedPhase2(selected)
                }}
                setAccessHandling={(indexAction: number) => {
                    setAccess(indexAction)
                }}
                modalSelectedPhase1={modalSelectedPhase1}
                modalSelectedPhase2={modalSelectedPhase2}
                setAllAccess={() => {
                    setAllAccess({})
                }}
            /> */}

                {/* Border */}
                <div className="border-t p-4 flex justify-between">
                    <Button
                        label={t('action.back')}
                        onClick={() => {
                            navigate('/manage-users')
                        }}
                        variant="brand-v2-inverse"
                        useUpperCase
                    />
                </div>
            </div>

            <SuspendUserModal
                data={manageUserDetail}
                modalService={modalConfirmationService}
                isActive={manageUserDetail?.isActive ?? false}
                onSubmit={function (): void {
                    changeStatus()
                }}
            />
        </>
    )
}

const ValueLabel = ({
    label = ' - ',
    value = ' - ',
}: {
    label?: string
    value?: string
}) => {
    const labelShow = label === '' || !label ? '-' : label
    const valueShow = value === '' || !value ? '-' : value
    return (
        <div className="leading-none ">
            <p className="!text-size-XS font-light pb-1">{labelShow}</p>
            <p className="!text-size-M font-normal">{valueShow}</p>
        </div>
    )
}

export default ManageUserDetails
