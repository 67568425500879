import Modal from '@components/modal/modal.component'
import { IUseCreateBooking } from '../create-booking.interface'
import { FormikProvider } from 'formik'
import Button from '@components/button/button.component'
import FormInput from '@components/form-input/form-input.component'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormMeasurementInput from '@components/form-measurement-input/form-measurement-input.component'
import { ICreateBookingPackLinesValidation } from 'client/ifb/form-validation/create-booking.validation'

const FormPackLineModal = ({ CBService }: { CBService: IUseCreateBooking }) => {
    const borderComponent = <div className="border-b"></div>
    const formTitle =
        CBService.packLineParams === 'add' ? 'Add Pack Line' : 'Edit Pack Line'
    return (
        <Modal
            isModalOpen={CBService.formPackLineModal.isModalOpen}
            className="!w-3/5 px-0 flex flex-col gap-3"
        >
            <>
                <div className="px-3 themes-text-gray-v9 text-size-M">
                    {formTitle}
                </div>
                {borderComponent}
                <div className="grid grid-cols-2 gap-3 px-3 h-full mobile:grid-cols-1 mobile:h-[calc(100vh-22rem)] mobile:overflow-auto">
                    <FormikProvider
                        value={CBService.formikCreateBookingPackLines}
                    >
                        <FormInput
                            label={'PIECES'}
                            name={`packs`}
                            placeholder={'Enter here'}
                            parentDivClassName="!mb-0 mobile:mt[3px]"
                            type="number"
                            useUppercaseLabel
                            useArrow={false}
                        />
                        <FormInput
                            label={'DESCRIPTION'}
                            name={`goodsDescription`}
                            placeholder={'Enter here'}
                            parentDivClassName="!mb-0"
                            useUppercaseLabel
                        />
                        <FormDropdown
                            label={'PACK TYPE'}
                            name={`packageType`}
                            placeholder={'Select here'}
                            options={CBService.dropdownData.packsUnit}
                            parentDivClassName="!mb-0"
                            additionalOnClick={(data) => {
                                CBService.additionalClickSetDesc<ICreateBookingPackLinesValidation>(
                                    CBService.formikCreateBookingPackLines,
                                    'packageTypeDescription',
                                    data as IDropdownItem,
                                )
                            }}
                            isClearable
                            isSearchable
                            useUppercaseLabel
                        />
                        <FormInput
                            label={'MARKS AND NUMBERS'}
                            name={`marksAndNumbers`}
                            placeholder={'Enter here'}
                            parentDivClassName="!mb-0"
                            useUppercaseLabel
                        />
                        <div className="flex gap-3">
                            <FormInput
                                label={'LENGTH'}
                                name={`lengthMeasure`}
                                placeholder={'Enter here'}
                                parentDivClassName="!mb-0"
                                valueSuffix={
                                    CBService.formikCreateBookingPackLines
                                        .values.specialField.unitDimension
                                }
                                useDecimalFormat
                                useUppercaseLabel
                            />
                            <FormInput
                                label={'WIDTH'}
                                name={`widthMeasure`}
                                placeholder={'Enter here'}
                                parentDivClassName="!mb-0"
                                valueSuffix={
                                    CBService.formikCreateBookingPackLines
                                        .values.specialField.unitDimension
                                }
                                useDecimalFormat
                                useUppercaseLabel
                            />
                            <FormInput
                                label={'HEIGHT'}
                                name={`heightMeasure`}
                                placeholder={'Enter here'}
                                parentDivClassName="!mb-0"
                                valueSuffix={
                                    CBService.formikCreateBookingPackLines
                                        .values.specialField.unitDimension
                                }
                                useDecimalFormat
                                useUppercaseLabel
                            />
                        </div>
                        <FormInput
                            label={'LINE PRICE'}
                            name={`numericLinePrice`}
                            placeholder={'Enter here'}
                            parentDivClassName="!mb-0"
                            useDecimalFormat
                            useUppercaseLabel
                        />
                        <FormDropdown
                            label={'UNIT DIMENSION'}
                            name={`specialField.unitDimension`}
                            placeholder={'Select here'}
                            options={
                                CBService.dropdownData.lengthWidthHeightMeasure
                            }
                            parentDivClassName="!mb-0"
                            isSearchable
                            useUppercaseLabel
                        />
                        <FormDropdown
                            label={'PRICE CURRENCY'}
                            name={`priceCurrency`}
                            placeholder={'Select here'}
                            options={CBService.dropdownData.currency}
                            parentDivClassName="!mb-0"
                            isClearable
                            isSearchable
                            useUppercaseLabel
                        />
                        <FormMeasurementInput
                            options={
                                CBService.dropdownData
                                    .weightMeasure as IDropdownItem[]
                            }
                            name="specialField.weightMeasure"
                            label={'WEIGHT'}
                            useDecimalFormat
                        />
                        <FormInput
                            label={'TARIFF NUMBER'}
                            name={`tariffNumber`}
                            placeholder={'Enter Here'}
                            parentDivClassName="!mb-0"
                            useDecimalFormat
                            useUppercaseLabel
                        />
                        <FormMeasurementInput
                            options={
                                CBService.dropdownData
                                    .volumeMeasure as IDropdownItem[]
                            }
                            name={'specialField.volumeMeasure'}
                            label={'VOLUME'}
                            useDecimalFormat
                        />
                    </FormikProvider>
                </div>
                {borderComponent}
                <div className="flex  px-3 justify-between">
                    <Button
                        onClick={() => {
                            CBService.formPackLineModal.closeModalHandling()
                        }}
                        label="CANCEL"
                        variant="brand-v2-inverse"
                    />
                    <Button
                        onClick={() => {
                            CBService.formikCreateBookingPackLines.submitForm()
                        }}
                        label="PROCEED"
                        variant="brand-v2-inverse"
                    />
                </div>
            </>
        </Modal>
    )
}

export default FormPackLineModal
