import CardsDashboard from '@components/card-dashboard/card-dashboard.component'
import useVolumes from './volumes.service'
import { BarChartStacked, DoughnutChart } from '@components/rechart-component'
import { FormikProvider } from 'formik'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import InfoCardGroup from '@components/InfoCardGroup/Info-card-group.component'
import { numberToCurrency } from 'common/common.service'
import FormMonthYearPicker from '@components/form-month-year-picker/form-month-year-picker.component'
import Button from '@components/button/button.component'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import ItemListInfo from '@components/item-list-info/item-list-info.component'
import {
    itemListAir,
    itemListCourier,
    itemListRail,
    itemListRoad,
    itemListSea,
} from './volumes.static'
import { IPieChartAdditionalData } from './volumes.interface'

function Volumes() {
    const {
        volemeDropdown,
        weightDropdown,
        formikVolume,
        barData,
        itemTotalChart,
        donatDatashipment,
        isLoading,
        itemActive,
        setItemActive,
        lastValueUnitMeasurement,
        setLastValueUnitMeasurement,
    } = useVolumes()
    const typeData = itemActive ? 'Volume' : 'Weight'
    const typeDropdown = itemActive ? volemeDropdown : weightDropdown

    return (
        <div className="flex flex-col gap-3 overflow-auto xl:overflow-hidden">
            <CardsDashboard className="grid grid-cols-12 gap-3">
                <div className="col-span-12 flex flex-col gap-3 justify-center xl:border-r xl:pr-3 xl:col-span-3">
                    <Button
                        onClick={() => {
                            setItemActive(true)
                            formikVolume.setFieldValue(
                                'MeasureCategory',
                                'volume',
                            )
                            formikVolume.setFieldValue(
                                'UnitMeasure',
                                lastValueUnitMeasurement.volume,
                            )
                        }}
                        className={`${itemActive ? 'themes-bg-brand-v4 themes-text-white ' : ' themes-bg-brand-v2 themes-text-brand-v4'} transition-all duration-300 cursor-pointer px-5 py-1 rounded-md flex items-center text-[12px]`}
                        icon="ri-box-3-line text-size-XL"
                        label="VOLUME MEASURE"
                        labelClass="sm:!block sm:!pl-2"
                    />
                    <Button
                        onClick={() => {
                            setItemActive(false)
                            formikVolume.setFieldValue(
                                'MeasureCategory',
                                'weight',
                            )
                            formikVolume.setFieldValue(
                                'UnitMeasure',
                                lastValueUnitMeasurement.weight,
                            )
                        }}
                        className={`${!itemActive ? 'themes-bg-brand-v4 themes-text-white ' : ' themes-bg-brand-v2 themes-text-brand-v4'} transition-all duration-300 cursor-pointer px-5 py-1 rounded-md flex items-center text-[12px]`}
                        icon="ri-box-3-line text-size-XL"
                        label="WEIGHT MEASURE"
                        labelClass="sm:!block sm:!pl-2"
                    />
                </div>
                <FormikProvider value={formikVolume}>
                    <div className="col-span-12 grid grid-cols-12 gap-3 xl:col-span-9">
                        <div className="grid grid-cols-2 gap-3 md:border-r md:pr-3 xl:col-span-5 md:col-span-7 sm:col-span-12">
                            <FormDropdown
                                isSearchable
                                label={'SHIPMENT DATE BY'}
                                name={`ShipmentDateBy`}
                                placeholder="Select Here"
                                parentDivClassName="!mb-0 sm:col-span-2 lg:col-span-2 md:col-span-2 h-[40px]"
                                className="h-[40px]"
                                options={[
                                    { value: 'eta', label: 'ETA' },
                                    { value: 'etd', label: 'ETD' },
                                    {
                                        value: 'newshipment',
                                        label: 'New Shipment',
                                    },
                                ]}
                            />
                            <FormMonthYearPicker
                                name="StartDate"
                                label="START MONTH"
                                placeholder="MM/YYYY"
                                hideError
                            />
                            <FormMonthYearPicker
                                name="EndDate"
                                label="END MONTH"
                                placeholder="MM/YYYY"
                                hideError
                            />
                        </div>
                        <FormDropdown
                            isSearchable
                            label={'UNIT MEASUREMENT'}
                            name={`UnitMeasure`}
                            placeholder="Select Here"
                            parentDivClassName="xl:col-span-5 md:col-span-5 sm:col-span-12"
                            options={typeDropdown}
                            additionalOnClick={(data) => {
                                const _data = data as IDropdownItem
                                setLastValueUnitMeasurement((prevState) => ({
                                    ...prevState,
                                    ...(formikVolume.values.MeasureCategory ===
                                        'volume' && {
                                        volume: _data.value as string,
                                    }),
                                    ...(formikVolume.values.MeasureCategory ===
                                        'weight' && {
                                        weight: _data.value as string,
                                    }),
                                }))
                            }}
                        />
                    </div>
                </FormikProvider>
            </CardsDashboard>
            <div className="grid grid-cols-12 grid-rows-2 gap-3 xl:h-[calc(100vh-15.2rem)]">
                <CardsDashboard className="!p-0 flex flex-col row-span-2 col-span-12 xl:col-span-4 h-[750px] lg:h-[650px] xl:h-full">
                    <div className="p-2 h-1/2 flex flex-col xl:h-1/2 lg:h-2/5 ">
                        <div className="text-size-XS font-bold">
                            Total {typeData} by Transport Modes
                        </div>
                        <InfoCardGroup
                            items={[
                                {
                                    value: `${numberToCurrency(itemTotalChart.donatShipment)} ${formikVolume.values.UnitMeasure}`,
                                    label: `Total Shipments ${typeData}`,
                                },
                            ]}
                            titleClass="!text-size-XS"
                            descClass="!text-[9px]"
                        />
                        <div className="flex-grow">
                            <DoughnutChart<IPieChartAdditionalData>
                                data={donatDatashipment}
                                isLoading={isLoading.donat}
                                renderTextLegendAndTooltip={({
                                    additionalValue,
                                }) =>
                                    `${additionalValue?.value} ${additionalValue?.unitMeasurement}`
                                }
                            />
                        </div>
                    </div>
                    <div className="border-b"></div>
                    <div className="px-2 pb-2 h-1/2 flex flex-col xl:h-1/2 lg:h-3/5 ">
                        <div className="flex justify-between">
                            <div className="font-bold text-size-XS">ROAD</div>
                            <ItemListInfo itemListInfo={itemListRoad} />
                        </div>
                        <InfoCardGroup
                            items={[
                                {
                                    value: `${numberToCurrency(itemTotalChart.road.fcl)} ${formikVolume.values.UnitMeasure}`,
                                    label: `Total ${typeData} (FCL)`,
                                },
                                {
                                    value: `${numberToCurrency(itemTotalChart.road.lcl)} ${formikVolume.values.UnitMeasure}`,
                                    label: `Total ${typeData} (LCL)`,
                                },
                                {
                                    value: `${numberToCurrency(itemTotalChart.road.ftl)} ${formikVolume.values.UnitMeasure}`,
                                    label: `Total ${typeData} (FTL)`,
                                },
                            ]}
                            titleClass="!text-size-XS"
                            descClass="!text-[9px]"
                        />
                        <div className="flex-grow">
                            <BarChartStacked
                                data={barData.road}
                                isLoading={isLoading.road}
                                renderTooltipValue={(data) => {
                                    let loadContent = ''
                                    const dataValue = data?.value
                                        ? numberToCurrency(data.value)
                                        : '0.00'
                                    switch (data?.name) {
                                        case 'f1':
                                            loadContent = `FCL:  ${dataValue}`
                                            break
                                        case 'f2':
                                            loadContent = `LCL:  ${dataValue}`
                                            break
                                        case 'f3':
                                            loadContent = `FTL:  ${dataValue}`
                                            break
                                    }
                                    return loadContent
                                }}
                            />
                        </div>
                    </div>
                </CardsDashboard>
                <CardsDashboard className="!py-0 gap-2 flex w-full col-span-12 row-span-2 xl:col-span-8 xl:row-span-1 xl:h-full xl:flex-row md:flex-col md:h-[950px] sm:flex-col sm:h-[722px]">
                    <div className="flex py-2 h-1/2 flex-col xl:pr-2 xl:border-r xl:h-full lg:h-1/2  md:w-full mobile:w-full">
                        <div className="flex justify-between">
                            <div className="text-size-XS font-bold">SEA</div>
                            <ItemListInfo itemListInfo={itemListSea} />
                        </div>
                        <InfoCardGroup
                            items={[
                                {
                                    value: `${numberToCurrency(itemTotalChart.sea.fcl)} ${formikVolume.values.UnitMeasure}`,
                                    label: `Total ${typeData} (FCL)`,
                                },
                                {
                                    value: `${numberToCurrency(itemTotalChart.sea.lcl)} ${formikVolume.values.UnitMeasure}`,
                                    label: `Total ${typeData} (LCL)`,
                                },
                            ]}
                            titleClass="!text-size-XS"
                            descClass="!text-[9px]"
                        />
                        <div className="flex-grow">
                            <BarChartStacked
                                data={barData.sea}
                                isLoading={isLoading.sea}
                                renderTooltipValue={(data) => {
                                    let loadContent = ''
                                    const dataValue = data?.value
                                        ? numberToCurrency(data.value)
                                        : '0.00'
                                    switch (data?.name) {
                                        case 'f1':
                                            loadContent = `FCL:  ${dataValue}`
                                            break
                                        case 'f2':
                                            loadContent = `LCL:  ${dataValue}`
                                            break
                                    }
                                    return loadContent
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex py-2 flex-col h-1/2 xl:h-full md:w-full lg:h-1/2 mobile:w-full">
                        <div className="flex justify-between">
                            <div className="text-size-XS font-bold">AIR</div>
                            <ItemListInfo itemListInfo={itemListAir} />
                        </div>
                        <InfoCardGroup
                            items={[
                                {
                                    value: `${numberToCurrency(itemTotalChart.air.lse)} ${formikVolume.values.UnitMeasure}`,
                                    label: `Total ${typeData} (LSE)`,
                                },
                            ]}
                            titleClass="!text-size-XS"
                            descClass="!text-[9px]"
                        />
                        <div className="flex-grow">
                            <BarChartStacked
                                data={barData.air}
                                isLoading={isLoading.air}
                                renderTooltipValue={(data) => {
                                    let loadContent = ''
                                    const dataValue = data?.value
                                        ? numberToCurrency(data.value)
                                        : '0.00'
                                    switch (data?.name) {
                                        case 'f1':
                                            loadContent = `LSE:  ${dataValue}`
                                            break
                                    }
                                    return loadContent
                                }}
                            />
                        </div>
                    </div>
                </CardsDashboard>
                <CardsDashboard className="!py-0 gap-2 flex w-full col-span-12 row-span-2 xl:col-span-8 xl:row-span-1 xl:h-full  xl:flex-row  md:flex-col md:h-[950px] sm:flex-col sm:h-[722px]">
                    <div className="flex flex-col py-2 h-1/2 xl:pr-2 xl:border-r xl:h-full lg:h-1/2 md:w-full mobile:w-full">
                        <div className="flex justify-between">
                            <div className="text-size-XS font-bold">RAIL</div>
                            <ItemListInfo itemListInfo={itemListRail} />
                        </div>
                        <InfoCardGroup
                            items={[
                                {
                                    value: `${numberToCurrency(itemTotalChart.rail.fcl)} ${formikVolume.values.UnitMeasure}`,
                                    label: `Total ${typeData} (FCL)`,
                                },
                                {
                                    value: `${numberToCurrency(itemTotalChart.rail.lcl)} ${formikVolume.values.UnitMeasure}`,
                                    label: `Total ${typeData} (LCL)`,
                                },
                            ]}
                            titleClass="!text-size-XS"
                            descClass="!text-[9px]"
                        />
                        <div className="flex-grow">
                            <BarChartStacked
                                data={barData.rail}
                                isLoading={isLoading.rail}
                                renderTooltipValue={(data) => {
                                    let loadContent = ''
                                    const dataValue = data?.value
                                        ? numberToCurrency(data.value)
                                        : '0.00'
                                    switch (data?.name) {
                                        case 'f1':
                                            loadContent = `FCL:  ${dataValue}`
                                            break
                                        case 'f2':
                                            loadContent = `LCL:  ${dataValue}`
                                            break
                                    }
                                    return loadContent
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col  py-2 h-1/2 xl:h-full lg:h-1/2 md:w-full mobile:w-full">
                        <div className="font-bold text-size-XS"></div>
                        <div className="flex justify-between">
                            <div className="text-size-XS font-bold">
                                COURIER
                            </div>
                            <ItemListInfo itemListInfo={itemListCourier} />
                        </div>
                        <InfoCardGroup
                            items={[
                                {
                                    value: `${numberToCurrency(itemTotalChart.cour.fcl)} ${formikVolume.values.UnitMeasure}`,
                                    label: `Total ${typeData} (Courier)`,
                                },
                            ]}
                            titleClass="!text-size-XS"
                            descClass="!text-[9px]"
                        />
                        <div className="flex-grow">
                            <BarChartStacked
                                data={barData.courier}
                                isLoading={isLoading.courier}
                                renderTooltipValue={(data) => {
                                    let loadContent = ''
                                    const dataValue = data?.value
                                        ? numberToCurrency(data.value)
                                        : '0.00'
                                    switch (data?.name) {
                                        case 'f1':
                                            loadContent = `COU:  ${dataValue}`
                                            break
                                    }
                                    return loadContent
                                }}
                            />
                        </div>
                    </div>
                </CardsDashboard>
            </div>
        </div>
    )
}

export default Volumes
