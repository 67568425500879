import { IMeta, IResponseData } from './common.interface'

export const requiredMessage = (fieldName: string) => `${fieldName} is required`
export const invalidEmailMessage = () => `Invalid Email`

export const initialFilter = {
    status: '',
    inProgressStatus: '',
    pageNumber: 1,
    pageSize: 50,
    search: '',
}

export const initialMeta: IMeta = {
    current_page: 0,
    last_page: 0,
    from: 0,
    to: 0,
    total_Items: 0,
    per_page: 0,
    total_page: 0,
    index_end: 0,
    index_start: 1,
}

export const metaDummy2 = (total: number) => {
    const meta: IMeta = {
        current_page: 1,
        last_page: 1,
        from: 1,
        to: 50,
        total_Items: total,
        per_page: 0,
        total_page: 1,
        index_end: total,
        index_start: 1,
    }
    return meta
}

export const initialResponseData: IResponseData<any> = {
    message: '',
    data: [],
    isSuccess: false,
    meta: {
        current_page: 0,
        last_page: 0,
        per_page: 0,
        total_page: 0,
        from: 0,
        to: 0,
        index_end: 0,
        index_start: 0,
    },
    additionals: {},
    errors: null,
    links: {
        next_page: null,
        previous_page: null,
        first_page: null,
        last_page: null,
    },
}
