import { ISTColumn } from '@components/simple-table/simple-table.interface'
import { ITabItem } from '@components/tab/tab.interface'
import { ICreateBookingPackLinesValidation } from 'client/ifb/form-validation/create-booking.validation'
import { formatDecimalNumber } from '@services/common.service'

export const formBookingStatusHeader: ITabItem[] = [
    {
        label: 'Details',
        labelTranslationCode: 'tab_details.details',
        key: 'status',
        value: 'details',
        className: 'brand',
    },
    {
        label: 'Related Parties',
        key: 'status',
        value: 'relatedParties',
        className: 'brand',
    },
    {
        label: 'Pack Lines',
        labelTranslationCode: 'tab_details.pack_lines',
        key: 'status',
        value: 'packLines',
        className: 'brand',
    },
    {
        label: 'Container',
        key: 'status',
        value: 'container',
        className: 'brand',
    },
]

// segment pack line
export const packLineHeadersForm: ISTColumn<ICreateBookingPackLinesValidation>[] =
    [
        {
            label: 'Pieces',
            // labelTranslation: 'bookings.packs',
            accessor: 'packs',
        },
        {
            label: 'Packs Type',
            // labelTranslation: 'bookings.packs',
            accessor: 'packageTypeDescription',
        },
        {
            label: 'Length',
            // labelTranslation: 'bookings.length_measure',
            accessor: 'specialField',
            customBuild: (data, rowData) => {
                return (
                    <>
                        {formatDecimalNumber(rowData?.lengthMeasure)}{' '}
                        {rowData?.specialField.unitDimension}
                    </>
                )
            },
        },
        {
            label: 'Height',
            // labelTranslation: 'bookings.height_measure',
            accessor: 'specialField',
            customBuild: (data, rowData) => {
                return (
                    <>
                        {formatDecimalNumber(rowData?.heightMeasure)}{' '}
                        {rowData?.specialField.unitDimension}
                    </>
                )
            },
        },
        {
            label: 'Width',
            // labelTranslation: 'bookings.width_measure',
            accessor: 'specialField',
            customBuild: (data, rowData) => {
                return (
                    <>
                        {formatDecimalNumber(rowData?.widthMeasure)}{' '}
                        {rowData?.specialField.unitDimension}
                    </>
                )
            },
        },
        {
            label: 'Total Weight',
            // labelTranslation: 'bookings.weight_measure',
            accessor: 'specialField',
            customBuild: (data, rowData) => {
                if (rowData?.specialField) {
                    const { qty, unit } = rowData.specialField.weightMeasure
                    return (
                        <>
                            {formatDecimalNumber(Number(qty))} {unit}
                        </>
                    )
                } else {
                    return <></>
                }
            },
        },

        {
            label: 'Total Volume',
            // labelTranslation: 'bookings.volume_measure',
            accessor: 'specialField',
            customBuild: (data, rowData) => {
                if (rowData?.specialField) {
                    const { qty, unit } = rowData.specialField.volumeMeasure
                    return (
                        <>
                            {formatDecimalNumber(Number(qty))} {unit}
                        </>
                    )
                } else {
                    return <></>
                }
            },
        },
        {
            label: 'Description',
            // labelTranslation: 'bookings.packs',
            accessor: 'goodsDescription',
        },
        {
            label: 'Marks and Numbers',
            accessor: 'marksAndNumbers',
        },
        {
            label: 'Line Price',
            accessor: 'numericLinePrice',
            customBuild: (data) => {
                return <>{formatDecimalNumber(Number(data))}</>
            },
        },
        {
            label: 'Tariff Number',
            accessor: 'tariffNumber',
            customBuild: (data) => {
                return <>{formatDecimalNumber(Number(data))}</>
            },
        },
    ]
