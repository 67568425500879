var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from 'react';
import './input.style.css';
var Input = function (_a) {
    var _b;
    var _c = _a.disabled, disabled = _c === void 0 ? false : _c, _d = _a.readonly, readonly = _d === void 0 ? false : _d, _e = _a.required, required = _e === void 0 ? false : _e, _f = _a.type, type = _f === void 0 ? 'text' : _f, _g = _a.disabledVariant, disabledVariant = _g === void 0 ? 'gray' : _g, useUppercaseLabel = _a.useUppercaseLabel, parentInputClassName = _a.parentInputClassName, onIconClick = _a.onIconClick, _h = _a.useArrow, useArrow = _h === void 0 ? true : _h, props = __rest(_a, ["disabled", "readonly", "required", "type", "disabledVariant", "useUppercaseLabel", "parentInputClassName", "onIconClick", "useArrow"]);
    var inputRef = useRef(null);
    var _j = useState(false), focus = _j[0], setFocus = _j[1];
    var showLabelClass = !props.label || props.label === '' ? 'hidden' : '';
    var isRequired = required ? 'required' : '';
    var labelFocus = focus ? 'themes-text-brand-v5' : '';
    var isDisabled = disabled ? "disabled-".concat(disabledVariant) : '';
    var iconBg = disabled ? "disabled-gray" : "disabled-white";
    var iconWithAction = ['ri-file-copy-line', 'ri-mail-line'];
    var iconClassDisabled = (props === null || props === void 0 ? void 0 : props.icon) &&
        disabled &&
        props.value &&
        iconWithAction.includes(props === null || props === void 0 ? void 0 : props.icon)
        ? 'cursor-pointer'
        : 'opacity-70';
    var useArrowClass = useArrow
        ? ''
        : '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none';
    var handleFocusEvent = function () {
        setFocus(true);
    };
    useEffect(function () {
        var handleClickOutside = function (event) {
            if (inputRef.current &&
                !inputRef.current.contains(event.target)) {
                setFocus(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return function () {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);
    return (_jsxs("div", __assign({ className: " ".concat(parentInputClassName || '', " inputParent-style mt-auto") }, { children: [_jsx("label", __assign({ className: "".concat(showLabelClass) }, { children: _jsx("div", __assign({ className: "" }, { children: _jsx("p", __assign({ className: "".concat(isRequired, " ").concat(labelFocus) }, { children: useUppercaseLabel
                            ? (_b = props.label) === null || _b === void 0 ? void 0 : _b.toUpperCase()
                            : props.label })) })) })), _jsxs("div", __assign({ className: "".concat(props.descriptionValue && 'input-group', " flex w-full items-center ").concat(props.descriptionValue && disabled && 'themes-bg-gray-v1'), id: props === null || props === void 0 ? void 0 : props.id }, { children: [props.descriptionValue && (_jsxs("div", __assign({ className: "themes-text-gray-v4 text-size-S mx-2 flex item gap-2 " }, { children: [_jsx("div", __assign({ className: "themes-text-gray-v4 text-size-S" }, { children: props.descriptionValue })), _jsx("div", { className: "border-r" })] }))), _jsx("input", __assign({}, props, { ref: inputRef, disabled: disabled, className: "".concat(props.className, " ").concat(isDisabled, " ").concat(useArrowClass), placeholder: props.placeholder, type: type, value: disabled && !props.value ? '-' : props.value, readOnly: readonly, onChange: function (e) {
                            if (props.onChange)
                                props.onChange(e);
                        }, onFocus: function (e) {
                            handleFocusEvent();
                            props.onFocus && (props === null || props === void 0 ? void 0 : props.onFocus(e));
                        } })), (props === null || props === void 0 ? void 0 : props.icon) && (_jsx("div", __assign({ className: "input-icon flex items-center justify-center  ".concat(onIconClick && 'cursor-pointer', " ").concat(iconBg) }, { children: _jsx("i", { className: "".concat(props.icon, " ").concat(iconClassDisabled), onClick: function () {
                                onIconClick && onIconClick();
                            } }) })))] }))] })));
};
export default Input;
